import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  collection_selected as collectionSelected, getcollectionlist
} from "../../store/collection/collectionActions";
import { get_listed_assets } from "store/collection/api";
import { ListingDisplayListing } from "components";
import "./style.scss";
import $ from "jquery";
import Footer from "../../components/Footer/index"
import { FadeImg, FadeVideo } from "components/Fades";
import { get_asset_image_source, numFormatter } from "utils/converter";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const Explore = () => {
  const ITEMS_PER_PAGE = 48;
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [collections, setCollections] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, sethasMore] = useState(true);
  const state_collection = useSelector((state) => state.collection);
  const state_account = useSelector((state) => state.account);

  const [listProjects, setListProjects] = useState(state_collection.exploresectionlist);
  const show_num_projects_initial = 18;
  const [window, setWindow] = useState("all");
  const [pending, setPending] = useState(false);
  const [showLimit, setShowLimit] = useState(show_num_projects_initial);
  const [sortvalue, setsortvalue] = useState("Sort by")
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);

  //**Filter componants integration starts here**//
  const [collectionsMultiSelect, setCollectionsMultiSelect] = useState(false);
  const [collectionsIndexSelected, setCollectionsIndexSelected] = useState([]);
  const [collectionsSelected, setCollectionsSelected] = useState({});
  const [searchText, setSearchText] = useState("");
  const location = useLocation()
  const { pathname, search } = location;
  console.log(pathname)
  const [searchQuery, setSearchQuery] = useState('');
  const closeNotification = () => {
    setShowNotification(false);
  };
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      // Filter the data based on the search query
      const filtered = state_collection.exploresectionlist.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setListProjects(filtered);
    }
    else {
      setListProjects(state_collection.exploresectionlist)
    }
  };

  const sortby = (event) => {
    debugger;
    const sortOrder = event;
    const sorted = state_collection.exploresectionlist.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.floor_price - b.floor_price;
      } else if (sortOrder === 'desc') {
        return b.floor_price - a.floor_price;
      }
    });
    setListProjects(sorted)
  }


  useEffect(() => {
    if (listProjects.length < 1) {
      onchange_window("all");
    }
  }, [state_collection]);

  const onchange_window = (win) => {
    setWindow(win);
    setPending(true);
    setListProjects([]);
    setShowLimit(show_num_projects_initial);
    if (state_collection.loaded) {
      let data = (state_collection.top_collections)
      prepare_data(data, show_num_projects_initial);
      $('body').addClass('loaded');
    }
  };
  function prepare_data(project_list, limit) {
    let list = [];
    let list1d = [];
    let list7d = [];
    for (var i in project_list) {
      let project = project_list[i];
      // project.rank = i;
      // project.image = get_asset_image_source(
      //   Array.isArray(project.thumbnail)
      //     ? project.thumbnail[0].includes("data:image")
      //       ? project.thumbnail
      //       : project.thumbnail[0]
      //     : project.thumbnail
      // );
      list.push(project);
      list1d.push(project);
      list7d.push(project);
    }
    dispatch(getcollectionlist(list))
    setListProjects(list)
  }
  function click_item(collection) {

    let tmp_collectionsSelected = { ...collectionsSelected };
    let tmp_collectionsIndexSelected = [...collectionsIndexSelected];

    if (tmp_collectionsIndexSelected.includes(collection.index)) {
      var index = tmp_collectionsIndexSelected.indexOf(collection.index);
      if (index !== -1) {
        tmp_collectionsIndexSelected.splice(index, 1);
        delete tmp_collectionsSelected[collection.index];
      }
    } else {
      tmp_collectionsIndexSelected.push(collection.index);
      tmp_collectionsSelected[collection.index] = collection;
    }

    if (Object.keys(tmp_collectionsSelected).length > 0) {
      let list_policy = [];
      for (let i in tmp_collectionsSelected) {
        list_policy.push(...tmp_collectionsSelected[i].policy_ids);
      }

      // filter listings
      let tmp_listings = [];
      for (let i in listings) {
        let this_listing = listings[i];
        if (list_policy.includes(this_listing.details.policyId)) {
          tmp_listings.push(this_listing);
        }
      }
      setFilteredListings(tmp_listings);
    } else {
      setFilteredListings(listings);
    }

    setCollectionsSelected(tmp_collectionsSelected);
    setCollectionsIndexSelected(tmp_collectionsIndexSelected);
    setCollectionsMultiSelect(tmp_collectionsIndexSelected.length > 0);
  }
  const setSelected = (asset) => {
    debugger;
    dispatch(collectionSelected(asset))
  }
  const searchingFor = (searchText) => {
    return (x) => {
      let return_this = false;
      if (searchText === "") {
        return_this = true;
      } else if (searchText !== "") {
        if (x.policy_ids.includes(searchText)) {
          return_this = true;
        }
        if (x.label.toLowerCase().includes(searchText.toLowerCase())) {
          return_this = true;
        }
      }
      return return_this;
    };
  };
  let matchCollections = Object.keys(state_collection.collections)
    .map(function (key, index) {
      return state_collection.collections[key];
    })
    .filter(searchingFor(searchText));
  //**Filter componants starts here **/
  function decimal(num) {
    if (num) return num.toFixed(2);
    return 0;
  }
  useEffect(() => {
    if (!isFetching && listings.length === 0) {
      loadNext();
    }
  }, []);
  useEffect(() => {
    $(".filter_left_area").hide();
    $(".colmdadd").addClass('col-md-12');
    $(".hideshowbtn").on("click", function () {
      $(".filter_left_area").animate({
        width: "toggle"
      });
      $(".colmdadd").removeClass('col-md-12');
      $("#bk2").toggleClass('col-md-9');
      $(".colmdadd").removeClass('col-md-3');
      $(".colmd3").toggleClass('col-md-4');
    });
  }, []);
  const loadNext = () => {

    if (hasMore && !isFetching) {
      setIsFetching(true);
      setCollections(state_collection);
      dispatch(
        get_listed_assets(ITEMS_PER_PAGE, lastVisible, (res) => {
          if (res.data) {

            setListings([...listings, ...res.data]);
            setFilteredListings([...filteredListings, ...res.data]);
            sethasMore(res.data.length > 0);

            if (res.data.length > 0) {
              setLastVisible(res.data[res.data.length - 1]);
            }

            let counter = 0;
            let list_collections = {};
            for (var i in res.data) {
              let this_listing = res.data[i];
              if ("id" in this_listing.collection) {
                list_collections[this_listing.collection.meta.name] = {
                  policy_ids: this_listing.collection.policy_ids,
                  label: this_listing.collection.meta.name,
                  rank:
                    "is_martify_verified" in this_listing.collection ? 1 : 2,
                  index: counter,
                };
              } else {
                list_collections[this_listing.collection.policy_id] = {
                  policy_ids: [this_listing.collection.policy_id],
                  label: this_listing.collection.policy_id,
                  rank: 3,
                  index: counter,
                };
              }
              counter += 1;
            }

            setIsFetching(false);
            $('body').addClass('loaded');
          }
        })
      );
    }
  };

  return (
    <>
      <div className="product_filter_area">
        <div className="filter_nav_top">
          <div className="container">
            <div className="row">
            {pathname != "/account" && <div className="col-md-6 col-3">
                <div className="filter_button">
                  <p><a className="btn btn-primary outline hideshowbtn"><i className="fa-solid fa-arrow-down-wide-short"></i> Filter</a></p>
                </div>
              </div>}
              <div className="col-md-6 col-9">
                <ul className="nav ml-auto float_right">
                  <li>
                    {/* <div className="search_area relative"> <i className="fa-solid fa-magnifying-glass icon_post_search"></i>
                      <input type="text" placeholder="Search Location" className="form-control" /> </div> */}
                  </li>
                  <div className="dropdown">
                    {/* <button className="sortbybutton dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> {sortvalue} </button>
                    <ul className="dropdown-menu dropmenu" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item" >Recent Add</a></li>
                      <li><a className="dropdown-item" >Recent Sold</a></li>
                      <li><a className="dropdown-item" onClick={()=>sortby("asc")}>Price Low to High</a></li>
                      <li><a className="dropdown-item" onClick={()=>sortby("desc")}>Price High to Low</a></li>
                    </ul> */}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3 filter_left_area">
              <div className="a">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                        Collection
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse show"
                      aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="card__content-search">
                          <i className="fa-solid fa-magnifying-glass card__content-search__icon"></i>
                          <input type="text" className="card__content-search__field" onChange={(e) => handleSearch(e)}
                            placeholder="Search by name" />
                        </div>
                        <p className="error" hidden>not found</p>
                        <main className="card__content collection_content">
                          <ul className="card__content__user-list">
                            {matchCollections.length > 0 ?
                              (matchCollections
                                .sort((a, b) => {
                                  return a.rank - b.rank;
                                })
                                .map((this_collection, i) => {
                                  return (
                                    <li>
                                      <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=334&q=80"
                                        alt="" />
                                      <div className="card__content__user-list__info">
                                        <input type="checkbox" id={this_collection.id}
                                          onChange={() => click_item(this_collection)}
                                        />
                                        <p>merr00y hanoudi </p>
                                      </div>
                                      <div className="card_icon"><i className="fa-solid fa-id-badge"></i>
                                      </div>
                                      <div className="count_collection">256 Items</div>
                                    </li>
                                  );
                                })
                              ) : <></>}
                          </ul>
                        </main>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="colmdadd" id="bk2">
              <div className="explore_right_data_area">
                <div className="row">

                  {/* <div className="row artworks-row"> */}
                    <InfiniteScroll
                      dataLength={listProjects.length}
                      className="artworks-row-wrapp row artworks-row"
                      next={loadNext}
                      hasMore={hasMore}
                      endMessage={
                        <div style={{ textAlign: "center" }}>
                          <span className="icon has-text-info">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          <b>Yay! You have seen it all</b>
                        </div>
                      }
                      scrollableTarget="body"
                    >
                      {listProjects
                        .slice(0, show_num_projects_initial)
                        .map((project, i) => {
                          return (
                            <div className="col-md-3" key={i} style={{ "width": "285px" }}>
                              <div className="featured-box">
                                <div className="fadeimg-box featured-imgbox like_share_all_content feed_l_content">
                                  {(project.thumbnail_type ? project.thumbnail_type : "").includes('image') ? (
                                    <FadeImg
                                      className="fadeimg"
                                      src={project.image}
                                      alt={project.name}
                                    />
                                  ) : (project.thumbnail_type ? project.thumbnail_type : "").includes('video') ? (
                                    <FadeVideo
                                      src={project.image}
                                    />
                                  ) :
                                    <FadeImg
                                      src={project.image}
                                      alt={project.name}
                                    />
                                  }
                                  <div className="like_share_all">
                                    <a href="#" ><i className="fa-solid fa-heart"></i></a>
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#shareModal" onClick={() => setSelected(project, '100')}><i className="fa-solid fa-share"></i></a>
                                  </div>
                                </div>
                                <div className="featured-information">
                                  <div className="featured-user-info">

                                    <span data-tooltip={project.name} className="user-featured-title-bid">{(project.name).slice(0, 15)}</span>
                                  </div>
                                  <div className="featured-bid" style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <span className="user-featured-title-bid">₳{numFormatter(project.volume)}</span>
                                    <span
                                      data-tooltip={`Change in volume in past ${window === "7d" ? "7 days" : "1 day"
                                        }`}
                                      className={
                                        parseFloat(
                                          decimal(
                                            project[
                                            window === "7d"
                                              ? "7dChange"
                                              : "1dChange"
                                            ]
                                          )
                                        ) >= 0
                                          ? "text-green"
                                          : "text-red"
                                      }
                                    >
                                      {parseFloat(
                                        decimal(
                                          project[
                                          window === "7d"
                                            ? "7dChange"
                                            : "1dChange"
                                          ]
                                        ) * 100
                                      ).toFixed(0)}
                                      %
                                      {
                                        parseFloat(
                                          decimal(
                                            project[
                                            window === "7d"
                                              ? "7dChange"
                                              : "1dChange"
                                            ]
                                          )
                                        ) >= 0
                                          ? <i className="fa-solid fa-arrow-up-long"></i> :
                                          <i className="fa-solid fa-arrow-down-long"></i>

                                      }
                                    </span>

                                  </div>
                                </div>
                                <div className="featured-action">
                                  <Link to={`/collection/${project.policies[0]}`} className="btn primary-btn">
                                    See More</Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </InfiniteScroll>
                  {/* </div> */}


                  {/* <ListingSection listings={filteredListings} /> */}
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      {(state_account.notificationstatus == true) ? (
        <button
          type="button"
          className="btn btn-primary nortification_footer"
        >
          <div className="row">
            <div className="col-md-10">{state_account.notificationmessage}</div>
            <div className="col-md-2">
              <span className="badge badge-light">
                <i
                  className="fa-solid fa-xmark hide_x"
                  onClick={closeNotification}
                ></i>
              </span>
            </div>
          </div>
        </button>
      ) : (
        <></>
      )}
      <div className="minmargin">
        <Footer />
      </div>
    </>
  );
};

// const Filter = ({ collections, listings, setFilteredListings }) => {
//   const [collectionsMultiSelect, setCollectionsMultiSelect] = useState(false);
//   const [collectionsIndexSelected, setCollectionsIndexSelected] = useState([]);
//   const [collectionsSelected, setCollectionsSelected] = useState({});
//   const [searchText, setSearchText] = useState("");

//   function click_item(collection) {
//     let tmp_collectionsSelected = { ...collectionsSelected };
//     let tmp_collectionsIndexSelected = [...collectionsIndexSelected];

//     if (tmp_collectionsIndexSelected.includes(collection.index)) {
//       var index = tmp_collectionsIndexSelected.indexOf(collection.index);
//       if (index !== -1) {
//         tmp_collectionsIndexSelected.splice(index, 1);
//         delete tmp_collectionsSelected[collection.index];
//       }
//     } else {
//       tmp_collectionsIndexSelected.push(collection.index);
//       tmp_collectionsSelected[collection.index] = collection;
//     }

//     if (Object.keys(tmp_collectionsSelected).length > 0) {
//       let list_policy = [];
//       for (let i in tmp_collectionsSelected) {
//         list_policy.push(...tmp_collectionsSelected[i].policy_ids);
//       }

//       // filter listings
//       let tmp_listings = [];
//       for (let i in listings) {
//         let this_listing = listings[i];
//         if (list_policy.includes(this_listing.details.policyId)) {
//           tmp_listings.push(this_listing);
//         }
//       }
//       setFilteredListings(tmp_listings);
//     } else {
//       setFilteredListings(listings);
//     }

//     setCollectionsSelected(tmp_collectionsSelected);
//     setCollectionsIndexSelected(tmp_collectionsIndexSelected);
//     setCollectionsMultiSelect(tmp_collectionsIndexSelected.length > 0);
//   }

//   const searchingFor = (searchText) => {
//     return (x) => {
//       let return_this = false;
//       if (searchText === "") {
//         return_this = true;
//       } else if (searchText !== "") {
//         if (x.policy_ids.includes(searchText)) {
//           return_this = true;
//         }  
//         if (x.label.toLowerCase().includes(searchText.toLowerCase())) {
//           return_this = true;
//         }
//       }
//       return return_this;
//     };
//   };

//   let matchCollections = Object.keys(collections)
//     .map(function (key, index) {
//       return collections[key];
//     })
//     .filter(searchingFor(searchText));

//   return (
//     <div className="card filter">
//       <header className="card-header">
//         <p className="card-header-title">Collections</p>
//       </header>
//       <div className="card-content">
//         <div className="content">
//           <div className="field">
//             <div className="control has-icons-left">
//               <input
//                 className="input"
//                 type="text"
//                 placeholder={"Filter"}
//                 value={searchText}
//                 onChange={(e) => setSearchText(e.target.value)}
//               />
//               <span className="icon is-small is-left">
//                 <i className="fas fa-search fa-xs"></i>
//               </span>
//             </div>
//           </div>
//           {matchCollections
//             .sort((a, b) => {
//               return a.rank - b.rank;
//             })
//             .map((this_collection, i) => {
//               return (
//                 <div className="field" key={i}>
//                   {collectionsMultiSelect ? (
//                     <>
//                       <input
//                         className="is-checkradio"
//                         id={this_collection.label}
//                         type="checkbox"
//                         checked={collectionsIndexSelected.includes(
//                           this_collection.index
//                         )}
//                         onChange={() => click_item(this_collection)}
//                       />
//                       <label
//                         className="filter_label"
//                         htmlFor={this_collection.label}
//                       >
//                         {this_collection.label}
//                       </label>
//                     </>
//                   ) : (
//                     <label
//                       className="filter_label"
//                       onClick={() => click_item(this_collection)}
//                     >
//                       {this_collection.label}
//                     </label>
//                   )}
//                 </div>
//               );
//             })}
//         </div>
//       </div>
//     </div>
//   );
// };

const ListingSection = ({ listings }) => {
  return (
    <>
      {listings.length > 0 ? (
        <ListingDisplayListing listings={listings} />
      ) : <></>
        // ) : (
        //   <section className="hero is-medium">
        //     <div className="hero-body">
        //       <div className="container has-text-centered">
        //         <h1>
        //           <span
        //             className="icon"
        //             style={{ fontSize: "100px", marginBottom: "50px" }}
        //           >
        //             <i className="far fa-times-circle"></i>
        //           </span>
        //         </h1>
        //         <p className="title">No Assets</p>
        //         <p className="subtitle">No Assets Listed on the Market.</p>
        //       </div>
        //     </div>
        //   </section>
        //)
      }
    </>
  );
};

export default Explore;
