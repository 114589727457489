import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navbar } from "components";

import "./style.scss";


const AccountSplash = () => {
  const state_account = useSelector(state => state.account)
  const state_wallet = useSelector(state => state.wallet)

  return (
    <>
      {/* {
        !state_wallet.connected ? ( */}
      <Navbar />
      {/* ) : <>
          {state_account.profile.profilebannerimage == '' && (
            <div className="account_areabg" style={{ background: `url(../../assets/images/explorer/explorer.jpeg)` }}>
              <Navbar />
            </div>)}
          {state_account.profile.profilebannerimage != '' && (
            <div className="account_areabg" style={{ background: `url(${state_account.profile.profilebannerimage})` }}>
              <Navbar />
            </div>)}
        </>
      } */}

    </>
  );
};
export default AccountSplash;
