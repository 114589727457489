import React, { Component, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { user_signin } from "../../store/account/accountActions";
// import AllAssets from "./AllAssets";
import Holdings from "./Holdings";
import Listings from "./Listings";
import Events from "./Events";
import { Link, useHistory } from "react-router-dom";
import FollowPopup from "components/FollowDetails";
import { FadeImg, FadeVideo } from "components/Fades";
import { AssetCard } from "components";
import { sunstepApi } from "../../config";
import { sunstep_recommended_users as sunstepRecommendedUsers } from "../../store/account/api";
import Footer from "../../components/Footer/index"
import $ from "jquery";
import './style.scss'
import { getfollowers, getfollowings, followaUser, unfollowaUser, getfollowdetailscount
} from "../../store/account/api";
import Explore from '../Explore/index'
import ExploreSplash from '../../components/ExploreSplash/index'
const Account = () => {

  const state_wallet = useSelector((state) => state.wallet);
  const state_collection = useSelector((state) => state.collection);
  const accountDetails = useSelector((state) => state.account);
  const [followers, setFollowers] = useState([]);
  const dispatch = useDispatch();
  // const [followerList, setFollowerList] = useState([]);
  // const [followingList, setFollowingList] = useState([]);
  let [formData, updateFormData] = React.useState(accountDetails.profile);
  const [connectList, setConnectList] = useState([]);
  useEffect(() => {
    if (state_wallet.connected) {
      let obj = {
        pageno: 0,
        size: 50,
      };
      //state_wallet.data.address
      dispatch(
        sunstepRecommendedUsers(obj, (res) => {
          setFollowers(res.data);
          setConnectList([...new Set(res.data)])
        })
      );
      dispatch(getfollowings(accountDetails.profile.email))
      dispatch(getfollowers(accountDetails.profile.email))
      const payloadforfollowdetails = {
        useremail: accountDetails.profile.email,
      };
      dispatch(getfollowdetailscount(payloadforfollowdetails, (res) => { }));
    }

  }, [state_wallet]);
  //  const li =  [...new Set(connectList)]
  useEffect(() => {
    $("body").addClass("loaded");
  }, []);
  return (
    <>
      {!state_wallet.connected ? <NotConnected /> : <></>}
      {state_wallet.connected ? (
        <Connected
          state_wallet={state_wallet}
          state_collection={state_collection}
          accountDetails={accountDetails}
          formData={formData}
          connectList={connectList}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const Connected = ({ state_wallet, state_collection, accountDetails, formData, connectList }) => {
  const dispatch = useDispatch();
  const [follower, setFollower] = useState(null);
  const [following, setFollowing] = useState(null);
  // const [accountdata, setAccountData] = useState({});
  // const [connectList, setConnectList] = useState([]);
  const [addresscopy, setaddresscopy] = useState(false)


  useEffect(() => {
    
    if (accountDetails.profile.walletaddress != null) {
      debugger;
      getAccountDetails();
      // getConnectList();
      // localStorage.setItem('accountDetails',JSON.stringify(accountDetails));
    }
  }, []);
  console.log(accountDetails)
  //   const formData =JSON.parse(localStorage.getItem("formData"));
  // console.log(formData);
  // setAccountData(accountDetails)
  const getfollow = async (email) => {
    const payload = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "access-control-allow-origin": "*",
      },
      mode: "cors", // or "same-origin"
      body: JSON.stringify({
        useremail: email,
      }),
    };


    fetch(`${sunstepApi}/api/getfollowercount`, payload)
      .then((res) => res.json())
      .then((res) => {
        setFollower(res);
      })
      .catch((err) => {
        console.error(err);
      });


    await fetch(`${sunstepApi}/api/getfollowingcount`, payload)
      .then((res) => res.json())
      .then((res) => {
        setFollowing(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAccountDetails = async () => {
    const accountpayload = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "Content-type": "application/json",
      },
      body: JSON.stringify({ walletaddress: state_wallet.data.address }),
    };
    await fetch(`${sunstepApi}/api/register`, accountpayload)
      .then((res) => res.json())
      .then((res) => {
        console.log("Data " + res);
        // setAccountData(res);
        dispatch(user_signin(res));

        if (res.email != null || res.email != "") {
          getfollow(res.email);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  async function copyaddress(data) {
    navigator.clipboard.writeText(data)
    setaddresscopy(!addresscopy)
  }
  // console.log(accountdata)
  const getConnectList = async () => {
    const accountpayload = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        pageno: "0",
        size: "50",
      }),
    };

    await fetch(`${sunstepApi}/api/users`, accountpayload)

      .then((res) => res.json())
      .then((res) => {
        console.log(res.json())
        // setConnectList(res.json());
      })
      .catch((err) => {
        console.error(err);
      });
  };
  console.log('line -->160', connectList)
  const TABS = {
    ALL_ASSETS: {
      label: "Assets",
      icon: "far fa-images",
    },
    HOLDINGS: {
      label: "Holdings",
      icon: "far fa-images",
    },
    LISTINGS: {
      label: "Listings",
      icon: "far fa-images",
    },
    EVENTS: {
      label: "Events",
      icon: "far fa-envelope",
    },
  };
  // const [displayTab, setDisplayTab] = useState("ALL_ASSETS");
  console.log(accountDetails.profile);
  return (
    <section className="section">
      <div className="create_profile_container">
        <div className="create_main_profile">
          <div className="container banner_section_wrapp">
            {/* <div className="col-md-1"></div> */}
            {/* <div className="creat_main_profile_img">
									<img src="images/create-profile/main_profile.png" alt="" />
								</div> */}
           {(accountDetails.profile.profilebannerimage =="" || accountDetails.profile.profilebannerimage == undefined) && <img src="images/profile_banner.svg" className="profile-banner" />}
           {(accountDetails.profile.profilebannerimage !="" && accountDetails.profile.profilebannerimage != undefined) && <img src={accountDetails.profile.profilebannerimage} className="profile-banner" />}

            <div className="banner_profile_section row">
              <div className="col-md-1"></div>

              <div className="col-md-5">
                <div className="row profile_details-row">
                  <div className="col-md-6">
                    <div className="profile-image">
                      <img
                        src={
                          accountDetails.profile.profileimage == null ||
                            accountDetails.profile.profileimage == ""
                            ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&s=200"
                            : `${accountDetails.profile.profileimage}`
                        }
                        alt=""
                      />
                    </div>
                    <ul className="list-inline float_none profile_details">
                      {accountDetails.profile.firstname!=undefined ? <li className="text-bold">
                        {accountDetails.profile.firstname} {accountDetails.profile.lastname}
                        
                      </li>:
                      <li className="text-bold">
                        Add name.
                        
                      </li>}
                      {/* <li className="subheading_size_wallet">
                        <a href="#">{state_wallet.data.address + "..."}</a>
                      </li> */}
                    </ul>
                    <div className="featured-information">
                      {accountDetails.profile.email == null ||
                        accountDetails.profile.email == "" ? (
                        <></>
                      ) : (
                        <FollowPopup
                          count={accountDetails.followercount}
                          email={
                            accountDetails.profile.email == null ||
                              accountDetails.profile.email == ""
                              ? state_wallet.data.address
                              : accountDetails.profile.email
                          }
                          followtype="Follower"
                        ></FollowPopup>
                      )}
                      {accountDetails.profile.email == null ||
                        accountDetails.profile.email == "" ? (
                        <></>
                      ) : (
                        <FollowPopup
                          count={accountDetails.followingcount}
                          email={
                            accountDetails.profile.email == null ||
                              accountDetails.profile.email == ""
                              ? state_wallet.data.address
                              : accountDetails.profile.email
                          }
                          followtype="Following"
                        ></FollowPopup>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="connections_section">
                      <h6>Connections</h6>
                      <h5>{accountDetails.followercount}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-5">
                <ul className="list-unstyled display_flex list-unstyled_end">
                  <li><a href={"#"} target="_blank"><i className="fa-brands fa-facebook-square"></i></a></li>
                  <li><a href={"#"} target="_blank"><i className="fa-brands fa-twitter-square"></i></a></li>
                  <li><a href={"#"} target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                  <li><a href={"#"} target="_blank"><i className="fa-brands fa-instagram-square"></i></a></li>
                </ul>
                <div className="row profile_address_row">
                  <div className="col-md-7">
                    <div className="address-data">
                      <h6>{(accountDetails.profile.walletaddress).slice(0, 20)}...</h6>
                      <h5 className="data_address" style={{cursor:"pointer"}} onClick={()=>copyaddress(accountDetails.profile.walletaddress)}>Copy</h5>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Link className="navbar-item" to="/profile">
                      <div className="address-data profile-edit-data">
                        <img src="images/edit_icon.svg" />
                        <h5>Edit Profile</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-1">
                <p className="user_profile_edit_area display_flex">
                  <i className="fa-solid fa-pencil"></i>{" "}
                  <Link className="navbar-item" to="/profile">
                    Edit
                  </Link>
                </p>
              </div> */}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="item_tab_area collection_tab">
          <div
            className="nav nav-tabs user_profile_nav justify-content-center"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#item"
              type="button"
              role="tab"
            >
              My Items
            </button>
            <button
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
            >
              Collections
            </button>

            <button
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#connect"
              type="button"
            >
              Connect
            </button>
          </div>
        </div>
      </div>
      <div className="container account_footer">
        <form>
          <div className="row">
            <div className="colmdadd" id="bk2">
              <div className="explore_right_data_area">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="item"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="home-header explore_area">
                      <div className="filter_nav_top no_padding">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6 col-3">
                              <div className="filter_button">
                                <p>
                                  <a className="btn btn-primary outline hideshowbtn">
                                    <i className="fa-solid fa-arrow-down-wide-short"></i>
                                    Filter
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6 col-9">
                              <ul className="nav ml-auto float_right">
                                <li>
                                  <div className="search_area relative">
                                    {" "}
                                    <i className="fa-solid fa-magnifying-glass icon_post_search"></i>
                                    <input
                                      type="text"
                                      placeholder="Search Location"
                                      className="form-control"
                                    />
                                  </div>
                                </li>
                                <div className="dropdown">
                                  <button
                                    className="sortbybutton dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Sort By{" "}
                                  </button>
                                  <ul
                                    className="dropdown-menu dropmenu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Recent Add
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Recent Sold
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Price Low to High
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Price High to Low
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <AllAssets
                      state_collection={state_collection}
                      state_wallet={state_wallet}
                    />

                    {/* <div className="colmd3 col-4">
												<div className="featured-box">
													<div className="no-imgbox"> <img src="https://img.icons8.com/fluency/256/000000/image.png" /> </div>
													<div className="featured-information">
														<div className="featured-user-info"> <span
															className="user-name-bid">@Jonny</span> <span
																className="user-featured-title-bid">Block of Art</span> </div>
														<div className="featured-bid"> <span className="user-name-bid">Current
															Bid</span> <span className="user-featured-title-bid">0.005
																ADA</span> </div>
													</div>
													<div className="featured-action">
														<button type="button" className="btn primary-btn"><a
															href="item-details.html" className="text-white">Item
															Details</a></button>
													</div>
												</div>
											</div> */}
                  </div>
                  <div
                    className="tab-pane fade" 
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div style={{marginTop:"15rem"}}>
                    <ExploreSplash />
                    <Explore/>
                    </div>
                    {/* <div className="home-header explore_area">
                      <div className="filter_nav_top no_padding">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6 col-3">
                              <div className="filter_button">
                                <p>
                                  <a className="btn btn-primary outline hideshowbtn">
                                    <i className="fa-solid fa-arrow-down-wide-short"></i>
                                    Filter
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6 col-9">
                              <ul className="nav ml-auto float_right">
                                <li>
                                  <div className="search_area relative">
                                    {" "}
                                    <i className="fa-solid fa-magnifying-glass icon_post_search"></i>
                                    <input
                                      type="text"
                                      placeholder="Search Location"
                                      className="form-control"
                                    />
                                  </div>
                                </li>
                                <div className="dropdown">
                                  <button
                                    className="sortbybutton dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Sort By{" "}
                                  </button>
                                  <ul
                                    className="dropdown-menu dropmenu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Recent Add
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Recent Sold
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Price Low to High
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Price High to Low
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="colmd3 col-4">
                        <div className="featured-box">
                          <div className="no-imgbox">
                            {" "}
                            <img src="https://img.icons8.com/fluency/256/000000/image.png" />{" "}
                          </div>
                          <div className="featured-information">
                            <div className="featured-user-info">
                              {" "}
                              <span className="user-name-bid">@Jonny</span>{" "}
                              <span className="user-featured-title-bid">
                                Block of Art
                              </span>{" "}
                            </div>
                            <div className="featured-bid">
                              {" "}
                              <span className="user-name-bid">
                                Current Bid
                              </span>{" "}
                              <span className="user-featured-title-bid">
                                0.005 ADA
                              </span>{" "}
                            </div>
                          </div>
                          <div className="featured-action">
                            <button type="button" className="btn primary-btn">
                              27 Place a Bid
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="colmd3 col-4">
                        <div className="featured-box">
                          <div className="no-imgbox">
                            {" "}
                            <img src="https://img.icons8.com/fluency/256/000000/image.png" />{" "}
                          </div>
                          <div className="featured-information">
                            <div className="featured-user-info">
                              {" "}
                              <span className="user-name-bid">@Jonny</span>{" "}
                              <span className="user-featured-title-bid">
                                23 Block of Art
                              </span>
                            </div>
                            <div className="featured-bid">
                              {" "}
                              <span className="user-name-bid">
                                Current Bid
                              </span>{" "}
                              <span className="user-featured-title-bid">
                                0.005 ADA
                              </span>{" "}
                            </div>
                          </div>
                          <div className="featured-action">
                            <button type="button" className="btn primary-btn">
                              27 Place a Bid
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="colmd3 col-4">
                        <div className="featured-box">
                          <div className="no-imgbox">
                            {" "}
                            <img src="https://img.icons8.com/fluency/256/000000/image.png" />{" "}
                          </div>
                          <div className="featured-information">
                            <div className="featured-user-info">
                              {" "}
                              <span className="user-name-bid">@Jonny</span>{" "}
                              <span className="user-featured-title-bid">
                                Block of Art
                              </span>{" "}
                            </div>
                            <div className="featured-bid">
                              {" "}
                              <span className="user-name-bid">
                                Current Bid
                              </span>{" "}
                              <span className="user-featured-title-bid">
                                0.005 ADA
                              </span>{" "}
                            </div>
                          </div>
                          <div className="featured-action">
                            <button type="button" className="btn primary-btn">
                              26 Place a Bid
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="colmd3 col-4">
                        <div className="featured-box">
                          <div className="no-imgbox">
                            {" "}
                            <img src="https://img.icons8.com/fluency/256/000000/image.png" />{" "}
                          </div>
                          <div className="featured-information">
                            <div className="featured-user-info">
                              {" "}
                              <span className="user-name-bid">@Jonny</span>{" "}
                              <span className="user-featured-title-bid">
                                24 Block of Art
                              </span>
                            </div>
                            <div className="featured-bid">
                              {" "}
                              <span className="user-name-bid">
                                Current Bid
                              </span>{" "}
                              <span className="user-featured-title-bid">
                                0.005 ADA
                              </span>{" "}
                            </div>
                          </div>
                          <div className="featured-action">
                            <button type="button" className="btn primary-btn">
                              View Details
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="colmd3 col-4">
                        <div className="featured-box">
                          <div className="no-imgbox">
                            {" "}
                            <img src="https://img.icons8.com/fluency/256/000000/image.png" />{" "}
                          </div>
                          <div className="featured-information">
                            <div className="featured-user-info">
                              {" "}
                              <span className="user-name-bid">@Jonny</span>{" "}
                              <span className="user-featured-title-bid">
                                Block of Art
                              </span>{" "}
                            </div>
                            <div className="featured-bid">
                              {" "}
                              <span className="user-name-bid">
                                Current Bid
                              </span>{" "}
                              <span className="user-featured-title-bid">
                                0.005 ADA
                              </span>{" "}
                            </div>
                          </div>
                          <div className="featured-action">
                            <button type="button" className="btn primary-btn">
                              25 Place a Bid
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="connect"
                    role="tabpanel"
                    aria-labelledby="connect-tab"
                  >
                    <div className="row justify-content-center">

                      {

                        connectList.length > 0 ? (
                          connectList.map((connect, i) =>
                            connect.email === null || connect.email === "" ? (
                              <></>
                            ) : (
                              <ConnectData
                                connect={connect}
                                myemail={accountDetails.profile.email}
                                key={i}
                              ></ConnectData>
                            )
                          )
                        ) : (
                          <></>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </section>
  );
};

const AllAssets = ({ state_wallet, state_collection }) => {
  const [listings, setListings] = useState([]);
  const [searchText, setSearchText] = useState("");
  const default_list_projects = [{ value: "all", label: "All Projects" }];
  const [listProjectsFilter, setListProjectsFilter] = useState([
    ...default_list_projects,
  ]);
  const [filterProject, setFilterProject] = useState("all");
  const filters_assets = [
    { value: "all", label: "Show all assets" },
    { value: "listed", label: "Show listed" },
    { value: "offered", label: "Show has offers" },
  ];
  const [filterAsset, setFilterAsset] = useState("all");

  useEffect(() => {
    load();
  }, [state_wallet]);
  const add_asset = (list_nfts, dict_projects, this_asset) => {
    if (this_asset) {
      list_nfts.push(this_asset);
      let policy_id = this_asset.details.policyId;
      if (policy_id in state_collection.policies_collections) {
        dict_projects[policy_id] =
          state_collection.policies_collections[policy_id].meta.name;
      } else {
        dict_projects[policy_id] = policy_id;
      }
    }
  };

  const load = () => {
    if (state_wallet.loaded_assets) {
      let list_nfts = [];
      let list_projects = [...default_list_projects];
      let dict_projects = {};
      let list_nfts_id = []; // for checking duplicates

      for (let i in state_wallet.data.market) {
        let this_asset = state_wallet.data.market[i];
        if (!list_nfts_id.includes(this_asset.details.asset)) {
          add_asset(list_nfts, dict_projects, this_asset);
          list_nfts_id.push(this_asset.details.asset);
        }
      }

      for (let i in state_wallet.data.assets) {
        let this_asset = state_wallet.data.assets[i];
        if (!list_nfts_id.includes(this_asset.details.asset)) {
          add_asset(list_nfts, dict_projects, this_asset);
          list_nfts_id.push(this_asset.details.asset);
        }
      }

      setListings(list_nfts);

      for (var policy_id in dict_projects) {
        list_projects.push({
          value: policy_id,
          label: dict_projects[policy_id],
        });
      }
      setListProjectsFilter(list_projects);
    }
  };

  const searchingFor = (searchText) => {
    return (x) => {
      let return_this = false;
      if (searchText === "") {
        return_this = true;
      } else if (searchText !== "" && x.details.onchainMetadata) {
        if (
          x.details.onchainMetadata.name
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return_this = true;
        }
      }
      return return_this;
    };
  };

  let matchedtokens = listings.filter(searchingFor(searchText));

  const filtered_listing = matchedtokens
    .filter((asset) => {
      let allow_project = false;

      if (filterProject === "all") allow_project = true;
      else allow_project = filterProject === asset.details.policyId;

      if (allow_project) {
        if (filterAsset === "all") {
          return true;
        } else if (filterAsset === "listed" && asset.status.locked) {
          return true;
        } else if (filterAsset === "offered") {
          if (asset.offers) {
            if (Object.keys(asset.offers).length) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
    .map((asset, i) => {
      return (
        <div className="colmd3 col-4">
          <AssetCard asset={asset} key={i} show_offer={true} />
        </div>
        // <AssetCard
        // 	asset={asset}
        // 	show_offer={true}
        // 	column_className="column is-one-full-mobile is-one-quarter-tablet is-2-desktop is-2-widescreen is-2-fullhd"
        // 	key={i}
        // />
      );
    });

  return (
    <>
      {listings.length > 0 ? (
        <div className="row">{filtered_listing}</div>
      ) : (
        // <div className="block">
        // 	<div className="field is-grouped">
        // 		<div className="control has-icons-left is-expanded">
        // 			<input
        // 				className="input"
        // 				type="text"
        // 				placeholder={"Search"}
        // 				value={searchText}
        // 				onChange={(e) => setSearchText(e.target.value)}
        // 			/>
        // 			<span className="icon is-small is-left">
        // 				<i className="fa fa-search"></i>
        // 			</span>
        // 		</div>

        // 		<div className="control">
        // 			<span className="select">
        // 				<select
        // 					value={filterProject}
        // 					onChange={(event) => setFilterProject(event.target.value)}
        // 				>
        // 					{listProjectsFilter.map((option, i) => {
        // 						return (
        // 							<option value={option.value} key={i}>
        // 								{option.label}
        // 							</option>
        // 						);
        // 					})}
        // 				</select>
        // 			</span>
        // 		</div>

        // 		<div className="control">
        // 			<span className="select">
        // 				<select
        // 					value={filterAsset}
        // 					onChange={(event) => setFilterAsset(event.target.value)}
        // 				>
        // 					{filters_assets.map((option, i) => {
        // 						return (
        // 							<option value={option.value} key={i}>
        // 								{option.label}
        // 							</option>
        // 						);
        // 					})}
        // 				</select>
        // 			</span>
        // 		</div>
        // 	</div>

        // 	<div className="columns is-multiline">{filtered_listing}</div>
        // </div>
        <NoAssetFound state_wallet={state_wallet} />
      )}
    </>
  );
};

const NoAssetFound = ({ state_wallet }) => {
  return (
    <section className="hero is-large">
      <div className="hero-body">
        <div className="container has-text-centered">
          {!state_wallet.connected ? (
            <>
              <h1>
                <span
                  className="icon"
                  style={{ fontSize: "100px", marginBottom: "50px" }}
                >
                  <i className="fas fa-plug"></i>
                </span>
              </h1>
              <p className="title">Connect your wallet</p>
              <p className="subtitle">
                Do not have Nami Wallet?{" "}
                <a
                  href="https://namiwallet.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>{" "}
                now!
              </p>
            </>
          ) : (
            <></>
          )}
          {state_wallet.connected && state_wallet.loading ? (
            <>
              <h1>
                <span
                  className="icon"
                  style={{ fontSize: "100px", marginBottom: "50px" }}
                >
                  <i className="fas fa-truck-loading"></i>
                </span>
              </h1>
              <p className="title">Fetching your assets</p>
              <p className="subtitle">This may take awhile...</p>
            </>
          ) : (
            <></>
          )}
          {state_wallet.connected &&
            !state_wallet.loading &&
            state_wallet.loaded_assets ? (
            <>
              <h1>
                <span
                  className="icon"
                  style={{ fontSize: "100px", marginBottom: "50px" }}
                >
                  <i className="fas fa-truck-loading"></i>
                </span>
              </h1>
              <p className="title">No assets</p>
              <p className="subtitle">
                Looks like your wallet is empty,{" "}
                <a href="/explore">start browsing</a>!
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

const ConnectData = ({ connect, myemail }) => {
  const [follow, setFollow] = useState("Follow");
  const FollowUser = async () => {
    const accountpayload = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        senderemail: myemail,
        receiveremail: connect.email,
      }),
    };

    console.log('Hi', JSON.stringify(connect))
    await fetch("https://629e-202-142-70-142.in.ngrok.io/api/followauser", accountpayload)
      .then((res) => res.json())
      .then((res) => {

        setFollow("Followed");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    // <div className="colmd3 col-4">
    //   <div className="item">
    <div className="featured-box creators_box">
      <div className="create_main_profile">
        <div className="top_creators_img">
          <FadeImg
            src={`https://gateway.pinata.cloud/ipfs/${connect.profilebannerimage}`}
            alt="Sunstep profile banner picture"
            className="pic"
          />
          {/* <img src="images/feed/top_creators.jpg" alt="">  */}
        </div>
        <div className="top_creators_content">
          <div className="artist_follow_img">
            <FadeImg
              src={`https://gateway.pinata.cloud/ipfs/${connect.profileimage}`}
              alt="Sunstep profile picture"
            />
            {/* <img src="images/img06.png" alt="">  */}
          </div>
          <div className="creat_main_profile_heading">
            <ul className="list-inline">
              <li className="text-bold">
                {connect.firstname} {connect.lastname}
              </li>
            </ul>
          </div>
          <div className="creat_main_profile_desc">
            <p>{connect.bio != null ? connect.bio : "No bio added yet"} </p>
          </div>
          {/* <button type="button" className="btn primary-btn" onClick={() => { FollowUser(); }}>{follow}</button> */}
          <div className="creat_main_profile_button">
            {" "}
            <a
              href="javascript:void(0)"
              className="btn email-send-btn"
              onClick={() => {
                FollowUser();
              }}
            >
              <i className="fa-solid fa-plus"></i> {follow}
            </a>{" "}
          </div>
        </div>
      </div>
    </div>
    // {/* </div> */}

    //   {/* <div className="featured-box">
    // 		<div className="no-imgbox">
    // 			<img src={connect.profileimage ?
    // 				"https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&s=200"
    // 				: connect.profileimage} />
    // 		</div>
    // 		<div className="featured-user-info"> <span
    // 			className="user-name-bid">{connect.firstname} {connect.lastname}</span> <span
    // 				className="user-featured-title-bid">{connect.bio != null ? connect.bio : "No bio added yet"}</span>
    // 		</div>
    // 		<div className="featured-action">
    // 			<button type="button" className="btn primary-btn" onClick={() => { FollowUser(); }}>{follow}</button>
    // 		</div>
    // 	</div> */}
    // </div>
  );
};

const NotConnected = () => {
  return (
    <section className="hero is-large">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1>
            <span
              className="icon"
              style={{ fontSize: "100px", marginBottom: "50px" }}
            >
              <i className="fas fa-plug"></i>
            </span>
          </h1>
          <p className="title">Connect your wallet</p>
          <p className="subtitle">
            Do not have Nami Wallet?{" "}
            <a href="https://namiwallet.io/" target="_blank" rel="noreferrer">
              Download
            </a>{" "}
            now!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Account;
