import * as types from "./actionTypes";

// export function user_signup(payload) {
//   return {
//     type: types.USER_SIGNUP,
//     payload: payload,
//   };
// }
export function user_signin(payload) {
  return {
    type: types.USER_SIGNIN,
    payload: payload,
  };
}

export function user_social_feed(payload) {
  return {
    type: types.USER_SOCIAL_FEED,
    payload: payload,
  };
}
export function user_recommended_users(payload) {
  return {
    type: types.USER_SOCIAL_FEED,
    payload: payload,
  };
}

export function user_profile_image_upload(payload) {
  return {
    type: types.USER_PROFILE_IMAGE_UPLOAD,
    payload: payload,
  };
}

export function user_get_followercount(followercount){
  return{
    type: types.USER_FOLLOWER_COUNT,
    payload: followercount
  }
}

export function user_get_followingcount(followingcount){
  return{
    type: types.USER_FOLLOWING_COUNT,
    payload: followingcount
  }
}
export function user_allfeedcount(payload) {
  return {
    type: types.USER_ALL_FEEDCOUNT,
    payload: payload,
  };
}

export function user_followers(payload) {
  return {
    type: types.USER_FOLLOWERS,
    payload: payload,
  };
}

export function user_followings(payload) {
  return {
    type: types.USER_FOLLOWINGS,
    payload: payload,
  };
}

export function notification_status(payload){
  return {
    type: types.NOTIFICATION_STATUS,
    payload: payload
  }
}

export function notification_message(payload){
  return {
    type: types.NOTIFICATION_MESSAGE,
    payload: payload
  }
}