import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./style.scss";

// import { opencnft_get_top_projects as opencnftGetTopProjects } from "store/collection/api";
import { get_asset_image_source, numFormatter } from "utils/converter";
import { FadeImg, FadeVideo } from "components/Fades";
import ethereum from '../../assets/images/ethereum.svg';
import Cardano from '../../assets/images/Cardano.svg';
import solana from '../../assets/images/solana.svg';

import cardanoLg from '../../assets/images/cardano-logo.png';
import cardanoBlue from '../../assets/images/cardano-blue-light.svg';
import $ from "jquery";
import {
  collection_selected as collectionSelected, getcollectionlist
} from "../../store/collection/collectionActions";

import nft1 from "../../assets/images/home/nft-1.svg"
import nft2 from "../../assets/images/home/nft-2.svg"
import nft3 from "../../assets/images/home/nft-3.svg"
import nft4 from "../../assets/images/home/nft-4.svg"
import Footer from "../../components/Footer/index"


const Home = () => {
  const dispatch = useDispatch();
  const [ticking, setTicking] = useState(true),
    [count, setCount] = useState(0)
  const [listProjects, setListProjects] = useState([]);

  const [listProjects1d, setListProjects1d] = useState([]);
  const [listProjects7d, setListProjects7d] = useState([]);

  // const opencnft_get_top_projects = (time, callback) => {
  //   dispatch(opencnftGetTopProjects(time, callback));
  // };
  useEffect(() => {
    const timer = setTimeout(() => ticking && setCount(count + 1), 10000)
    return () => clearTimeout(timer)
  });
  return (
    <div className="homepage">
      <TopProjects
        // opencnft_get_top_projects={opencnft_get_top_projects}
        listProjects={listProjects}
        setListProjects={setListProjects}
        listProjects1d={listProjects1d}
        setListProjects1d={setListProjects1d}
        listProjects7d={listProjects7d}
        setListProjects7d={setListProjects7d}
      />
    </div>
  );
};
const TopProjects = ({
  // opencnft_get_top_projects,
  listProjects,
  setListProjects,
  listProjects1d,
  setListProjects1d,
  listProjects7d,
  setListProjects7d
}) => {

  const [ticking, setTicking] = useState(true),
    [count, setCount] = useState(0)
  const state_account = useSelector((state) => state.account);

  useEffect(() => {
    const timer = setTimeout(() => ticking && setCount(count + 1), 10000)
    return () => clearTimeout(timer)
  });
  const dispatch = useDispatch();
  const state_collection = useSelector((state) => state.collection);
  const show_num_projects_initial = 99;
  const [pending, setPending] = useState(false);
  const [window, setWindow] = useState("7d");
  const [showLimit, setShowLimit] = useState(show_num_projects_initial);
  const [topProjectData, setTopProjectData] = useState([]);
  const [CNFTLoaded, setCNFTLoaded] = useState(false);

  const closeNotification = () => {
  };
  const window_options = [
    { value: "24h", label: "Last 24 hours" },
    { value: "7d", label: "Last 7 days" },
    { value: "30d", label: "Last 30 days" },
    { value: "all", label: "All time" },
  ];

  function prepare_data(project_list, limit) {
    let list = [];
    let list1d = [];
    let list7d = [];
    for (var i in project_list) {
      let project = project_list[i];
      // project.rank = i;
      // project.image = get_asset_image_source(
      //   Array.isArray(project.thumbnail)
      //     ? project.thumbnail[0].includes("data:image")
      //       ? project.thumbnail
      //       : project.thumbnail[0]
      //     : project.thumbnail
      // );
      list.push(project);
      list1d.push(project);
      list7d.push(project);
    }
    dispatch(getcollectionlist(list))
    setListProjects(list);
    setListProjects1d(list1d);
    setListProjects7d(list7d);
    setPending(false);
    setCNFTLoaded(true);
  }

  const onchange_window = (win) => {
    setWindow(win);
    setPending(true);
    setListProjects([]);
    setShowLimit(show_num_projects_initial);
    if (state_collection.loaded) {
      setTopProjectData(state_collection.top_collections);
      prepare_data(state_collection.top_collections, show_num_projects_initial);
      $('body').addClass('loaded');
    }
    // opencnft_get_top_projects(win, (res) => {
    //   setTopProjectData(res.data);
    //   prepare_data(res.data, show_num_projects_initial);
    //   $('body').addClass('loaded');
    // });
  };
  const setSelected = (asset) => {
    dispatch(collectionSelected(asset))
  }
  // const handleClick = (userEmail) => {
  //   if (specificFriend.includes(userEmail)) {

  //     setSpecificFriend(specificFriend.filter((email) => email !== userEmail));
  //   } else {

  //     setSpecificFriend([...specificFriend, userEmail]);
  //   }
  // };

  useEffect(() => {
    if (listProjects.length === 0) onchange_window("7d");
  }, [state_collection]);

  function decimal(num) {
    if (num) return num.toFixed(2);
    return 0;
  }
  function add(accumulator, a) {
    return accumulator + a;
  }
  console.log(listProjects)

  return (
    <>
      {CNFTLoaded ? (
        <section className="section top-project">

          <div className="home-logo-section">
            <div className="home-coin-logo-section">
              <div className="container">
                <div className="home-logo-row">
                  <div className="coin-logo-col">
                    <img src={ethereum} />
                    <h4>Ethereum</h4>
                  </div>
                  <div className="coin-logo-col">
                    <img src={Cardano} />
                    <h4>Cardano</h4>
                  </div>
                  <div className="coin-logo-col">
                    <img src={solana} />
                    <h4>Solana</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-wrap">
            <div className="container">
              <div className="mints-operations col-md-12">
                <h6>Nft-mints Operations</h6>
              </div>
              <div className="nft_world_head">
                <div className="blur-effect1"></div>
                <div className="nfts-minted-smartcontract">
                  <h2>NFTs are minted using a<br /> smart contract.</h2>
                  <h6>This contract holds the information about the digital asset, such as its ownership, the terms of sale, and any other relevant information. When someone wants to mint an NFT, they use the smart contract to create the asset and assign it a unique digital identifier. This identifier is then used to track the asset and provide proof of ownership.</h6>
                </div>
              </div>
              <div className="">
                <div className="home-logo-row">
                  <div className="NFTs-minted">
                    <div className="NFTs-minted-details">
                      <img src={nft1} />
                      <h4>Create a digital<br /> asset</h4>
                      <h6>Create a digital asset that represents the NFT. This could include a token, a smart contract, or a piece of digital art.</h6>
                    </div>
                  </div>
                  <div className="NFTs-minted">
                    <div className="NFTs-minted-details">
                      <img src={nft2} />
                      <h4>Prepare the asset<br /> for minting</h4>
                      <h6>Prepare the asset for minting by providing all relevant information, such as the asset’s name, description, and other metadata.</h6>
                    </div>
                  </div>
                  <div className="nft_world_head NFTs-minted">
                    <div className="blur-effect"></div>
                    <div className="NFTs-minted-details">
                      <img src={nft3} />
                      <h4>Mint the<br /> asset</h4>
                      <h6>Use our platform to mint the asset, which creates a unique token on the blockchain representing the asset.</h6>
                    </div>
                  </div>
                  <div className="NFTs-minted">
                    <div className="NFTs-minted-details">
                      <img src={nft4} />
                      <h4>Verify the<br /> asset</h4>
                      <h6>Verify that the asset has been successfully minted by checking the blockchain explorer.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="min-margin container">
              <div className="step-heading-row">
                <h2>About us</h2>
                <p>If you want in on the NFT craze, SUNSTEP is your gateway to participating in the purchase and sale of
                  these digital assets -- from art to music to entire virtual worlds.</p>
              </div>
              {listProjects.length > 0 ? (
                <div className="row about-row">
                  <div className="col-md-5">
                    <div className="about-content">
                      <h3>Get popular <span>{listProjects[8 + count].name}</span> NFT</h3>
                      <p>There are total <span>{listProjects[8 + count].total_minted[0]}</span> assets minted and out of <span>{listProjects[8 + count].total_assets}</span> are available.
                        There are around <span>{listProjects[8 + count].total_owners[0]}</span> lucky owners.
                        You want to know more about the collection please click on the button below.
                      </p>
                      <Link to={`/collection/${listProjects[8 + count].policies[0]}`} className="btn primary-btn">Show more</Link>
                    </div>
                  </div>
                  <div className="col-md-5 offset-md-2">
                    <div className="about-img like_share_all_content feed_l_content">
                      {(listProjects[8 + count].thumbnail_type ? listProjects[8 + count].thumbnail_type : "").includes('image') ? (
                        <FadeImg
                          src={listProjects[8 + count].image}
                          alt={listProjects[8 + count].name}
                          style={{ "opacity": "1" }}
                        />
                      ) : (listProjects[8 + count].thumbnail_type ? listProjects[8 + count].thumbnail_type : "").includes('video') ? (
                        <FadeVideo
                          src={listProjects[8 + count].image}
                          style={{ "opacity": "1" }}
                        />
                      ) :
                        <FadeImg
                          src={listProjects[8 + count].image}
                          alt={listProjects[8 + count].name}
                          style={{ "opacity": "1" }}
                        />
                      }
                      <div className="like_share_all">
                        <a href="#" ><i className="fa-solid fa-heart"></i></a>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#shareModal" onClick={() => setSelected(listProjects[8 + count], '100')}><i className="fa-solid fa-share"></i></a>
                      </div>
                    </div>
                  </div>

                </div>
              ) : <></>}

            </div>
          </div>

          <div className="top_nft_table">
            <div className="container">
              <h3 className="h3_heading">Top NFTS</h3>
              <div className="top_nft_box">
                <div className="table-responsive">
                  <table className="table_nft_data mobile_view">
                    <thead>
                      <tr className="table_nft_head">
                        <td>Collection</td>
                        <td>Volume</td>
                        <td>24h %</td>
                        <td>7d %</td>
                        <td>Floor Price</td>
                        <td>Owners</td>
                        <td>Items</td>
                      </tr>
                    </thead>
                    <tbody>
                      {listProjects
                        .slice(0, 5)
                        .map((project, i) => {
                          console.log(project)
                          return (
                            <tr key={i}>
                              <td>
                                <ul className="display_flex">
                                  <li className="user_number">{ }</li>
                                  <li className="user_det_img">
                                    {(project.thumbnail_type ? project.thumbnail_type : "").includes('image') ? (
                                      <FadeImg
                                        src={project.image}
                                        alt={project.name}
                                      />
                                    ) : (project.thumbnail_type ? project.thumbnail_type : "").includes('video') ? (
                                      <FadeVideo
                                        src={project.image}
                                      />
                                    ) :
                                      <FadeImg
                                        src={project.image}
                                        alt={project.name}
                                      />
                                    }
                                  </li>
                                  <li className="user_img_desc">
                                    <Link to={`/collection/${project.policies[0]}`}>{project.name}</Link>
                                    {/* <span>@diana wade</span> */}
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <img src={cardanoBlue} />
                                {numFormatter(
                                  project.volume
                                )}

                              </td>
                              <td>
                                <span
                                  data-tooltip={`Change in volume in past ${window === "7d" ? "7 days" : "1 day"
                                    }`}
                                  className={
                                    parseFloat(
                                      decimal(
                                        project[
                                        "1dChange"
                                        ]
                                      )
                                    ) >= 0
                                      ? "text-green"
                                      : "text-red"
                                  }
                                >
                                  {
                                    parseFloat(
                                      decimal(
                                        project[
                                        project[
                                        "1dChange"
                                        ]
                                        ]
                                      )
                                    ) >= 0
                                      ? <i className="fa-solid fa-arrow-up-long"></i> :
                                      <i className="fa-solid fa-arrow-down-long"></i>

                                  }
                                  {parseFloat(
                                    decimal(
                                      project[
                                      "1dChange"
                                      ]
                                    ) * 100
                                  ).toFixed(0)}
                                  %

                                </span>
                              </td>
                              <td>
                                <span
                                  data-tooltip={`Change in volume in past ${window === "7d" ? "7 days" : "1 day"
                                    }`}
                                  className={
                                    parseFloat(
                                      decimal(
                                        project[
                                        "7dChange"
                                        ]
                                      )
                                    ) >= 0
                                      ? "text-green"
                                      : "text-red"
                                  }
                                >
                                  {
                                    parseFloat(
                                      decimal(
                                        project[
                                        project[
                                        "7dChange"
                                        ]
                                        ]
                                      )
                                    ) >= 0
                                      ? <i className="fa-solid fa-arrow-up-long"></i> :
                                      <i className="fa-solid fa-arrow-down-long"></i>

                                  }
                                  {parseFloat(
                                    decimal(
                                      project[
                                      "7dChange"
                                      ]
                                    ) * 100
                                  ).toFixed(0)}
                                  %

                                </span>
                              </td>
                              <td>
                                <img src={cardanoBlue} />
                                {numFormatter(
                                  project.floor_price
                                )}
                              </td>
                              <td> {numFormatter(
                                project.total_owners
                              )}</td>
                              <td>{numFormatter(
                                project.total_assets
                              )}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>



              <div className="row">

                <div className="col-md-6">
                  <h3 className="h3_heading">Top Gainers Last 24 hours</h3>
                  <div className="top_nft_box">
                    <div className="table-responsive">
                      <table className="table_nft_data">
                        <thead>
                          <tr className="table_nft_head">
                            <td>Collection</td>
                            <td>24h %</td>
                          </tr>
                        </thead>
                        <tbody>
                          {listProjects1d.sort((a, b) => (b["1dChange"] - a["1dChange"]))
                            .slice(0, 5)
                            .map((project, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <ul className="display_flex">
                                      <li className="user_number">{ }</li>
                                      <li className="user_det_img">
                                        {(project.thumbnail_type ? project.thumbnail_type : "").includes('image') ? (
                                          <FadeImg
                                            src={project.image}
                                            alt={project.name}
                                          />
                                        ) : (project.thumbnail_type ? project.thumbnail_type : "").includes('video') ? (
                                          <FadeVideo
                                            src={project.image}
                                          />
                                        ) :
                                          <FadeImg
                                            src={project.image}
                                            alt={project.name}
                                          />
                                        }
                                      </li>
                                      <li className="user_img_desc">
                                        <Link to={`/collection/${project.policies[0]}`}>{project.name}</Link>
                                        {/* <span>@diana wade</span> */}
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <span
                                      data-tooltip={`Change in volume in past ${window === "7d" ? "7 days" : "1 day"
                                        }`}
                                      className={
                                        parseFloat(
                                          decimal(
                                            project[
                                            "1dChange"
                                            ]
                                          )
                                        ) >= 0
                                          ? "text-green"
                                          : "text-red"
                                      }
                                    >
                                      {
                                        parseFloat(
                                          decimal(
                                            project[
                                            project[
                                            "1dChange"
                                            ]
                                            ]
                                          )
                                        ) >= 0
                                          ? <i className="fa-solid fa-arrow-up-long"></i> :
                                          <i className="fa-solid fa-arrow-down-long"></i>

                                      }
                                      {parseFloat(
                                        decimal(
                                          project[
                                          "1dChange"
                                          ]
                                        ) * 100
                                      ).toFixed(0)}
                                      %

                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>


                <div className="col-md-6">
                  <h3 className="h3_heading">Top Gainers Last 7 days</h3>
                  <div className="top_nft_box">
                    <div className="table-responsive">
                      <table className="table_nft_data">
                        <thead>
                          <tr className="table_nft_head">
                            <td>Collection</td>
                            <td>Volume</td>
                          </tr>
                        </thead>
                        <tbody>
                          {listProjects7d.sort((a, b) => (b["7dChange"] - a["7dChange"]))
                            .slice(0, 5)
                            .map((project, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <ul className="display_flex">
                                      <li className="user_number">{ }</li>
                                      <li className="user_det_img">
                                        {(project.thumbnail_type ? project.thumbnail_type : "").includes('image') ? (
                                          <FadeImg
                                            src={project.image}
                                            alt={project.name}
                                          />
                                        ) : (project.thumbnail_type ? project.thumbnail_type : "").includes('video') ? (
                                          <FadeVideo
                                            src={project.image}
                                          />
                                        ) :
                                          <FadeImg
                                            src={project.image}
                                            alt={project.name}
                                          />
                                        }
                                      </li>
                                      <li className="user_img_desc">
                                        <Link to={`/collection/${project.policies[0]}`}>{project.name}</Link>
                                        {/* <span>@diana wade</span> */}
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <span
                                      data-tooltip={`Change in volume in past ${window === "7d" ? "7 days" : "1 day"
                                        }`}
                                      className={
                                        parseFloat(
                                          decimal(
                                            project[
                                            "7dChange"
                                            ]
                                          )
                                        ) >= 0
                                          ? "text-green"
                                          : "text-red"
                                      }
                                    >
                                      {
                                        parseFloat(
                                          decimal(
                                            project[
                                            project[
                                            "7dChange"
                                            ]
                                            ]
                                          )
                                        ) >= 0
                                          ? <i className="fa-solid fa-arrow-up-long"></i> :
                                          <i className="fa-solid fa-arrow-down-long"></i>

                                      }
                                      {parseFloat(
                                        decimal(
                                          project[
                                          "7dChange"
                                          ]
                                        ) * 100
                                      ).toFixed(0)}
                                      %

                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="artworks-wrap">
            <div className="container">
              <div className="step-heading-row">
                <h2>Collections</h2>
                <p>Explore NFT collections from different categories</p>
              </div>
              <div className="row artworks-row">
                {listProjects
                  .slice(0, show_num_projects_initial)
                  .map((project, i) => {
                    return (
                      <div className="col-md-3" key={i}>
                        <div className="featured-box">
                          <div className="fadeimg-box featured-imgbox like_share_all_content feed_l_content">
                            {(project.thumbnail_type ? project.thumbnail_type : "").includes('image') ? (
                              <FadeImg
                                className="fadeimg"
                                src={project.image}
                                alt={project.name}
                              />
                            ) : (project.thumbnail_type ? project.thumbnail_type : "").includes('video') ? (
                              <FadeVideo
                                src={project.image}
                              />
                            ) :
                              <FadeImg
                                src={project.image}
                                alt={project.name}
                              />
                            }
                            <div className="like_share_all">
                              <a href="#" ><i className="fa-solid fa-heart"></i></a>
                              <a href="#" data-bs-toggle="modal" data-bs-target="#shareModal" onClick={() => setSelected(project, '100')}><i className="fa-solid fa-share"></i></a>
                            </div>
                          </div>
                          <div className="featured-information">
                            <div className="featured-user-info">
                              <span data-tooltip={project.name} className="user-featured-title-bid">{(project.name).slice(0, 15)}</span>
                            </div>
                            <div className="featured-bid" style={{display:"flex", gap:"5px", alignItems: "center"}}>
                              <span className="user-featured-title-bid">₳{numFormatter(project.volume)}</span>

                              <span
                                data-tooltip={`Change in volume in past ${window === "7d" ? "7 days" : "1 day"
                                  }`}
                                className={
                                  parseFloat(
                                    decimal(
                                      project[
                                      window === "7d"
                                        ? "7dChange"
                                        : "1dChange"
                                      ]
                                    )
                                  ) >= 0
                                    ? "text-green"
                                    : "text-red"
                                }
                              >
                                {parseFloat(
                                  decimal(
                                    project[
                                    window === "7d"
                                      ? "7dChange"
                                      : "1dChange"
                                    ]
                                  ) * 100
                                ).toFixed(0)}
                                %
                                {
                                  parseFloat(
                                    decimal(
                                      project[
                                      window === "7d"
                                        ? "7dChange"
                                        : "1dChange"
                                      ]
                                    )
                                  ) >= 0
                                    ? <i className="fa-solid fa-arrow-up-long"></i> :
                                    <i className="fa-solid fa-arrow-down-long"></i>

                                }
                              </span>

                            </div>
                          </div>
                          <div className="featured-action">
                            <Link to={`/collection/${project.policies[0]}`} className="btn primary-btn">
                              See More</Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="artworks-wrap">
            <div className="container">
              <div className="step-heading-row">
                <h2>Featured Artworks</h2>
              </div>
              <div className="row artworks-row">
                {listProjects
                  .slice(0, 3)
                  .map((project, i) => {
                    return (
                      <div className="col-md-4" key={i}>
                        <div className="featured-box">
                          <div className="featured-imgbox like_share_all_content feed_l_content">
                            {(project.thumbnail_type ? project.thumbnail_type : "").includes('image') ? (
                              <FadeImg
                                src={project.image}
                                alt={project.name}
                              />
                            ) : (project.thumbnail_type ? project.thumbnail_type : "").includes('video') ? (
                              <FadeVideo
                                src={project.image}
                              />
                            ) :
                              <FadeImg
                                src={project.image}
                                alt={project.name}
                              />
                            }
                            <div className="like_share_all">
                              <a href="#" ><i className="fa-solid fa-heart"></i></a>
                              <a href="#" data-bs-toggle="modal" data-bs-target="#shareModal" onClick={() => setSelected(project, '100')}><i className="fa-solid fa-share"></i></a>
                            </div>
                          </div>
                          <div className="featured-information">
                            <div className="featured-user-info">
                              <span className="user-featured-title-bid">{project.name}</span>
                            </div>
                            <div className="featured-bid">
                              <span className="user-featured-title-bid">₳{numFormatter(project.volume)}</span>

                              <span
                                data-tooltip={`Change in volume in past ${window === "7d" ? "7 days" : "1 day"
                                  }`}
                                className={
                                  parseFloat(
                                    decimal(
                                      project[
                                      window === "7d"
                                        ? "7dChange"
                                        : "1dChange"
                                      ]
                                    )
                                  ) >= 0
                                    ? "text-green"
                                    : "text-red"
                                }
                              >
                                {parseFloat(
                                  decimal(
                                    project[
                                    window === "7d"
                                      ? "7dChange"
                                      : "1dChange"
                                    ]
                                  ) * 100
                                ).toFixed(0)}
                                %
                                {
                                  parseFloat(
                                    decimal(
                                      project[
                                      window === "7d"
                                        ? "7dChange"
                                        : "1dChange"
                                      ]
                                    )
                                  ) >= 0
                                    ? <i className="fa-solid fa-arrow-up-long"></i> :
                                    <i className="fa-solid fa-arrow-down-long"></i>

                                }
                              </span>

                            </div>
                          </div>
                          <div className="featured-action">
                            <a type="button" className="btn primary-btn">Place a Bid</a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="artworks-wrap">
            <div className="container">
              <div className="step-heading-row create-nft">
                <h2>Create and sell your <span>NFTs</span></h2>
              </div>
              <div className="product-background-new">
                <div className="product-row-background">
                  <table className="full-width">
                    <tbody>
                      <tr className="nft-create-sell-row">
                        <td className="nft-heading-num">
                          <h6>Step 01/</h6>
                        </td>
                        <td className="nft-heading">
                          <h6>create account and setup wallet</h6>
                        </td>
                        <td>
                          <h6>create your own proprietary digital wallet to manage both buyers and sellers. Use cards and other tokens to pays from the wallet.</h6>
                        </td>
                      </tr>
                      <tr className="nft-create-sell-row">
                        <td className="nft-heading-num">
                          <h6>Step 02/</h6>
                        </td>
                        <td className="nft-heading">
                          <h6>create your collections</h6>
                        </td>
                        <td>
                          <h6>Compete with the most iconic wallets in the market by using our platform.
                            Use cards and other tokens to pay from the wallet.</h6>
                        </td>
                      </tr>
                      <tr className="nft-create-sell-row">
                        <td className="nft-heading-num">
                          <h6>Step 03/</h6>
                        </td>
                        <td className="nft-heading">
                          <h6>add your NFTs</h6>
                        </td>
                        <td>
                          <h6>our platform integrates to any Enterprise-level ecosystem seamlessly. Use cads and other tokens to pay from wallet</h6>
                        </td>
                      </tr>
                      <tr className="nft-create-sell-end-row nft-create-sell-row">
                        <td className="nft-heading-num">
                          <h6>Step 04/</h6>
                        </td>
                        <td className="nft-heading">
                          <h6>sell your NFTs</h6>
                        </td>
                        <td>
                          <h6>Blockchain Bandicoot together with NFT-MINTS is a collection of over 5,000 unique NFTs living on the Ethereum blockchain</h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="artworks-wrap">
            <div className="container">
              <div className="nft-explore-box-wrapp">
                <div className="nft-explore-box">
                  <div className="step-heading-row create-nft all-nft-explore">
                    <h2>All <span>NFTs</span> you can explore</h2>
                    <p>create your own proprietary digital wallet to manage both buyers and
                      <br /> sellers. Use cards and other tokens to pays from the wallet.</p>
                    <div className="nft-wallet-btn-wrapp">
                      <Link href="#" className="btn_exlore">Connect Wallet</Link>
                      <Link href="#" className="btn_exlore more_explore">EXPLORE MORE</Link>
                    </div>
                  </div>
                  <div className="nft-explore-boximg">
                    <img src="images/NFTs.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(state_account.notificationstatus==true) ? (
        <button
          type="button"
          className="btn btn-primary nortification_footer"
        >
          <div className="row">
            <div className="col-md-10">{state_account.notificationmessage}</div>
            <div className="col-md-2">
              <span className="badge badge-light">
                <i
                  className="fa-solid fa-xmark hide_x"
                  onClick={closeNotification}
                ></i>
              </span>
            </div>
          </div>
        </button>
      ) : (
        <></>
      )}
          <Footer />
        </section>
      ) : (
        <></>
      )
      }
    </>
  );
};

export {
  Home
}
