import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navbar } from "components";
import { sunstep_collection_share } from "../../store/account/api";
import { notification_status, notification_message } from '../../store/account/accountActions'
import "./style.scss";
import { useLocation } from 'react-router-dom';
import { opencnft_get_top_projects as opencnftGetTopProjects } from "store/collection/api";
import { FadeImg, FadeVideo } from "components/Fades";
import { get_asset_image_source, numFormatter } from "utils/converter";
const NotConnected = () => {

  return (
    <section className="hero is-large">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1>
            <span
              className="icon"
              style={{ fontSize: "100px", marginBottom: "50px" }}
            >
              <i className="fas fa-plug"></i>
            </span>
          </h1>
          <p className="title">
            Connect your wallet to share assets on social platform
          </p>
          <p className="subtitle">
            Do not have Nami Wallet?{" "}
            <a href="https://namiwallet.io/" target="_blank" rel="noreferrer">
              Download
            </a>{" "}
            now!
          </p>
        </div>
      </div>
    </section>
  );
};
const ExploreSplash = () => {
  const show_num_projects_initial = 12;
  const [pending, setPending] = useState(false);
  const [window, setWindow] = useState("7d");
  const [showLimit, setShowLimit] = useState(show_num_projects_initial);
  const [topProjectData, setTopProjectData] = useState([]);
  const [CNFTLoaded, setCNFTLoaded] = useState(false);
  const [selectedCollection, setselectedCollection] = useState({});
  const dispatch = useDispatch();
  const [ticking, setTicking] = useState(true),
    [count, setCount] = useState(0)
  const [listProjects, setListProjects] = useState([]);
  const state_account = useSelector((state) => state.account);
  const state_wallet = useSelector((state) => state.wallet);
  const state_collection = useSelector((state) => state.collection);
  const [followers, setFollowers] = useState([]);
  const containerRef = useRef(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [inputs, setInput] = useState('');
  const [share, setShare] = useState('');
  const [specificFrind, setSpecificFrind] = useState([])
  const [specificFriend, setSpecificFriend] = useState([])
  const opencnft_get_top_projects = (time, callback) => {
    dispatch(opencnftGetTopProjects(time, callback));
  };
  const location = useLocation();
  const { pathname, search } = location;
  console.log(pathname)
  useEffect(() => {
    if (state_collection.selected_collection) {
      setselectedCollection(state_collection.selected_collection);
    }
  }, [state_collection.selected_collection]);

  const share_social = () => {

    if (state_account.profile.email != undefined) {
      const shareObj = {
        userid: state_account.profile.email,
        sharedto: specificFrind,
        notes: inputs,
        shareimage: selectedCollection.image,
        productid: selectedCollection.policies[0],
        name: selectedCollection.name,
      };

      dispatch(
        sunstep_collection_share(shareObj, (res) => {
          setShowNotificationMessage("Share Sucessfully..");
          setShowNotification(true);
          dispatch(notification_status(true))
          dispatch(notification_message("Share Sucessfully.."))
          setTimeout(() => {
            setShowNotification(false);
            dispatch(notification_status(false))
          }, 5000);
          setInput("")
          setShare('')
          if (res.data.email != undefined) {
            // Home();
          }
          // history.push("/social");
        })
      );
    }
  };
  const closeNotification = () => {
    setShowNotification(false);
    setInput("")
  };
  const handleChange = (e) => {
    if (e.target.name === 'note') {
      setInput(e.target.value)
    }
  };
  const handleClick = (userEmail) => {
    if (specificFriend.includes(userEmail)) {

      setSpecificFriend(specificFriend.filter((email) => email !== userEmail));
    } else {

      setSpecificFriend([...specificFriend, userEmail]);
    }
  };
  function prepare_data(project_list, limit) {
    let list = [];
    // 
    for (var i in project_list) {
      let project = project_list[i];
      project.rank = i;
      project.image = get_asset_image_source(
        Array.isArray(project.thumbnail)
          ? project.thumbnail[0].includes("data:image")
            ? project.thumbnail
            : project.thumbnail[0]
          : project.thumbnail
      );
      list.push(project);
      // if(i>=limit) break;
    }
    setListProjects(list);
    setPending(false);
    setCNFTLoaded(true);
  }
  const onchange_window = (win) => {
    setWindow(win);
    setPending(true);
    setListProjects([]);
    setShowLimit(show_num_projects_initial);
    opencnft_get_top_projects(win, (res) => {
      setTopProjectData(res.data);
      prepare_data(res.data, show_num_projects_initial);
    });
  };
  // useEffect(() => {
  //   const timer = setTimeout(() => ticking && setCount(count + 1), 10000)
  //   return () => clearTimeout(timer)
  // }, []);
  // useEffect(() => {
  //   // if (listProjects.length === 0) onchange_window("7d");
  // }, []);

  return (
    <div className={pathname != "/account" ? "explore_areabg" : ""}>
      {pathname != "/account" && <Navbar />}
      {pathname != "/account" && <div className="blur-effect"></div>}
      <div
        className="modal fade"
        id="shareModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <form>
          <div className="feed_details modal-dialog offer_dialog modal-lg">
            <div className="modal-content feed_modal_content">
              <div className="modal-header">
                <h5 className="modal-title text-white">Share</h5>
                <a className="modal-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                  setInput('')
                  setShare('')
                  setSpecificFrind([])

                }}><i className="fa-solid fa-xmark"></i></a>
              </div>
              <div className="modal-body">
                {
                  !state_wallet.connected ? (
                    <NotConnected />
                  ) : <></>
                }
                {showNotification ? (
                  <button
                    type="button"
                    className="btn btn-primary nortification_footer"
                  >
                    <div className="row">
                      <div className="col-md-10">{showNotificationMessage}</div>
                      <div className="col-md-2">
                        <span className="badge badge-light">
                          <i
                            className="fa-solid fa-xmark hide_x"
                            onClick={closeNotification}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </button>
                ) : (
                  <></>
                )}
                {
                  state_wallet.connected ? (
                    <>
                      <div className="row">
                        <div className="col-md-4">
                          {/* {listProjects.length > 0 ? (
                            (selectedCollection.thumbnail_type
                              ? selectedCollection.thumbnail_type
                              : ""
                            ).includes("image") ? (
                              <FadeImg
                                src={selectedCollection.image}
                                alt={selectedCollection.name}
                              />
                            ) : (selectedCollection.thumbnail_type
                              ? selectedCollection.thumbnail_type
                              : ""
                            ).includes("video") ? (
                              <FadeVideo src={selectedCollection.image} />
                            ) : ( */}
                          <FadeImg
                            src={selectedCollection.image}
                            alt={selectedCollection.name}
                          />
                          {/* )
                          ) : (
                            <></>
                          )} */}
                          {/* <img src="images/feed/1.png" alt="" className="img-responsive" /> */}
                        </div>
                        <div className="col-md-8">
                          <div className="first_row margin-bottom-20">
                            <div className="row">
                              <div className="col-md-12">
                                <ul className="display_flex margin-bottom-20">
                                  <li className="user_det_img">
                                    <img
                                      src={state_account.profile.profileimage}
                                      alt="sunstep"
                                    />
                                  </li>
                                  <li className="user_img_desc">
                                    Be the first to share and get rewards.
                                    <span>@Sunstep Team</span>
                                  </li>
                                </ul>
                                <textarea
                                  rows="4"
                                  cols="10"
                                  type="text"
                                  name='note'
                                  value={inputs}
                                  onChange={handleChange}
                                  className="comment_text"
                                  placeholder="Write something here about the point of interests of sharing the NFT. It might help the buyer to purchase. If that conversation happens you will be rewarded."
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="second_row margin-bottom-20">
                            <p className="text-white">Share With </p>
                            <div className="share_area" >
                              <ul className="share_list">
                                <li onClick={() => setShare('Public')}>
                                  <input
                                    type="radio"
                                    id="f-option"
                                    name="share"
                                    value={share}
                                    checked={share == "Public"}
                                  />
                                  <label htmlFor="f-option">Public</label>
                                  <div className="check"></div>
                                </li>
                                <li onClick={() => setShare('Friends')}>
                                  <input
                                    type="radio"
                                    id="s-option"
                                    name="share"
                                    value={share}
                                    checked={share == "Friends"}
                                  />
                                  <label htmlFor="s-option">Friends</label>
                                  <div className="check">
                                    <div className="inside"></div>
                                  </div>
                                </li>
                                <li
                                  className="dropdown-toggle"
                                  type="button"
                                  id="defaultDropdown"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="true"
                                  aria-expanded="false"

                                >
                                  <label htmlFor="s-option1">
                                    Specific Friends{" "}
                                    <i className="fa-solid fa-circle-chevron-down text-right spc_icon"></i>
                                  </label>
                                </li>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="defaultDropdown"
                                >
                                  {followers
                                    .slice(0, followers.length - 1)
                                    .map((user, i) => {
                                      return (
                                        <div
                                          className="display_flex specific_area"
                                          key={i}
                                        >
                                          <li>
                                            <p className="user_det_img">
                                              <img
                                                src={`https://gateway.pinata.cloud/ipfs/${user.profileimage}`}
                                                alt=""
                                              />
                                            </p>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              {user.firstname} {user.lastname}
                                            </a>
                                          </li>
                                          <li>
                                            <li>
                                              <a
                                                type="button"
                                                className="btn email-send-btn"
                                                onClick={() => handleClick(user.email)}
                                              >
                                                {specificFriend.includes(user.email) ? "Unselect" : "Select"}
                                              </a>
                                            </li>
                                          </li>
                                        </div>
                                      );
                                    })}
                                </ul>
                              </ul>
                            </div>
                          </div>
                          <div className="share_now">
                            <div className="display_flex">
                              {(share != 'Public' || share != 'Friend' || specificFrind.length === 0) && inputs === '' ? <a
                                type="button"
                                className="btn email-send-btn"
                                onClick={() => {
                                  setShowNotificationMessage("All fields are required to share..");
                                  setShowNotification(true);
                                }}
                              >
                                Share
                              </a>
                                :
                                <a
                                  type="button"
                                  className="btn email-send-btn"
                                  onClick={() => share_social()}
                                  data-bs-dismiss='modal'
                                >
                                  Share
                                </a>}
                              <a href="#" data-bs-dismiss='modal'
                                onClick={() => {
                                  setInput('')
                                  setShare('')
                                  setSpecificFrind([])
                                }}
                              >
                                Cancel
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
              </div>
            </div>
          </div>
        </form>
      </div>
      {pathname != "/account" && <div className="home-header explore_area">
        <div className="explore_header_section">
          <div className="container">
            <div className="explore_heading">
              {/* <h1>Welcome To The World Of NFTS</h1>
              <p>Explore finest and trending NFTS and make them yours</p> */}
              <h1>Explore a new universe of<br /> digital collectibles</h1>
            </div>
          </div>
        </div>

      </div>}
    </div>
  );
};

export default ExploreSplash;
