import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navbar } from "components";

import "./style.scss";


const SocialSplash = () => {
  return (
    <Navbar />
  );
};
export default SocialSplash;
