import { combineReducers } from "redux";

import walletReducer from "./wallet/walletReducer";
import collectionReducer from "./collection/collectionReducer";
import createnftReducer from "./createnft/createnftReducer"
import errorReducer from "./error/errorReducer";
import accountReducer from "./account/accountReducer";
import mintReducer from "./mint/mintReducer";
export default combineReducers({
  wallet: walletReducer,
  collection: collectionReducer,
  // createnft: createnftReducer,
  error: errorReducer,
  account: accountReducer,
  mint: mintReducer
});
