import * as types from "./mintTypes";
import * as walletTypes from "../wallet/walletTypes";


let collectioncreate = {
  creatoraddress: "",
  platformaddress: "",
  collectionname: "",
  collectiondescription: "",
  royaltyaddress: "",
  royaltypercentage: "",
  collectionimage: "",
  collectionbanner: "",
  collectionslotnumber: 0,
  twitter: "",
  facebook: "",
  instagram: "",
  discord: "",
  website: "",
  medium: "",
  telegram: "",
  slotnumber: 0,
  platform: "",
  policytype: "",
  policyid: "",
  collections: [],
  nftdata: [],
  filename: "",


}



export default function mintReducer(state = collectioncreate, { type, payload }) {
  switch (type) {

    case types.GET_CURRENT_SLOT_SUCCESS:
      return {
        ...state,
        slotnumber: payload,
      };

    case types.GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: payload,
      };
    case types.ADD_PLATFORM_SUCCESS:
      return {
        ...state,
        platform: payload,
      };


    default:
      return state;
  }
}
