import React from "react";
import { Switch, Route } from "react-router-dom";

import {
  Home,
  Collection,
  Asset,
  Account,
  About,
  Guide,
  GuideBuy,
  GuideSell,
  Explore,
  Mint,
  Profile,
  Social
} from 'pages'

import {
  ExploreSplash,
  HomeSplash,
  CollectionSplash,
  AssetSplash,
  CreatenftSplash,
  ProfileSplash,
  AccountSplash,
  SocialSplash,
  MintSplash
} from 'components'

import { MainLayout } from "layouts";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css";

const RenderRoutes = () => {

  return (
    <Switch>
      <Route exact path="/">
        <MainLayout>
          <HomeSplash />
          <Home />
        </MainLayout>
      </Route>
      <Route exact path="/collection/:collection_id">
        <MainLayout>
          <CollectionSplash />
          <Collection />
        </MainLayout>
      </Route>
      <Route exact path="/assets/:policy_id/:asset_id">
        <MainLayout>
          <AssetSplash />
          <Asset />
        </MainLayout>
      </Route>
      <Route exact path="/account">
        <MainLayout>
          <AccountSplash />
          <Account />
        </MainLayout>
      </Route>
      <Route exact path="/explore">
        <MainLayout>
          <ExploreSplash />
          <Explore />
        </MainLayout>
      </Route>
      <Route exact path="/about">
        <MainLayout>
          <About />
        </MainLayout>
      </Route>
      <Route exact path="/guide">
        <MainLayout>
          <Guide />
        </MainLayout>
      </Route>
      <Route exact path="/guide-buy">
        <MainLayout>
          <GuideBuy />
        </MainLayout>
      </Route>
      <Route exact path="/guide-sell">
        <MainLayout>
          <GuideSell />
        </MainLayout>
      </Route>
      <Route exact path="/mint">
        <MainLayout>
          <MintSplash />
          <Mint />
        </MainLayout>
      </Route>
      <Route exact path="/profile">
        <MainLayout>
          <ProfileSplash />
          <Profile />
        </MainLayout>
      </Route>
      <Route exact path="/social">
        <MainLayout>
          <SocialSplash />
          <Social />
        </MainLayout>
      </Route>
    </Switch>
  );
};

export default RenderRoutes;