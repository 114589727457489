// import { TwitterAuthProvider, FacebookAuthProvider } from "firebase/auth";
// export const twitter_login = (callback) => async (dispatch) => {
//   const provider = new TwitterAuthProvider();
// }
import axios from "axios"
import { sunstepApi } from '../../config';
import {
  get_current_slot_success,
  get_collection_success,
  add_platform_sucess,
} from "./mintActions";
import { set_error } from "../error/errorActions";
import { resolveError } from "utils/resolver";


export const authenticate_pinata = async () => {
  const payload = {}
  axios.post(`${sunstepApi}/cardano/pinatasdk`, payload)
}

export const file_upload = async (formData, callback) => {
  await axios.post(`${sunstepApi}/cardano/pinatasdk/fileupload`, formData)
    .then(res => {
      const value = res.data

      callback({ success: true, data: value });
    })
    .catch(err => {
      alert(err)
    })
}

export const createCollection = async (payload, callback) => {
  await axios.post(`${sunstepApi}/cardano/add/newcollection`, payload)
    .then(res => {
      const value = res.data

      callback({ success: true, data: value });
    })
    .catch(err => {
      alert(err)
    })
}

export const get_current_slot =
  () => async (dispatch) => {
    const payload = {}
    axios.post(`${sunstepApi}/cardano/cardanocli/getcurrentslot`, payload)
      .then(res => {
        const value = res.data

        dispatch(get_current_slot_success(parseInt(value[0])))
        // callback({ success: true, data: res.data });
      })
      .catch(err => {
        alert(err)
      })
  };


export const create_policy = (payload, callback) => async (dispatch) => {

  axios.post(`${sunstepApi}/cardano/createpolicy`, payload)
    .then(res => {
      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })


}

export const create_platform = (payload, callback) => async (dispatch) => {

  axios.post(`${sunstepApi}/cardano/createplatform`, payload)
    .then(res => {
      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })


}

export const royalties_metadata_generation = (payload, callback) => async (dispatch) => {
  
  await axios.post(`${sunstepApi}/cardano/create/metadata/royalties`, payload)
    .then(res => {
      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })
}

export const user_nft_metadata_generation = (payload, callback) => async (dispatch) => {
  
  await axios.post(`${sunstepApi}/cardano/create/metadata/user/nft`, payload)
    .then(res => {
      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })
}

export const user_nft_build_txn_file_generation = (payload, callback) => async (dispatch) => {
  
  await axios.post(`${sunstepApi}/cardano/buildtxn/user/nft`, payload)
    .then(res => {
      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })
}

export const user_nft_sign_txn_file_generation = (payload, callback) => async (dispatch) => {
  
  await axios.post(`${sunstepApi}/cardano/signtxn/user/nft`, payload)
    .then(res => {
      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })
}

export const user_nft_submit_txn_file_generation = (payload, callback) => async (dispatch) => {
  
  await axios.post(`${sunstepApi}/cardano/submittxn/user/nft`, payload)
    .then(res => {
      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })
}

export const get_current_balance_of_platform_address = (payload, callback) => async () => {
  
  await axios.post(`${sunstepApi}/cardano/cardanocli/getwalletbalanceutxobyaddress`, payload)
    .then(res => {
      // const value = res.data

      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })
}

export const build_txn_for_royalties = async (payload, callback) => {
  await axios.post(`${sunstepApi}/cardano/buildtxn/royalties`, payload)
    .then(res => {
      // const value = res.data

      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })
}

export const sign_txn_for_royalties = async (payload, callback) => {
  await axios.post(`${sunstepApi}/cardano/signtxn/royalties`, payload)
    .then(res => {
      // const value = res.data

      callback({ success: true, data: res.data });
    })
    .catch(err => {
      alert(err)
    })
}

export const submit_txn_for_royalties = async (payload, callback) => {
  await axios.post(`${sunstepApi}/cardano/submittxn/royalties`, payload)
    .then(res => {
      const value = res.data

      callback({ success: true, data: value });
    })
    .catch(err => {
      alert(err)
    })
}


export const get_collection = 
(payload, callback) => async (dispatch) => {
  await axios.post(`${sunstepApi}/cardano/get/collections/walletaddress`, payload)
    .then(res => {
      dispatch(get_collection_success(res.data))
    })
    .catch(err => {
      alert(err)
    })
}

export const get_collection_details = async (payload, callback) => {
  await axios.post(`${sunstepApi}/cardano/single/collection/data`, payload)
    .then(res => {
      const value = res.data

      callback({ success: true, data: value });
    })
    .catch(err => {
      alert(err)
    })
}


