import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ListingSection from "./ListingSection";

import { opencnft_get_policy, collection_transaction_details } from "store/collection/api";
import { getcollectiontransaction } from "store/collection/collectionActions";
import { CollectionBanner } from "components";
import $ from "jquery";

import "./style.scss";

const Collection = () => {
  const { collection_id } = useParams();
  const dispatch = useDispatch();
  const state_collection = useSelector((state) => state.collection);
  const default_meta = {
    collection: {},
    opencnft: [],
  };

  const [policyIds, setPolicyIds] = useState([]);
  const [thisCollection, setThisCollection] = useState(default_meta);
  const [thisTransactions, setthisTransactions] = useState([])

  useEffect(() => {
    
    console.log(thisCollection)
    if (state_collection.loaded) {
      $('body').addClass('loaded');
      let currentCollectionIterator = undefined;
      currentCollectionIterator = {
        ...default_meta,
      };
      state_collection.top_collections.forEach(element => {
        element.policies.forEach(policy => {
          if (collection_id === policy) {
            setPolicyIds([policy]);
            currentCollectionIterator.collection = element;
          }
        });

      });
      for (let policyIdx in currentCollectionIterator.collection.policies) {
        let policy_id = currentCollectionIterator.collection.policies[policyIdx];
        dispatch(
          opencnft_get_policy(policy_id, (res) => {
            if (res.data.statusCode === 404) {
            } else {
              currentCollectionIterator.opencnft.push(res.data);
              console.log(res.data)
              setThisCollection({ ...currentCollectionIterator });
            }
          })
        );
        dispatch(
          collection_transaction_details(policy_id, (res) => {
            if (res.data.statusCode === 404) {
            } else {
              console.log(res.data)
              setthisTransactions(res.data)
              dispatch(getcollectiontransaction(res.data))
            }
          })
        );
      }
    }
  }, [state_collection.loaded]);
  console.log(thisCollection)
  return (
    <div className="nft-collection-banner-wrap">
      <CollectionBanner
        thisCollection={thisCollection}
        size={thisCollection.is_martify_verified ? "is-medium" : "is-small"}
        is_collection_page={true}
      />

      <section className="section">
        <div className="columns">
          <div className="column">
            <ListingSection
              thisCollection={thisCollection}
              policyIds={policyIds}
              thisTransactions={thisTransactions}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default Collection;
