import { Link } from "react-router-dom";
import { useSelector, } from "react-redux";
import React, { useEffect } from "react";

import "./style.scss";
import { FadeImg, FadeVideo } from "components/Fades";


import ButtonConnect from "../ButtonConnect";
import logo from '../../assets/images/logo.svg';
import producttitle from '../../assets/images/product-title.svg';
import userdefault from '../../assets/images/userdefault.png';
import searchimg from '../../assets/images/search.svg';
import $ from "jquery";

const Navbar = () => {
  const state_collection = useSelector((state) => state.collection);
  const state_account = useSelector(state => state.account)

  useEffect(() => {
    $('#popup').hide();
    $('#open').on('click', function () {
      $('#popup').slideToggle(500)
    });
    $(document).mouseup(function (e) {
      var popup = $("#popup");
      if (!$('#open').is(e.target) && !popup.is(e.target) && popup.has(e.target).length == 0) {
        popup.hide(500);
      }
    });
    function filter(text) {
      const el = document.querySelectorAll(".card__content__user-list li");
      let found = 0;
      el.forEach((li) => {
        const name = li.querySelector(".card__content__user-list__info p").textContent;
        if (String(name).includes(text)) {
          li.className = "fadeIn";
          li.style.display = "flex";
          found++;
        } else {
          li.style.display = "none";
        }
      });
      p.hidden = true;
      if (found === 0) {
        p.hidden = false;
      }
    }
    const input = document.querySelector(".card__content-search__field");
    const p = document.querySelector(".error");
    input.addEventListener("input", () => {
      filter(input.value);
    });
  }, []);
  return (
    <header>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <div className="header-logo-brand">
                <img src={logo} className="logo-slot" alt="" />
                <img src={producttitle} className="product-slot" alt="" />
              </div>
            </Link>
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon fa-solid fa-bars"></span>
          </button>
          <div className="collapse navbar-collapse navbar-center" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="navbar-item" to="/explore">
                  Explore
                </Link>
              </li>
              <li className="nav-item">
                <Link className="navbar-item" to="/social">
                  Social
                </Link>
              </li>
              <li className="nav-item">
                <Link className="navbar-item" to="/Mint">
                  Mint
                </Link>
              </li>
            </ul>


            <div className="header-rightcol">
              <div className="header-search">
                {/* <img src={searchimg} /> */}
                <input type="text" placeholder="Search" className="card__content-search__field" id="open" autoComplete="off" style={{ backgroundImage: "url(/static/media/search.69a036c6.svg)" }} />
                <main className="card__content home_card_content" id="popup">
                  <p className="error" hidden>not found</p>
                  {state_collection.top_collections.length > 0 ? (
                    <ul className="card__content__user-list">
                      {state_collection.top_collections
                        .slice(0, 90)
                        .map((project, i) => {
                          return (
                            <li key={i}>
                              {(project.thumbnail_type ? project.thumbnail_type : "").includes('image') ? (
                                <FadeImg
                                  src={project.image}
                                  alt={project.name}
                                  className="user_ser_img"
                                />
                              ) : (project.thumbnail_type ? project.thumbnail_type : "").includes('video') ? (
                                <FadeVideo
                                  src={project.image}
                                />
                              ) :
                                <FadeImg
                                  src={project.image}
                                  alt={project.name}
                                  className="user_ser_img"
                                />
                              }
                              <div className="card__content__user-list__info">
                                <Link to={`/collection/${project.policies[0]}`}><p>{project.name} </p></Link>
                              </div>
                              <div className="card_icon"><i className="fa-solid fa-id-badge"></i></div>
                              <div className="count_collection">{project.total_assets} Assets</div>
                            </li>
                          );
                        })}
                    </ul>
                  ) : <></>
                  }
                </main>

              </div>
              <div className="connect-col">
                <ButtonConnect />
              </div>
              <div className="connect-user">
                {state_account.profile.profileimage == '' || state_account.profile.profileimage == undefined ?
                  (<img src={userdefault} />) :
                  // <FadeImg
                  //   src={`${state_account.profile.profileimage}`}
                  //   alt="Sunstep profile picture"
                  //   className="pic"
                  // />
                  <img id="profilePic" className="pic" src={`${state_account.profile.profileimage}`} alt="" />
                }
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
