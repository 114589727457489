import * as types from "./actionTypes";
import * as walletTypes from "../wallet/walletTypes";

let userobj = {
  loaded: false,
  loading: false,
  profile: {
    walletaddress: "",
    email: "",
    firstname: "",
    profileimage: "",
    lastname: "",
    profilebannerimage: "",
    bio: "",
    facebook: "",
    twitter: "",
    linkedIn: "",
    telegram: "",
    discord: "",
    website: "",
    instagram: "",
    data: {}
  },
  favourites: [],
  followers: [],
  followings: [],
  recommenedusers: [],
  feed: [],
  followercount: 0,
  followingcount: 0,
  notificationstatus: false,
  notificationmessage: ''
};



export default function accountReducer(state = userobj, { type, payload }) {
  switch (type) {
    case types.USER_SIGNIN:
      userobj.profile.walletaddress = payload.walletaddress;
      userobj.profile.email = payload.email;
      userobj.profile.firstname = payload.firstname;
      userobj.profile.profileimage = payload.profileimage;
      userobj.profile.lastname = payload.lastname;
      userobj.profile.profilebannerimage = payload.profilebannerimage;
      userobj.profile.bio = payload.bio;
      userobj.profile.facebook = payload.facebook;
      userobj.profile.twitter = payload.twitter;
      userobj.profile.linkedIn = payload.linkedIn;
      userobj.profile.telegram = payload.telegram;
      userobj.profile.discord = payload.discord;
      userobj.profile.website = payload.website;
      userobj.profile.instagram = payload.instagram;
      return {
        ...state,
        loaded: true,
        loading: false,
        profile: userobj.profile,
      };
    case types.NOTIFICATION_STATUS:

      return {
        ...state,
        notificationstatus: payload
      };

    case types.NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationmessage: payload
      };

    case types.USER_ALL_USERS:
      return {
        ...state,
        loaded: true,
        loading: false,
        recommenedusers: payload,
      }
    case types.USER_FOLLOWER_COUNT:
      return {
        ...state,
        loaded: true,
        loading: false,
        followercount: payload,
      };
    case types.USER_FOLLOWING_COUNT:
      return {
        ...state,
        loaded: true,
        loading: false,
        followingcount: payload,
      };

    case types.USER_FOLLOWERS:
      return {
        ...state,
        loaded: true,
        loading: false,
        followers: payload,
      };

    case types.USER_FOLLOWINGS:
      return {
        ...state,
        loaded: true,
        loading: false,
        followings: payload,
      };

    default:
      return state;
  }
}
