import * as types from "./mintTypes";


export function user_signin(payload) {
  return {
    type: types.USER_SIGNIN,
    payload: payload,
  };
}


export function get_current_slot_success(data){
  return{
    type: types.GET_CURRENT_SLOT_SUCCESS,
    payload: data
  }
}

export function get_collection_success(data){
  return{
    type: types.GET_COLLECTIONS_SUCCESS,
    payload: data
  }
}


export function add_platform_sucess(data){
  return{
    type: types.ADD_PLATFORM_SUCCESS,
    payload: data
  }
}
