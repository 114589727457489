import React, { useEffect, useState } from "react";
import { FadeImg } from "components/Fades";

import "./style.scss";

import { $ } from "jquery";
import { useSelector, useDispatch } from "react-redux";


const FollowPopup = ({
    email, followtype, count
}) => {
    const accountDetails = useSelector((state) => state.account);
    console.log(count)
    const [showPopup, setShowPopup] = useState(false);
    const [followerList, setFollowerList] = useState([]);
    const [followingList, setFollowingList] = useState([]);

    // useEffect(() => {

    // }, []);

    const getFollowers = async () => {
        const accountpayload = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                useremail: email,
                pageno: "0",
                size: "50"
            })
        }
        await fetch("https://629e-202-142-70-142.in.ngrok.io/api/getuserfollowerdetails", accountpayload
        )
            .then((res) => res.json())
            .then((res) => {
                setFollowingList(res.userdetails);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const getFollowing = async () => {
        const accountpayload = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                useremail: email,
                pageno: "0",
                size: "50"
            })
        }
        await fetch("https://629e-202-142-70-142.in.ngrok.io/api/getuserfollowingdetails", accountpayload
        )
            .then((res) => res.json())
            .then((res) => {
                
                setFollowingList(res.userdetails);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <>{<button
            type="button"
            onClick={() => {
                setShowPopup(true);
                if (followtype == "Follower") {
                    getFollowers();
                } else if (followtype == "Following") {
                    getFollowing();
                }
            }} className="follow-details-button"
        >
            <div className="featured-bid"><span className="user-featured-title-bid">{count}</span>
                <span className="user-featured-title-bid">
                    {followtype}
                </span>
            </div>
        </button>}
            {showPopup ? (
                <div className={"modal " + (showPopup ? "is-active" : "")}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    {followtype}
                                </h4>
                                <button
                                    type="button"
                                    className="btn-close btn-close-white"
                                    onClick={() => setShowPopup(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                {
                                    followerList.length > 0 ?
                                        followerList.map((follow, i) => (
                                            <FollowUser type={"Follower"} user={follow} email={email} key={i}></FollowUser>
                                        )) : <></>
                                }
                                {
                                    followingList != null && followingList.length > 0 ?
                                        followingList.map((follow, i) => (
                                            <FollowUser type={"Following"} user={follow} email={email} key={i}></FollowUser>
                                        )) : <></>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            ) : <>

            </>
            }
        </>
    );
};
const FollowUser = ({
    type, user, email
}) => {
    const [removeFollower, setRemoveFollower] = useState(false);
    const [removeFollowing, setRemoveFollowing] = useState(false);

    const Removefollower = async () => {
        const accountpayload = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                useremail: email,
                followeruseremail: user.userdetails[0].email,
            })
        }
        await fetch("https://629e-202-142-70-142.in.ngrok.io/api/removefollowrlist", accountpayload
        )
            .then((res) => res.json())
            .then((res) => {
                setRemoveFollower(true);
            })
            .catch((err) => {
                console.error(err);
            });
    }
    const Removefollowing = async () => {
        const accountpayload = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                useremail: email,
                followinguseremail: user.userdetails[0].email,
            })
        }
        
        await fetch("https://629e-202-142-70-142.in.ngrok.io/api/removefollowrequest", accountpayload
        )
            .then((res) => res.json())
            .then((res) => {
                
                setRemoveFollowing(true);
            })
            .catch((err) => {
                console.error(err);
            });
    }
    return (
        <>
            {user.userdetails.length > 0 ? (
                <div className="display_flex specific_area">
                    <li>
                        <p className="user_det_img">

                            <FadeImg
                                src={`https://gateway.pinata.cloud/ipfs/${user.userdetails[0].profileimage}`}
                                alt="Sunstep profile picture"
                            />
                        </p>
                    </li>
                    <li><a className="dropdown-item" href="#">{user.userdetails[0].firstname} {user.userdetails[0].lastname}</a></li>

                    {type == "Following" ?
                        (removeFollowing ? <></> : <li><a type="button" className="btn email-send-btn" onClick={() => { Removefollowing(); }}>Unfollow</a></li>)
                        : (removeFollower ? <></> : <li><a type="button" className="btn email-send-btn" onClick={() => { Removefollower(); }}>Remove</a></li>)}
                </div>
            ) : <></>
            }
        </>
    );
}


export default FollowPopup;
