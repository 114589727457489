import React, { useEffect, useState } from "react";

import { usePalette } from 'react-palette'

import CollectionLinks from "../CollectionLinks";
import { numFormatter, get_asset_image_source } from "../../utils/converter";
import { FadeImg } from "components/Fades";
import website from '../../assets/images/website.svg';
import twitter from '../../assets/images/twitter.svg';
import discord from '../../assets/images/discord.svg';
import verify from '../../assets/images/verify.png';
import cardanoBlue from '../../assets/images/cardano-blue-light.svg';

import "./style.scss";

const CollectionBanner = ({ thisCollection, size, asset, is_collection_page, is_asset_page }) => {

  const [assetImgSrc, setAssetImgSrc] = useState(false);
  const [addresscopy, setaddresscopy] = useState(false)

  useEffect(() => {
    if (asset === undefined && ("opencnft" in thisCollection)) {
      if (thisCollection.opencnft.length > 0) {
        if (thisCollection.opencnft[0].thumbnail) {
          let imgsrc = get_asset_image_source(thisCollection.opencnft[0].thumbnail);
          setAssetImgSrc(imgsrc);

        }
      }
    }
  }, [thisCollection]);
  // console.log(thisCollection);
  const MAX_LENGTH = 25;
  async function copyaddress() {
    navigator.clipboard.writeText(thisCollection.collection.policies[0])
    setaddresscopy(!addresscopy)
  }

  return (
    <>
      {thisCollection.collection.name ? (
        <div className="collection_explore_area">
          <div className="container">
            {/* -------------------------------
            ---------------Item details-------- */}
            {/* <div className="featured-box"> */}
            {/* <div className="row spacearound collection-section">
              <div className="col-md-5">
                <div className="image_section">
                  <FadeImg
                    src={assetImgSrc ? assetImgSrc : undefined}
                  />
                </div>
                <div className="collection-price-section">
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="price-list">
                        <li><h5>Price</h5></li>
                        <li><h4>0.8 ADA </h4></li>
                        <li><h6>$7434</h6></li>
                      </ul>
                    </div>
                    <div className="col-md-6 text-align-end">Last sale: 0.0556 ADA</div>
                  </div>
                  <button class="btn btn_exlore btn_buynow">Buy Now</button>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div> */}
            {/* -------------------------------
            ----------------------------------- */}
            <div className="row collection-banner-section">
              <div className="col-md-4">
                <div className="art_universe">
                  <FadeImg
                    src={thisCollection.collection.image ? thisCollection.collection.image : undefined} className="art_img"
                  />
                  {thisCollection.collection.name ? (
                    <p className="art_heading">{thisCollection.collection.name}<img src={verify} alt=""
                      width="18" /></p>
                  ) : <></>
                  }

                  {thisCollection.collection ? (
                    <div className="align-items-xl-center gap-2 d-xl-flex"> <p>Policy Id: {`${thisCollection.collection.policies[0].slice(0, MAX_LENGTH)}....`}</p>
                      <i className={`fas ${addresscopy == true ? "fa-clipboard-check" : "fa-copy"} pointer_cursor`} onClick={copyaddress}></i>
                    </div>
                  ) : <></>
                  }
                </div>
              </div>
              <div className="col-md-8 justify-content-sm-between d-md-flex flex-md-column">
                <p>{thisCollection.collection.name}</p>
                <div className="collection-details-banner collection-banner-section">
                  {thisCollection.opencnft ? (
                    <div className="row">
                      <div className="col-md-3">
                        <h6 className="col_name">Items</h6>
                        <p className="collection_number_head">
                          {
                            numFormatter(
                              thisCollection.collection.total_assets)
                          }
                        </p>
                      </div>
                      <div className="col-md-3">
                        <h6 className="col_name">owners</h6>
                        <p className="collection_number_head">
                          {
                            thisCollection.collection.total_owners
                          }
                        </p>
                      </div>
                      <div className="col-md-3">
                        <h6 className="col_name">floor</h6>
                        <p className="collection_number_head">
                          {/* <img src="images/collection/round.png" alt="" /> */}
                          {/* <img src={cardanoBlue} /> */}
                          {thisCollection.collection.floor_price}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <h6 className="col_name">Volume</h6>
                        <p className="collection_number_head">
                          {/* <img src="images/collection/round.png" alt="" /> */}
                          {/* <img src={cardanoBlue} /> */}
                          {parseFloat(thisCollection.collection.volume).toFixed(2)}
                          {/* {numFormatter(
                            (thisCollection.opencnft.reduce(function (result, policy) {
                              return result + policy.total_volume
                            }, 0) / 1000000)
                          )} */}
                        </p>
                      </div>
                    </div>
                  ) : <></>}
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-3">
                <div className="art_universe">
                  <FadeImg
                    src={assetImgSrc ? assetImgSrc : undefined} className="art_img"
                  />
                  {thisCollection.collection.name ? (
                    <p className="art_heading">{thisCollection.collection.name}<img src={verify} alt=""
                      width="18" /></p>
                  ) : <></>
                  }

                  {thisCollection.collection ? (
                    <p>Policy Id: {thisCollection.collection.policies[0]}</p>
                  ) : <></>
                  }
                </div>
              </div>
              <div className="col-md-9">
                {/* <div className="btn-group collection_social_btn float-end" role="group"
                    aria-label="Basic outlined example">
                    <a className="btn btn-outline-primary"><img src={website} /></a>
                    <a className="btn btn-outline-primary"><img src={twitter} /></a>
                    <a className="btn btn-outline-primary"><img src={discord} /></a>
                  </div>*/}

            {/* <div className="collection_desc">
                  <p>{thisCollection.collection.name}</p>
                </div>
                {thisCollection.opencnft ? (
                  <div className="collection_count">
                    <div className="row">

                      <div className="col-md-3 col-6">
                        <div className="collection_numer">
                          <p className="collection_number_head">
                            {
                              numFormatter(
                                thisCollection.opencnft.reduce(function (result, policy) {
                                  return result + policy.asset_minted
                                }, 0))
                            }
                          </p>
                          <p className="col_name">Items</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-6">
                        <div className="collection_numer">
                          <p className="collection_number_head">
                            {numFormatter(
                              thisCollection.opencnft.reduce(function (result, policy) {
                                return result + policy.asset_holders
                              }, 0))
                            }
                          </p>
                          <p className="col_name">owners</p>
                        </div>
                      </div>

                      <div className="col-md-3 col-6">
                        <div className="collection_numer">
                          <p className="collection_number_head"><img src="images/collection/round.png"
                            alt="" />
                            <img src={cardanoBlue} />{

                              thisCollection.opencnft.reduce(function (result, policy) {
                                return Math.min(result, policy.floor_price)
                              }, 999999 * 1000000) / 1000000}
                          </p>
                          <p className="col_name">floor</p>
                        </div>
                      </div>

                      <div className="col-md-3 col-6">
                        <div className="collection_numer">
                          <p className="collection_number_head"><img src="images/collection/round.png"
                            alt="" />
                            <img src={cardanoBlue} />{numFormatter(
                              (thisCollection.opencnft.reduce(function (result, policy) {
                                return result + policy.total_volume
                              }, 0) / 1000000)
                            )}
                          </p>
                          <p className="col_name">Volume</p>
                        </div>
                      </div>

                    </div>
                  </div>
                ) : <></>}
              </div>
            </div> */}
            {/* </div> */}
          </div>
        </div >
      ) : <></>}
    </>
  );
};

export default CollectionBanner;
