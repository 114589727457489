export const COLLECTIONS_LOADED = 'COLLECTIONS_LOADED';
export const COLLECTIONS_TOP_LOADED = 'COLLECTIONS_TOP_LOADED'
export const COLLECTIONS_LISTING_LOADED = 'COLLECTIONS_LISTING_LOADED';
export const COLLECTIONS_ADD_TOKENS = 'COLLECTIONS_ADD_TOKENS';
export const COLLECTIONS_ADD_TOKEN = 'COLLECTIONS_ADD_TOKEN';
export const COLLECTIONS_LOADING = 'COLLECTIONS_LOADING';
export const COLLECTIONS_TOP_PROJECTS = 'COLLECTIONS_TOP_PROJECTS';
export const COLLECTIONS_ADD_ASSETS = 'COLLECTIONS_ADD_ASSETS';

export const COLLECTION_SELECTED = 'COLLECTION_SELECTED';
export const ASSET_SELECTED = 'ASSET_SELECTED';

export const EXPLORE_SECTION_COLLECTION_LIST = 'EXPLORE_SECTION_COLLECTION_LIST'
export const COLLECTION_TRANSACTION_SUCCESS = 'COLLECTION_TRANSACTION_SUCCESS'
