import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import DataTable from "react-data-table-component";

import "./style.scss";
import userImage from "../../assets/images/create-4/user.png";
import { sunstepApi } from '../../config';
import axios from "axios"

import Footer from "../../components/Footer/index"
import {
  file_upload,
  authenticate_pinata,
  createCollection,
  get_collection,
  user_nft_build_txn_file_generation,
  user_nft_sign_txn_file_generation,
  user_nft_submit_txn_file_generation,
  get_current_slot,
  create_policy,
  create_platform,
  royalties_metadata_generation,
  user_nft_metadata_generation,
  build_txn_for_royalties,
  sign_txn_for_royalties,
  submit_txn_for_royalties,
  get_current_balance_of_platform_address,
  get_collection_details,
} from "../../store/mint/api";

import { file_upload_to_aws } from "../../store/account/api";
import { user_signin, add_platform_sucess } from "../../store/mint/mintActions";

import $ from "jquery";

import { buildSendADATransaction } from "../../utils/blockchain";

// import "react-loading-skeleton/dist/skeleton.css";

const Mint = () => {
  const state_wallet = useSelector((state) => state.wallet);

  const configurenftdata = {
    tokenname: "",
    assetname: "",
    amount: 0,
    name: "",
    image: "",
    mediatype: "",
    description: "",
    copyright: "",
    website: "",
    url: "",
  };
  const status = {
    status: false,
  };
  const dispatch = useDispatch();
  const [createcollection, setcreatecollection] = useState(false);
  const [nftstatus, setnftstatue] = useState(false);
  const [selectedcollectionid, setselectedcollectionid] = useState("");
  const [
    errorforcollectionselection,
    seterrorforcollectionselection,
  ] = useState(false);
  const [Platform, setPlatform] = useState("");
  const [Platformaddress, setPlatformaddress] = useState("");
  const [stepforcreatecollection, setstepforcreatecollection] = useState(true);
  const [stepforadddetails, setstepforadddetails] = useState(false);
  const [stepforfundaccount, setstepforfundaccount] = useState(false);
  const [stepforsubmit, setstepforsubmit] = useState(false);
  const [stepforsuccess, setstepforsuccess] = useState(false);
  const [errorforcollectionname, seterrorforcollectionname] = useState(false);
  const [
    errorforcollectiondescription,
    seterrorforcollectiondescription,
  ] = useState(false);
  const [
    errorforcollectionroyaltyaddress,
    seterrorforcollectionroyaltyaddress,
  ] = useState(false);
  const [
    errorforcollectionroyaltypercentage,
    seterrorforcollectionroyaltypercentage,
  ] = useState(false);
  const [errorforcollectiontwitter, seterrorforcollectiontwitter] = useState(
    false
  );
  const [errorforcollectionwebsite, seterrorforcollectionwebsite] = useState(
    false
  );
  const [errorforcollectiondiscord, seterrorforcollectiondiscord] = useState(
    false
  );
  const [errorforcollectiontelegram, seterrorforcollectiontelegram] = useState(
    false
  );
  const [errorforcollectionfacebook, seterrorforcollectionfacebook] = useState(
    false
  );
  const [
    errorforcollectioninstagram,
    seterrorforcollectioninstagram,
  ] = useState(false);
  const [errorforcollectionimage, seterrorforcollectionimage] = useState(false);
  const [
    errorforcollectionbannerimage,
    seterrorforcollectionbannerimage,
  ] = useState(false);
  const [selected, setSelected] = useState(null);
  // const [ticking, setTicking] = useState(true)
  // const [count, setCount] = useState(0)
  const [nftdata, setnftdata] = useState([]);
  const [errornftdataassetname, seterrornftdataassetname] = useState([]);
  const [
    errornftdataassetdescription,
    seterrornftdataassetdescription,
  ] = useState([]);
  const [errornftdataassetimage, seterrornftdataassetimage] = useState([]);
  const [errornftdataassetamount, seterrornftdataassetamount] = useState([]);
  const [errornftdataname, seterrornftdataname] = useState([]);
  const [errorfortokenname, seterrorfortokenname] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);

  // const opencnft_get_top_projects = (time, callback) => {
  //   dispatch(opencnftGetTopProjects(time, callback));
  // };
  const collectioncreate = useSelector((state) => state.mint);

  useEffect(() => {
    if (nftdata.length == 0) {
      setnftdata([...nftdata, configurenftdata]);
      seterrornftdataassetname([...errornftdataassetname, status]);
      seterrornftdataassetdescription([
        ...errornftdataassetdescription,
        status,
      ]);
      seterrornftdataassetimage([...errornftdataassetimage, status]);
      seterrornftdataassetamount([...errornftdataassetamount, status]);
      seterrornftdataname([...errornftdataname, status]);
    }
    if (state_wallet.connected) {
      collectioncreate.creatoraddress = state_wallet.data.address;

      authenticate_pinata();
      dispatch(get_current_slot());
      collectioncreate.creatoraddress = state_wallet.data.address;
      const payload = {
        walletaddress: collectioncreate.creatoraddress,
      };
      dispatch(get_collection(payload, (res) => { }));
    }
    $("body").addClass("loaded");
  }, [state_wallet]);

  const handleselectforcollection = (e) => {
    seterrorforcollectionselection(false);
    console.log(e.target.value);
    setselectedcollectionid(e.target.value);
  };
  const selectcollectionprocess = () => {
    if (selectedcollectionid != "") {
      const payload = {
        collectionid: selectedcollectionid,
      };
      get_collection_details(payload, (result) => {
        console.log(result.data);
        collectioncreate.collectionname = result.data.collectionname;
        collectioncreate.collectiondescription =
          result.data.collectiondescription;
        collectioncreate.collectionslotnumber =
          result.data.collectionslotnumber;
        collectioncreate.slotnumber = result.data.collectionslotnumber;
        collectioncreate.policyid = result.data.policyid;
        collectioncreate.policytype = collectioncreate.collectionname;
        setstepforcreatecollection(false);
        setstepforadddetails(true);
        console.log(collectioncreate);
      });
    } else {
      seterrorforcollectionselection(true);
    }
  };
  const createcollectionprocess = () => {
    setcreatecollection(true);
  };
  const backtocollectionselectionprocess = () => {
    setcreatecollection(false);
  };
  const closeNotification = () => {
    setShowNotification(false);
  };
  const onCollectionImageUpload = async (e) => {
    if (e) {
      setShowNotificationMessage(
        "Please wait your collection photo is uploading to server"
      );
      setShowNotification(true);
      const file = e.target.files[0];
      console.log(file);

      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      await file_upload_to_aws(formData, (res) => {
        collectioncreate.collectionimage = `${res.data}`;
        seterrorforcollectionimage(false);
        setShowNotificationMessage(
          "Your collection photo has been uploaded to server!"
        );
        setShowNotification(true);
      });
      console.log(collectioncreate);
    }
  };
  const onCollectionBannerImageUpload = async (e) => {
    if (e) {
      setShowNotificationMessage(
        "Please wait your collection banner photo is uploading to server"
      );
      setShowNotification(true);
      const file = e.target.files[0];
      console.log(file);

      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      await file_upload_to_aws(formData, (res) => {
        collectioncreate.collectionbanner = `${res.data}`;
        seterrorforcollectionbannerimage(false);
        setShowNotificationMessage(
          "Your collection banner photo has been uploaded to server!"
        );
        setShowNotification(true);
      });
    }
  };
  const handleChangeCollectionName = (e) => {
    collectioncreate.collectionname = e.target.value.trim();
    if (collectioncreate.collectionname == "") {
      seterrorforcollectionname(true);
    } else {
      seterrorforcollectionname(false);
    }
  };
  const handleChangeCollectionDescription = (e) => {
    collectioncreate.collectiondescription = e.target.value.trim();
    if (collectioncreate.collectiondescription == "") {
      seterrorforcollectiondescription(true);
    } else {
      seterrorforcollectiondescription(false);
    }
  };
  const handleChangeRoyaltyPercentage = (e) => {
    collectioncreate.royaltypercentage = e.target.value.trim() / 100;
    collectioncreate.royaltypercentage = collectioncreate.royaltypercentage.toString();
    if (collectioncreate.royaltypercentage == "") {
      seterrorforcollectionroyaltypercentage(true);
    } else {
      seterrorforcollectionroyaltypercentage(false);
    }
  };
  const handleChangeTwitter = (e) => {
    collectioncreate.twitter = e.target.value.trim();
    if (collectioncreate.twitter == "") {
      seterrorforcollectiontwitter(true);
    } else {
      seterrorforcollectiontwitter(false);
    }
  };
  const handleChangeFacebook = (e) => {
    collectioncreate.facebook = e.target.value.trim();
    if (collectioncreate.facebook == "") {
      seterrorforcollectionfacebook(true);
    } else {
      seterrorforcollectionfacebook(false);
    }
  };
  const handleChangeInstagram = (e) => {
    collectioncreate.instagram = e.target.value.trim();

    if (collectioncreate.instagram == "") {
      seterrorforcollectioninstagram(true);
    } else {
      seterrorforcollectioninstagram(false);
    }
  };
  const handleChangeDiscord = (e) => {
    collectioncreate.discord = e.target.value.trim();
    if (collectioncreate.discord == "") {
      seterrorforcollectiondiscord(true);
    } else {
      seterrorforcollectiondiscord(false);
    }
  };
  const handleChangeTelegram = (e) => {
    collectioncreate.telegram = e.target.value.trim();
    if (collectioncreate.telegram == "") {
      seterrorforcollectiontelegram(true);
    } else {
      seterrorforcollectiontelegram(false);
    }
  };
  const handleChangeWebsite = (e) => {
    collectioncreate.website = e.target.value.trim();
    if (collectioncreate.website == "") {
      seterrorforcollectionwebsite(true);
    } else {
      seterrorforcollectionwebsite(false);
    }
  };
  const createaCollectionandprocessNext = async () => {
    
    collectioncreate.royaltyaddress = state_wallet.data.address;
    if (
      collectioncreate.collectionname == undefined ||
      collectioncreate.collectionname == ""
    ) {
      setShowNotificationMessage("Collection name required");
      setShowNotification(true);
    } else if (
      collectioncreate.collectiondescription == undefined ||
      collectioncreate.collectiondescription == ""
    ) {
      setShowNotificationMessage("Collection description required");
      setShowNotification(true);
    } else if (
      collectioncreate.royaltyaddress == undefined ||
      collectioncreate.royaltyaddress == ""
    ) {
      seterrorforcollectionroyaltyaddress(true);
    } else if (
      collectioncreate.royaltypercentage == undefined ||
      collectioncreate.royaltypercentage == ""
    ) {
      setShowNotificationMessage("Please submit royalty percentage from 10-99");
      setShowNotification(true);
    } else if (
      collectioncreate.collectionimage == undefined ||
      collectioncreate.collectionimage == ""
    ) {
      setShowNotificationMessage("Collection image required");
      setShowNotification(true);
    } else if (
      collectioncreate.collectionbanner == undefined ||
      collectioncreate.collectionbanner == ""
    ) {
      setShowNotificationMessage("Collection banner image required");
      setShowNotification(true);
    }
    else {
      const payload = {
        platform: collectioncreate.collectionname,
      };
      dispatch(create_platform(payload, async (result) => {
        console.log(result.data)
        setPlatform(result.data)
        dispatch(add_platform_sucess(Platform))
        collectioncreate.platform = collectioncreate.collectionname
        collectioncreate.collectionslotnumber =
          collectioncreate.slotnumber + 10000000;
        collectioncreate.slotnumber = collectioncreate.slotnumber + 10000000;
        collectioncreate.policytype = collectioncreate.collectionname;

        dispatch(
          create_policy(collectioncreate, (result) => {
            alert("Policy creation success");
            collectioncreate.policyid = result.data;
            dispatch(
              createCollection(collectioncreate, (res) => {
                console.log(res.data);
                collectioncreate.data = {
                  777: {
                    rate: collectioncreate.royaltypercentage,
                    addr: collectioncreate.creatoraddress,
                  },
                };
                setstepforcreatecollection(false);
                setstepforadddetails(true);
              })
            );
          })
        );
      }
      ))
    }
  };


  const addcreatenftdatabox = () => {
    setnftdata([...nftdata, configurenftdata]);
    seterrornftdataassetamount([...errornftdataassetname, status]);
    seterrornftdataassetdescription([...errornftdataassetdescription, status]);
    seterrornftdataassetimage([...errornftdataassetimage, status]);
    seterrornftdataassetname([...errornftdataassetname, status]);
  };
  const multi = () => {
    const nftdatanew = nftdata[nftdata.length - 1];
    setnftdata([...nftdata, nftdatanew]);
    const errorassetnamenew =
      errornftdataassetname[errornftdataassetname.length - 1];
    seterrornftdataassetname([...errornftdataassetname, errorassetnamenew]);
    const errorassetdescriptionnew =
      errornftdataassetdescription[errornftdataassetdescription.length - 1];
    seterrornftdataassetdescription([
      ...errornftdataassetdescription,
      errorassetdescriptionnew,
    ]);
    const errorassetamountnew =
      errornftdataassetamount[errornftdataassetamount.length - 1];
    seterrornftdataassetamount([
      ...errornftdataassetamount,
      errorassetamountnew,
    ]);
    const errorassetimagenew =
      errornftdataassetimage[errornftdataassetimage.length - 1];
    seterrornftdataassetimage([...errornftdataassetimage, errorassetimagenew]);
  };
  const removecreatenftdatabox = (index) => {
    setnftdata((nftdata) => nftdata.filter((_, index) => index !== 0));
    seterrornftdataassetname((errornftdataassetname) =>
      errornftdataassetname.filter((_, index) => index !== 0)
    );
    seterrornftdataassetdescription((errornftdataassetdescription) =>
      errornftdataassetdescription.filter((_, index) => index !== 0)
    );
    seterrornftdataassetamount((errornftdataassetamount) =>
      errornftdataassetamount.filter((_, index) => index !== 0)
    );
    seterrornftdataassetimage((errornftdataassetimage) =>
      errornftdataassetimage.filter((_, index) => index !== 0)
    );
  };
  const handleChangefornftvalue = (count) => (e) => {
    const newState = nftdata.map((i, index) => {
      if (index === count) {
        return { ...i, [e.target.name]: e.target.value.trim() };
      }
      return i;
    });
    setnftdata(newState);
  };
  const nftfileupload = (count) => (e) => {
    setShowNotificationMessage(
      "Please wait your asset photo is uploading to server!"
    );
    setShowNotification(true);
    const file = e.target.files[0];
    console.log(file);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    file_upload(formData, (res) => {
      const newState = nftdata.map((i, index) => {
        if (index === count) {
          return { ...i, image: `${res.data.IpfsHash}`, mediatype: file.type };
        }
        return i;
      });
      setnftdata(newState);
      setShowNotificationMessage(
        "Your asset photo has been uploaded to server!"
      );
      setShowNotification(true);
    });
    console.log(collectioncreate);
  };
  const onsubmit = () => {
    
    console.log(nftdata);
    for (let i = 0; i < nftdata.length; i++) {
      if (!nftdata[i].tokenname) {
        setShowNotificationMessage(
          "Please submit tokenname for NFT mint process"
        );
        setShowNotification(true);
        setnftstatue(true);
      } else if (!nftdata[i].assetname) {
        setShowNotificationMessage(
          "Please submit assetname for NFT mint process"
        );
        setShowNotification(true);
        setnftstatue(true);
      } else if (!nftdata[i].image) {
        setShowNotificationMessage("Please submit image for NFT mint process");
        setShowNotification(true);
        // const newState2 = errornftdataassetimage.map((j, index) => {
        //   if (index === i) {
        //     return { ...j, status: true };
        //   }
        //   return j;
        // });
        // seterrornftdataassetimage(newState2);
        setnftstatue(true);
      } else if (!nftdata[i].amount) {
        // const newState4 = errornftdataassetamount.map((j, index) => {
        //   if (index === i) {
        //     return { ...j, status: true };
        //   }
        //   return j;
        // });
        // seterrornftdataassetamount(newState4);
        setShowNotificationMessage("Please submit amount for NFT mint process");
        setShowNotification(true);
        setnftstatue(true);
      } else if (!nftdata[i].name) {
        // const newState4 = errornftdataassetamount.map((j, index) => {
        //   if (index === i) {
        //     return { ...j, status: true };
        //   }
        //   return j;
        // });
        // seterrornftdataassetamount(newState4);
        setShowNotificationMessage("Please submit name for NFT mint process");
        setShowNotification(true);
        setnftstatue(true);
      } else if (!nftdata[i].description) {
        setShowNotificationMessage(
          "Please submit description for NFT mint process"
        );
        setShowNotification(true);
        // const newState3 = errornftdataassetdescription.map((j, index) => {
        //   if (index === i) {
        //     return { ...j, status: true };
        //   }
        //   return j;
        // });
        // seterrornftdataassetdescription(newState3);
        // setnftstatue(true);
        // setShowNotification(true);
        setnftstatue(true);
      } else {
        secondstep();
      }
    }
  };
  const secondstep = () => {
    setShowNotificationMessage(
      "Your asset metadata generation is in process!"
    );
    setShowNotification(true);
    if (nftstatus === false) {
      // setShowNotificationMessage(
      //   "Please submit all the details for NFT mint process"
      // );
      // setShowNotification(true);
      setnftstatue(false);
    } else {

      const policyid = collectioncreate.policyid;
      let jsonassetdata = "";
      for (let i = 0; i < nftdata.length; i++) {
        const assetname = nftdata[i].assetname;
        const name = nftdata[i].name;
        const image = nftdata[i].image;
        const description = nftdata[i].description;
        const type = nftdata[i].mediatype;

        let value = `"${assetname}":{"description":"${description}","name":"${name}","id":"${i}","src":"ipfs://${image}","image":"ipfs://${image}","type":"${type}"}`;
        if (jsonassetdata == "") {
          jsonassetdata = value;
        } else {
          jsonassetdata = jsonassetdata + "," + value;
        }
      }
      const finaldata = `{
      "721": {
        "${policyid}": {
          ${jsonassetdata}
        }}}`;
      console.log(finaldata);
      const newfinaldata = JSON.parse(finaldata);
      console.log(newfinaldata);
      collectioncreate.filename = "testing1";
      const payload = {
        useraddress: collectioncreate.creatoraddress,
        platform: collectioncreate.platform,
        data: newfinaldata,
        filename: collectioncreate.filename,
      };
      dispatch(
        user_nft_metadata_generation(payload, (filegenerationresult) => {
          setShowNotificationMessage(
            "Your metadata has been generated successfully!"
          );
          setShowNotification(true);
          console.log(filegenerationresult.data);
          setstepforcreatecollection(false);
          setstepforadddetails(false);
          setstepforfundaccount(true);
          const payload = {
            platform: collectioncreate.collectionname
          }
          axios.post(`${sunstepApi}/cardano/getplatformpaymentaddress`, payload)
            .then(res => {
              const value = res.data
              setPlatformaddress(value);
            })
            .catch(err => {
              alert(err)
            })
        })
      );
    }
  };

  const backtoaddnftdata = () => {
    setstepforcreatecollection(false);
    setstepforadddetails(true);
    setstepforfundaccount(false);
  };
  const buildtxnforasset = () => {
    
    buildSendADATransaction();
    setShowNotificationMessage("Transaction build is under progress");
    setShowNotification(true);
    collectioncreate.filename = "testing1";
    const payloadforbalance = {
      walletaddress: collectioncreate.Platformaddress,
    };
    dispatch(
      get_current_balance_of_platform_address(payloadforbalance, (balance) => {
        console.log(balance);
        const payloadforbuildtxn = {
          platform: collectioncreate.platform,
          policytype: collectioncreate.policytype,
          platformaddress: collectioncreate.Platformaddress,
          useraddress: collectioncreate.creatoraddress,
          txhash: balance.data.TxHash,
          txix: balance.data.TxIx,
          slotnumber: collectioncreate.slotnumber,
          filename: collectioncreate.filename,
          platformaddress: collectioncreate.platformaddress,
          nftfiles: nftdata,
          policyid: collectioncreate.policyid,
        };

        dispatch(
          user_nft_build_txn_file_generation(payloadforbuildtxn, (result) => {
            alert(result.data);
            if (result.data == null) {
              setShowNotificationMessage("Transaction build successfully done");
              setShowNotification(true);
              dispatch(
                user_nft_sign_txn_file_generation(
                  payloadforbuildtxn,
                  (result) => {
                    setShowNotificationMessage(
                      "Transaction signed successfully"
                    );
                    alert(result.data);
                    dispatch(
                      user_nft_submit_txn_file_generation(
                        payloadforbuildtxn,
                        (result) => {
                          setShowNotificationMessage(
                            "Transaction submitted successfully"
                          );
                          stepforsubmit(false);
                          stepforsuccess(true);
                          // alert(result.data)
                        }
                      )
                    );
                  }
                )
              );
            } else {
              setShowNotificationMessage("Transaction build failed");
              setShowNotification(true);
            }
          })
        );
      })
    );
  };

  const [createcollectionmodal, setcreatecollectionmodal] = useState("")

  function createCollectionModal() {
    setcreatecollectionmodal("createcollection")
  }
  function importCollectionModal() {
    setcreatecollectionmodal("importcollection")
  }
  function setdefultcollectionmodal() {
    setcreatecollectionmodal("")
  }

  return (
    <>
      {!state_wallet.connected ? <NotConnected /> : <></>}
      {state_wallet.connected ? (
        <>
          {showNotification ? (
            <button
              type="button"
              className="btn btn-primary nortification_footer"
            >
              <div className="row">
                <div className="col-md-10">{showNotificationMessage}</div>
                <div className="col-md-2">
                  <span className="badge badge-light">
                    <i
                      className="fa-solid fa-xmark hide_x"
                      onClick={closeNotification}
                    ></i>
                  </span>
                </div>
              </div>
            </button>
          ) : (
            <></>
          )}
          <div className="create_master_area">
            <div className="home-header explore_area">
              <div className="explore_header_section mint_header_setion">
                <div className="container">
                  <div className="explore_heading mint_heading">
                    <h1>Mint</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              {createcollectionmodal == "" && <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <div className="mint-main-box mint-main-collection">
                    <h3>Start your NFT journey by minting<br />
                      your own unique digital collectibles on our platform.</h3>
                    <p>Use our platform to mint the asset, which creates a unique<br /> token on the blockchain representing the asset.</p>
                    <div className="nft-wallet-btn-wrapp collection_wrapp">
                      <Link href="#" onClick={createCollectionModal} className="btn_exlore">Create Collection</Link>
                      <Link href="#" onClick={importCollectionModal} className="btn_exlore more_explore">Import Collection</Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>}
              {createcollectionmodal == "createcollection" && <div className="createcollection_section">
                <div className="mint-main-box mint-main-collection main_mint_collection">
                  <div className="mint_box_header">
                    <div className="mint_box_back">
                      <h4><img src="/images/back_img.svg" onClick={setdefultcollectionmodal} /> Create collection</h4>
                    </div>
                    <div className="main_mint_box">
                      <h6>Fill in the below details to create your collection.</h6>

                      <div className="details_section">
                        <div className="row">
                          <div className="col-md-4">
                            <h4>Logo image<span>*</span></h4>
                            <p>This image will also be used for
                              <br />
                              navigation.</p>
                            <div className="image_upload_section">
                              <h5>Drag and drop an image</h5>
                              <p>JPG or PNG , 350x350 px</p>
                              <div className="btn_exlore uploadimginputbtn-wrapp">
                                Choose file
                                <input
                                  className="uploadCoverInput uploadinputbtn"
                                  type="file"
                                  name="profilebannerimage"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                          </div>
                          <div className="col-md-8">
                            <h4>Banner image<span>*</span></h4>
                            <p>This image will also be used for navigation.</p>
                            <div className="image_upload_section">
                              <h5>Drag and drop an image</h5>
                              <p>JPG or PNG , 1860x465px</p>
                              <div className="btn_exlore uploadimginputbtn-wrapp">
                                Choose file
                                <input
                                  className="uploadCoverInput uploadinputbtn"
                                  type="file"
                                  name="bannerimage"
                                  accept="image/*"
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="row from_text_secction">
                          <div className="col-md-5 all_info_box">
                            <label>Collection name<span>*</span></label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>website link</label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>Description</label>
                            <textarea
                              rows="6"
                              cols="4"
                              name="Description"
                              className="form-control form-control-box"
                            ></textarea>
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>Twitter link</label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>Type your desired Royalty fee rate below (Max 3%)Collection name</label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>Telegram link</label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>Wallet address to receive the royalty fee</label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>Discord server link</label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>which blockchain is your project on?</label>
                            {/* <input type="text" name="lastname" class="form-control form-control-box" /> */}
                            <select className="form_select_data">
                              <option value="0">BNB Chain</option>
                              <option value="1">BNB Chain</option>
                            </select>
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>Instagram link</label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-5 all_info_box"></div>
                          <div className="col-md-5 all_info_box">
                            <label>Facebook link</label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-12 all_info_box submit_mint_process_row">
                            <button className="btn Submit_mint_process">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              {createcollectionmodal == "importcollection" && <div className="createcollection_section">
                <div className="mint-main-box mint-main-collection main_mint_collection">
                  <div className="mint_box_header">
                    <div className="mint_box_back">
                      <h4><img src="/images/back_img.svg" onClick={setdefultcollectionmodal} /> Import collection</h4>
                    </div>
                    <div className="main_mint_box">
                      <h6>Fill in the below details to create your collection.</h6>
                      <div className="details_section">
                        <div className="row from_text_secction from_text_secction_import">
                          <div className="col-md-5 all_info_box">
                            <label>Network</label>
                            {/* <input type="text" name="lastname" class="form-control form-control-box" /> */}
                            <select className="form_select_data">
                              <option value="0">BNB Chain</option>
                              <option value="1">BNB Chain</option>
                            </select>
                          </div>
                          <div className="col-md-5 all_info_box">
                            <label>Collection Address</label>
                            <input type="text" name="lastname" className="form-control form-control-box" />
                          </div>
                          <div className="col-md-12 all_info_box submit_mint_process_row">
                            <button className="btn Submit_mint_process">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>}
            </div>
            {/* 
            <div className="container">
              <div className="create_your border_bottom text-white">
                <p>Create your Master piece</p>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-md-12">
                  {stepforcreatecollection == true && (
                    <form className="msform">
                      <ul id="progressbar">
                        <li className="active" id="account">
                          <span>Create or Select Collection</span>
                        </li>
                      </ul>
                      {createcollection == false && (
                        <fieldset>
                          <div className="customer_records">
                            <div className="form-card">
                              <div className="row">
                                <div className="col-md-4">
                                  {/* <div className="row"> */}
            {/* <select
                                    className="form-control drp-selection"
                                    onChange={(e) =>
                                      handleselectforcollection(e)
                                    }
                                  >
                                    <option className="dropdown" value="">
                                      -Select Collection-
                                    </option>
                                    {collectioncreate.collections.map(
                                      (data, index) => (
                                        <option
                                          className="dropdown"
                                          key={index}
                                          value={data._id}
                                        >
                                          {data.collectionname}
                                        </option>
                                      )
                                    )}
                                  </select> */}

            {/* </div> */}
            {/* </div> */}
            {/* <div className="col-md-4">
                                  <input
                                    type="button"
                                    name="next"
                                    className="next action-button-forselection"
                                    onClick={selectcollectionprocess}
                                    value="Next"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="button"
                                    name="next"
                                    className="next action-button-forselection"
                                    onClick={createcollectionprocess}
                                    value="Create New"
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}
            {/* {errorforcollectionselection == true && (
                            <span className="alert">
                              Please select a collection or create a new one to
                              proceed
                            </span>
                          )}
                        </fieldset>
                      )}
                      {createcollection == true && (
                        <fieldset>
                          <div className="customer_records">
                            <div className="form-card">
                              <div className="configure_area">
                                <div className="row">
                                  {/* <div className="col-md-6"> */}
            {/* <div className="col-md-6">
                                    <div
                                      className="edit_profille_area_form margin-bottom-30"
                                      style={{ height: "45%" }}
                                    >
                                      <label>Upload Collection Image*</label>
                                      <div
                                        className="pic-holder"
                                        style={{ height: "85%" }}
                                      >
                                        {collectioncreate.collectionimage !=
                                          undefined &&
                                          collectioncreate.collectionimage !=
                                          "" && (
                                            <img
                                              id="profilePic"
                                              src={`${collectioncreate.collectionimage}`}
                                              alt=""
                                            />
                                          )}
                                        <input
                                          className="uploadProfileInput"
                                          type="file"
                                          name="profileimage"
                                          id="newProfilePhoto"
                                          accept="image/*"
                                          onChange={onCollectionImageUpload}
                                        />
                                        <label
                                          htmlFor="newProfilePhoto"
                                          className="upload-file-block"
                                        >
                                          <div className="text-center">
                                            <div className="mb-2">
                                              <i className="fa fa-camera fa-2x"></i>
                                            </div>
                                            <div className="text-uppercase">
                                              Upload <br /> Collection Image
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      className="edit_profille_area_form"
                                      style={{ height: "45%" }}
                                    >
                                      <label>
                                        Upload Collection Banner Image*
                                      </label>
                                      <div
                                        className="pic-holder"
                                        style={{ height: "85%" }}
                                      >
                                        {collectioncreate.collectionbanner !=
                                          undefined &&
                                          collectioncreate.collectionbanner !=
                                          "" && (
                                            <img
                                              id="profilePic"
                                              src={
                                                collectioncreate.collectionbanner
                                              }
                                              alt=""
                                            />
                                          )}
                                        <input
                                          className="uploadCoverInput"
                                          type="file"
                                          name="profilebannerimage"
                                          id="newProfileBannerPhoto"
                                          accept="image/*"
                                          onChange={
                                            onCollectionBannerImageUpload
                                          }
                                        />
                                        <label
                                          htmlFor="newProfileBannerPhoto"
                                          className="upload-file-block"
                                        >
                                          <div className="text-center">
                                            <div className="mb-2">
                                              <i className="fa fa-camera fa-2x"></i>
                                            </div>
                                            <div className="text-uppercase">
                                              Upload <br /> Collection Banner
                                              Image
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="row">
                                      <div className="col-md-12 input_icon_area">
                                        <label>Collection Name*</label>
                                        {errorforcollectionname == true && (
                                          <span className="alert">
                                            Required collection name
                                          </span>
                                        )}
                                        <input
                                          type="text"
                                          name=""
                                          className="form-control"
                                          onChange={handleChangeCollectionName}
                                          placeholder="E.g. GlobeArt"
                                        />{" "}
                                        <i className="fa-solid fa-file"></i>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label>Description</label>
                                        {errorforcollectiondescription ==
                                          true && (
                                            <span className="alert">
                                              Required collection description
                                            </span>
                                          )}
                                        <textarea
                                          rows="6"
                                          cols="20"
                                          className="form-control"
                                          placeholder="E.g. This is a full of Global Arts"
                                          onChange={
                                            handleChangeCollectionDescription
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Royalty Address</label>
                                        {errorforcollectionroyaltyaddress ==
                                          true && (
                                            <span className="alert">
                                              Royalty address
                                            </span>
                                          )}
                                        <input
                                          type="text"
                                          name=""
                                          className="form-control"
                                          defaultValue={
                                            state_wallet.data.address
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Percentage</label>
                                        {errorforcollectionroyaltypercentage ==
                                          true && (
                                            <span className="alert">
                                              Royalty percentage
                                            </span>
                                          )}
                                        <input
                                          type="text"
                                          min="0"
                                          max="1"
                                          name=""
                                          className="form-control"
                                          placeholder="E.g .2 for 20%"
                                          onChange={
                                            handleChangeRoyaltyPercentage
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <label>Social Links</label>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label>Facebook</label>
                                        {errorforcollectionfacebook == true && (
                                          <span className="alert">
                                            Submit facebook page link
                                          </span>
                                        )}
                                        <input
                                          type="text"
                                          name=""
                                          className="form-control"
                                          placeholder="Facebook handler"
                                          onChange={handleChangeFacebook}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label>Website</label>
                                        {errorforcollectionwebsite == true && (
                                          <span className="alert">
                                            Required any website link
                                          </span>
                                        )}
                                        <input
                                          type="text"
                                          name=""
                                          className="form-control"
                                          placeholder="Website url"
                                          onChange={handleChangeWebsite}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label>Instagram</label>
                                        {errorforcollectioninstagram ==
                                          true && (
                                            <span className="alert">
                                              Required any instagram handel
                                            </span>
                                          )}
                                        <input
                                          type="text"
                                          name=""
                                          className="form-control"
                                          placeholder="Instagram handler"
                                          onChange={handleChangeInstagram}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label>Twitter</label>
                                        {errorforcollectiontwitter == true && (
                                          <span className="alert">
                                            Required twitter handel
                                          </span>
                                        )}
                                        <input
                                          type="url"
                                          name=""
                                          className="form-control"
                                          placeholder="Twitter handler"
                                          onChange={handleChangeTwitter}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label>Discord</label>
                                        {errorforcollectiondiscord == true && (
                                          <span className="alert">
                                            Submit discord url
                                          </span>
                                        )}
                                        <input
                                          type="url"
                                          name=""
                                          className="form-control"
                                          placeholder="Discord handler"
                                          onChange={handleChangeDiscord}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label>Telegram</label>
                                        {errorforcollectiontelegram == true && (
                                          <span className="alert">
                                            Submit telegram handeler
                                          </span>
                                        )}
                                        <input
                                          type="url"
                                          name=""
                                          className="form-control"
                                          placeholder="Telegram handler"
                                          onChange={handleChangeTelegram}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <input
                            type="button"
                            name="next"
                            className="next action-button"
                            onClick={createaCollectionandprocessNext}
                            value="Create"
                          />
                          <ul className="button_area form_footer_button">
                            <li
                              className="email-send-btn extra-fields-customer"
                              onClick={backtocollectionselectionprocess}
                            >
                              Back
                            </li>
                          </ul>
                        </fieldset>
                      )}
                    </form>
                  )} */}
            {/* {stepforadddetails == true && (
                    <form className="msform">
                      <ul id="progressbar">
                        <li className="active border_left" id="account">
                          <span>Configure NFTs</span>
                        </li>
                        <li id="personal" className="border_left">
                          <span>Fund Account</span>
                        </li>
                        <li id="confirm">
                          <span>Review & Required</span>
                        </li>
                      </ul>
                      <fieldset>
                        {nftdata.map((data, index) => (
                          <>
                            <div className="customer_records" key={index}>
                              <div className="form-card">
                                {index != 0 && (
                                  <a
                                    className="remove-field btn-remove-customer"
                                    onClick={() =>
                                      removecreatenftdatabox(index)
                                    }
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </a>
                                )}
                                <div className="configure_area">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div
                                        className="edit_profille_area_form"
                                        style={{ height: "90%" }}
                                      >
                                        {errornftdataassetimage[index].status ==
                                          true && (
                                            <span className="alert">
                                              Please upload your asset image
                                            </span>
                                          )}
                                        <label>Upload Asset Image*</label>
                                        <div
                                          className="pic-holder"
                                          style={{ height: "85%" }}
                                        >
                                          {data.image != "" && (
                                            <img
                                              id="profilePic"
                                              src={`https://gateway.pinata.cloud/ipfs/${data.image}`}
                                              alt=""
                                            />
                                          )}
                                          <input
                                            className="uploadCoverInput"
                                            type="file"
                                            name="profilebannerimage"
                                            id="newProfileBannerPhoto"
                                            accept="image/*"
                                            onChange={nftfileupload(index)}
                                          />
                                          <label
                                            htmlFor="newProfileBannerPhoto"
                                            className="upload-file-block"
                                          >
                                            <div className="text-center">
                                              <div className="mb-2">
                                                <i className="fa fa-camera fa-2x"></i>
                                              </div>
                                              <div className="text-uppercase">
                                                Upload <br /> Asset Image
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      {/* <div className="form-group inputDnD">
                                          {errornftdataassetimage[index].status == true && (
                                            <span className="alert">Please upload asset image</span>)}
                                          <div className="dashed_border box box-a">
                                            {data.image == "" && (<img
                                              src={defaultImage} alt="" />)}
                                            {data.image != "" && (<img
                                              src={`https://gateway.pinata.cloud/ipfs/${data.image}`} alt="" />)}
                                            <input type="file" className="dashed_border box box-a" id="inputFile" onChange={nftfileupload(index)}
                                              data-title="Drop your files here. PNG, GIF, WEBP, MP4 or MP3 Max 100mb. Browse" />
                                          </div>
                                        </div> */}
            {/* </div>
          <div className="col-md-6">
            {index == 0 && (
              <div className="row">
                <div className="col-md-12 input_icon_area">
                  <label>Token Name*</label>
                  <input
                    type="text"
                    name="tokenname"
                    value={data.tokenname}
                    className="form-control"
                    onChange={handleChangefornftvalue(
                      index
                    )}
                  />{" "}
                  <i className="fa-solid fa-file"></i>
                  {errorfortokenname == true && (
                    <span className="alert">
                      Please submit token name
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-6">
                <label>Asset name*</label>
                <input
                  type="text"
                  name="assetname"
                  className="form-control"
                  value={data.assetname}
                  onChange={handleChangefornftvalue(
                    index
                  )}
                />
                {errornftdataassetname[index]
                  .status == true && (
                    <span className="alert">
                      Please submit asset name
                    </span>
                  )}
              </div>
              <div className="col-md-6">
                <label>Amount*</label>
                <input
                  type="text"
                  name="amount"
                  className="form-control"
                  onChange={handleChangefornftvalue(
                    index
                  )}
                />
                {errornftdataassetamount[index]
                  .status == true && (
                    <span className="alert">
                      Please submit asset amount
                    </span>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 input_icon_area">
                <label>Name*</label>
                <input
                  type="text"
                  name="name"
                  value={data.name}
                  className="form-control"
                  onChange={handleChangefornftvalue(
                    index
                  )}
                />{" "}
                <i className="fa-solid fa-file"></i>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 input_icon_area">
                <label>Image*</label>
                <input
                  type="text"
                  name="image"
                  value={data.image}
                  className="form-control"
                  onChange={handleChangefornftvalue(
                    index
                  )}
                />{" "}
                <i className="fa-solid fa-file"></i>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 input_icon_area">
                <label>Media type*</label>
                <input
                  type="text"
                  name="mediatype"
                  value={data.mediatype}
                  className="form-control"
                  onChange={handleChangefornftvalue(
                    index
                  )}
                />{" "}
                <i className="fa-solid fa-file"></i>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>Description*</label>
                <textarea
                  rows="6"
                  cols="20"
                  className="form-control"
                  name="description"
                  onChange={handleChangefornftvalue(
                    index
                  )}
                ></textarea>
                {errornftdataassetdescription[index]
                  .status == true && (
                    <span className="alert">
                      Please submit asset description
                    </span>
                  )}
              </div>
            </div> */}
            {/* <div className="row">
                                          <div className="col-md-12">
                                            <label>Copyright</label>
                                            <input type="text" name="copyright" className="form-control" />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-12">
                                            <label>Website</label>
                                            <input type="text" name="website" className="form-control" />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-12">
                                            <label>URL</label>
                                            <input type="url" name="url" className="form-control" />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-12">
                                            <ul className="button_area">
                                              <li className="active_tag">Project</li>
                                              <li>Type</li>
                                              <li className="active_tag">Artist</li>
                                              <li>Publisher</li>
                                              <li><i className="fa-solid fa-plus"></i> Add attribute</li>
                                            </ul>
                                          </div>
                                        </div> */}
            {/* </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ paddingTop: "20px" }}></div>
                          </>
                        ))} */}

            {/* <input
                          type="button"
                          name="next"
                          className="next action-button"
                          onClick={onsubmit}
                          value="Next"
                        />
                        <ul className="button_area form_footer_button">
                          <li
                            className="email-send-btn extra-fields-customer"
                            onClick={() => addcreatenftdatabox()}
                          >
                            Add more
                          </li>
                          <li className="email-send-btn" onClick={multi}>
                            Multi
                          </li>
                        </ul>
                      </fieldset>
                    </form>
                  )} */}
            {/* {stepforfundaccount == true && (
                    <form className="msform">
                      <ul id="progressbar">
                        <li className="border_left" id="account">
                          <span>Configure NFTs</span>
                        </li>
                        <li id="personal" className="active border_left">
                          <span>Fund Account</span>
                        </li>
                        <li id="confirm">
                          <span>Review & Required</span>
                        </li>
                      </ul>
                      <fieldset>
                        <div className="form-card">
                          <div className="create_6">
                            <div className="row">
                              {nftdata.map((data, index) => (
                                <div className="col-md-4">
                                  <div className="create_6_img">
                                    <img
                                      src={`https://gateway.pinata.cloud/ipfs/${data.image}`}
                                      className="img-fluid"
                                    />
                                    <div className="create_6_img_content">
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td>Amount </td>
                                            <td> : {data.amount} ADA</td>
                                          </tr>
                                          <tr>
                                            <td>Item Name </td>
                                            <td> : {data.assetname}</td>
                                          </tr>
                                          <tr>
                                            <td>Art of life </td>
                                            <td>
                                              : <a href="#">{data.tokenname}</a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Media Type </td>
                                            <td> : {data.mediatype}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-8">
                                <div className="create_6_proceed_area">
                                  <p>
                                    To Proceed to the next step please send at
                                    least{" "}
                                    <span className="text-primary">
                                      1.774261{" "}
                                    </span>{" "}
                                    <img
                                      src="images/create-6/copy.svg"
                                      alt=""
                                      width="12"
                                    />{" "}
                                    to this address :
                                  </p>
                                  <p className="copy_text">
                                    <span className="text-primary">
                                      {Platformaddress}
                                    </span>{" "}
                                    <img
                                      src="images/create-6/copy.svg"
                                      alt=""
                                      width="12"
                                    />{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <input
                          type="button"
                          name="next"
                          className="next action-button create_6_button mobile_btn"
                          value="Proceed"
                          onClick={buildtxnforasset}
                        />{" "}
                        <input
                          type="button"
                          name="previous"
                          className="previous action-button-previous create_6_button email-send-btn mobile_btn"
                          value="Previous"
                          onClick={backtoaddnftdata}
                        />
                      </fieldset>
                    </form>
                  )}
                  {stepforsubmit == true && (
                    <form className="msform">
                      <ul id="progressbar">
                        <li className="active border_left" id="account">
                          <span>Configure NFTs</span>
                        </li>
                        <li id="personal" className="border_left">
                          <span>Fund Account</span>
                        </li>
                        <li id="confirm">
                          <span>Review & Required</span>
                        </li>
                      </ul>

                      <fieldset>
                        <div className="form-card">
                          <div className="create_6">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="create_6_img2">
                                  <img src={userImage} className="img-fluid" />
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="create_6_proceed_area2">
                                  <div className="create_6_img_content2">
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td className="width_fix">
                                            Item Name{" "}
                                          </td>
                                          <td> : Art of Life</td>
                                        </tr>
                                        <tr>
                                          <td className="width_fix">Image </td>
                                          <td>
                                            {" "}
                                            :{" "}
                                            <span className="text-primary">
                                              b1546113cfb14133
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="width_fix">
                                            Description{" "}
                                          </td>
                                          <td>
                                            : Integer at faucibus urna. Nullam
                                            condimentum leo id elit sagittis
                                            auctor. Curabitur elementum nunc a
                                            leo imperdiet, nec elementum diam
                                            elementum. Etiam elementum euismod
                                            commodo. Proin eleifend eget quam ut
                                            efficitur. Mauris
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="width_fix">
                                            Media Type{" "}
                                          </td>
                                          <td> : JPEG</td>
                                        </tr>
                                        <tr>
                                          <td className="width_fix">
                                            Token id{" "}
                                          </td>
                                          <td> : abcd</td>
                                        </tr>
                                        <tr>
                                          <td className="width_fix">
                                            Policy id{" "}
                                          </td>
                                          <td> : bvjh14545fsvb7842knvo</td>
                                        </tr>
                                        <tr>
                                          <td className="width_fix">Amount </td>
                                          <td>
                                            {" "}
                                            : 0.005{" "}
                                            <span className="text-bold">
                                              ADA
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="width_fix">
                                            User Address{" "}
                                          </td>
                                          <td>
                                            {" "}
                                            :{" "}
                                            <span className="text-primary">
                                              cd646791376b321
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <input
                          type="button"
                          name="next"
                          className="next action-button hide_step"
                          value="Proceed"
                          onClick={buildSendADATransaction}
                        />{" "}
                        <input
                          type="button"
                          name="previous"
                          className="previous action-button-previous email-send-btn"
                          value="Previous"
                        />
                      </fieldset>
                    </form>
                  )}
                  {stepforsuccess == true && (
                    <form className="msform">
                      <ul id="progressbar">
                        <li className="active border_left" id="account">
                          <span>Configure NFTs</span>
                        </li>
                        <li id="personal" className="border_left">
                          <span>Fund Account</span>
                        </li>
                        <li id="confirm">
                          <span>Review & Required</span>
                        </li>
                      </ul>
                      <fieldset>
                        <div className="form-card">
                          <div className="create_6">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="create_6_img2"></div>
                              </div>
                              <div className="col-md-6">
                                <div className="create_6_submit_nort">
                                  <img
                                    src="images/create-6/nortification.png"
                                    className="img-fluid"
                                  />
                                  <p className="nort_head">
                                    Your NFT has been listed!
                                  </p>
                                  <p className="nort_content">
                                    your NFT has been successfully listed on our
                                    marketplace
                                  </p>

                                  <p>
                                    <a className="email-send-btn extra-fields-customer">
                                      View in marketplace
                                    </a>
                                  </p>

                                  <p>
                                    <a className="back_to_home">Back to Home</a>
                                  </p>
                                </div>
                                <div className="col-md-3 col-sm-3"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  )}
                </div>
              </div> */}
            {/* </div> */}
            <Footer />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const NotConnected = () => {
  return (
    <section className="hero is-large">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1>
            <span
              className="icon"
              style={{ fontSize: "100px", marginBottom: "50px" }}
            >
              <i className="fas fa-plug"></i>
            </span>
          </h1>
          <p className="title">Connect your wallet</p>
          <p className="subtitle">
            Do not have Nami Wallet?{" "}
            <a href="https://namiwallet.io/" target="_blank" rel="noreferrer">
              Download
            </a>{" "}
            now!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Mint;
