import React from "react";
import PropTypes from "prop-types";
import { Navbar } from "components";

import logo from '../../assets/images/logo.svg';
import producttitle from '../../assets/images/product-title.svg';

import crypto from '../../assets/images/crypto.png';
import file from '../../assets/images/file.png';
import nft from '../../assets/images/nft.png';
import photography from '../../assets/images/photography.png';
// import "./style.scss";

const MainLayout = ({ children }) => {
  return (
    <React.Fragment>
      {/* <div class="home_areabg"> */}
      <div>{children[0]}</div>
      {/* </div> */}
      <div>{children[1]}</div>
      {/* <div className="nft-step-row">
        <div className="container">
          <div className="nft-step-heading">
            <span className="step-small-title">NFT Steps</span>
            <h2>Easy Steps to create and sell your NFT</h2>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="nft-step-info">
                <div className="nft-step-title">
                  <div className="nftstep-icon">
                    <img src={crypto} alt="" />
                  </div>
                  <h3>Setup your wallet</h3>
                </div>
                <p>Once you have setup your walletof choice, connect it to Sunstep by clicking the wallet icon
                  in the top right corner.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="nft-step-info">
                <div className="nft-step-title">
                  <div className="nftstep-icon">
                    <img src={file} alt="" />
                  </div>
                  <h3>Create your collection</h3>
                </div>
                <p>Setup your collection. Add social links, description, profile and banner images, and set a
                  secondary sales fee.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="nft-step-info">
                <div className="nft-step-title">
                  <div className="nftstep-icon">
                    <img src={nft} alt="" />
                  </div>
                  <h3>Add your NFTs</h3>
                </div>
                <p>Upload your work (image, audio, video or 3d art), add a title and description and customize
                  your NFTs with properties, states, and unlockable content.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="nft-step-info">
                <div className="nft-step-title">
                  <div className="nftstep-icon">
                    <img src={photography} alt="" />
                  </div>
                  <h3>List them for sale</h3>
                </div>
                <p>Choose between actions, fixed price listings, and declining-price listings. You choose how
                  you want to sell your NFTs, and we help you sell them!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-wrap">
        <div className="container">
          <div className="footer-top-row">
            <div className="footer-logo">
              <img src={logo} alt="" />
              <img src={producttitle} className="product-title" alt="" />
            </div>
            <div className="email-subscribe-row">
              <h4>Be Updated</h4>
              <div className="email-subscribe-form">
                <input type="text" placeholder="Your email address" className="email-input" />
                <button type="submit" className="email-send-btn">Send</button>
              </div>
            </div>
          </div>
          <div className="footer-middle-row">
            <div className="row">
              <div className="col-md-4">
                <p>The best NFT marketplace website in the world and feel your experience in selling or buy
                  NFT'S</p>
                <div>
                  <h4>Join the Community</h4>
                </div>
              </div>
              <div className="col-md-2">
                <div className="footer-link-item">
                  <h4>Marketplace</h4>
                  <ul>
                    <li><a href="#">All NFT'S</a></li>
                    <li><a href="#">Collections</a></li>
                    <li><a href="#">Art</a></li>
                    <li><a href="#">Sport</a></li>
                    <li><a href="#">Photography</a></li>
                    <li><a href="#">Pattern</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2">
                <div className="footer-link-item">
                  <h4>My Account</h4>
                  <ul>
                    <li><a href="#">All NFT'S</a></li>
                    <li><a href="#">Collections</a></li>
                    <li><a href="#">Art</a></li>
                    <li><a href="#">Sport</a></li>
                    <li><a href="#">Photography</a></li>
                    <li><a href="#">Pattern</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2">
                <div className="footer-link-item">
                  <h4>Resources</h4>
                  <ul>
                    <li><a href="#">Help Center</a></li>
                    <li><a href="#">Blogs</a></li>
                    <li><a href="#">Docs</a></li>
                    <li><a href="#">Newsletter</a></li>
                    <li><a href="#">Settings</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2">
                <div className="footer-link-item">
                  <h4>Company</h4>
                  <ul>
                    <li><a href="#">About us</a></li>
                    <li><a href="#">Contact us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copy-right">
            <p>&#169; 2020-2022 BrandMe and eDgeWrapper</p>
            <ul>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Terms of Service</a></li>
            </ul>
          </div>
        </div>
      </footer> */}
    </React.Fragment>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
