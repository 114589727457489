import {
  collections_loaded,
  collections_top_loaded,
  collections_add_tokens,
  collections_loading,
  collections_add_assets,
} from "./collectionActions";
import { set_error } from "../error/errorActions";
import { resolveError } from "utils/resolver";

import data_collections from "../../data/collections.json";
import data_collections_cnft from "../../data/collections-cnft.json";
import { get_asset_image_source } from "utils/converter";

import {
  getAsset,
  getAssets,
  saveAsset,
  getCollectionAssets,
  getLockedAssets,
} from "../../database/assets";
import { CNFTapiKey } from "../../config"
export const load_collection = (callback) => async (dispatch) => {
  let all_collections = {};

  for (let collection_id in data_collections) {
    let tmp = data_collections[collection_id];
    tmp.is_martify_verified = true;

    if (tmp.style) {
      if (tmp.style.banner)
        tmp.style.banner_path = `/images/collections/${tmp.id}/${tmp.style.banner}`;
      if (tmp.style.logo)
        tmp.style.logo_path = `/images/collections/${tmp.id}/${tmp.style.logo}`;
    }
    all_collections[collection_id] = tmp;
  }

  for (let collection_id in data_collections_cnft) {
    let tmp = data_collections_cnft[collection_id];
    if (tmp.id in all_collections) {
      all_collections[tmp.id].policy_ids = [
        ...all_collections[tmp.id].policy_ids,
        ...tmp.policy_ids,
      ];
    } else {
      tmp.is_cnft_verified = true;
      all_collections[collection_id] = tmp;
    }
  }

  for (let collection_id in all_collections) {
    all_collections[collection_id].policy_ids = [
      ...new Set(all_collections[collection_id].policy_ids),
    ];
  }

  dispatch(collections_loaded(all_collections));
  callback({ all_collections });
};

export const get_listings =
  (policy_id, page, count, lastVisible, callback) => async (dispatch) => {
    try {
      dispatch(collections_loading(true));

      let output = {
        policy_id: policy_id,
        listing: {},
      };

      const assets = await getCollectionAssets(
        policy_id,
        page,
        count,
        lastVisible
      );

      if (assets) {
        output.listing = assets.reduce((map, asset) => {
          map[asset.details.asset] = asset;
          return map;
        }, {});

        if (output.policy_id && output.listing) {
          dispatch(collections_add_tokens(output));
        }
      }

      callback({ success: true, data: assets });
    } catch (error) {
      callback({ success: false, error });
      dispatch(collections_loading(false));
      dispatch(
        set_error({
          message: resolveError(error.message, "Retrieving Collection Assets"),
          detail: error,
        })
      );
    }
  };

export const get_assets = (assetIds, callback) => async (dispatch, state) => {
  try {
    dispatch(collections_loading(true));

    let output = {
      assets: {},
    };

    let assets = await getAssets(
      assetIds.filter((assetId) => {
        let policyId = assetId.slice(0, 56);
        if (policyId in state().collection.policies_assets) {
          if (assetId in state().collection.policies_assets[policyId]) {
            return false;
          }
        }
        return true;
      })
    );

    for (let i in assets) {
      let asset = assets[i];
      if (asset) {
        if (asset.details) {
          output.assets[asset.details.asset] = asset;
        }
      }
    }

    dispatch(collections_add_assets(output));

    callback({ success: true });
  } catch (error) {
    callback({ success: false, error });
    dispatch(collections_loading(false));
    dispatch(
      set_error({
        message: resolveError(error.message, "Retrieving Assets"),
        detail: error,
      })
    );
  }
};

export const get_asset = (asset_id, callback) => async (dispatch) => {
  try {
    dispatch(collections_loading(true));
    let asset = await getAsset(asset_id);

    if (asset) add_token(asset, dispatch);

    callback({ success: true });
  } catch (error) {
    callback({ success: false, error });
    dispatch(collections_loading(false));
    dispatch(
      set_error({
        message: resolveError(error.message, "Retrieving Asset"),
        detail: error,
      })
    );
  }
};

export const get_listed_assets =
  (count, lastVisible, callback) => async (dispatch) => {
    try {
      let listed_assets = await getLockedAssets(count, lastVisible);

      if (listed_assets) {
        let listed_assets_by_policy = {};

        for (let i in listed_assets) {
          let asset = listed_assets[i];

          if (asset) {
            if (asset.details) {
              if (!(asset.details.policyId in listed_assets_by_policy)) {
                listed_assets_by_policy[asset.details.policyId] = {
                  policy_id: asset.details.policyId,
                  listing: {},
                };
              }
              listed_assets_by_policy[asset.details.policyId].listing[
                asset.details.asset
              ] = asset;
            }
          }
        }

        for (let policy_id in listed_assets_by_policy) {
          dispatch(collections_add_tokens(listed_assets_by_policy[policy_id]));
        }
      }

      callback({ success: true, data: listed_assets });
    } catch (error) {
      callback({ success: false, error });
      dispatch(
        set_error({
          message: resolveError(error.message, "Retrieving Listed Assets"),
          detail: error,
        })
      );
    }
  };

  export const get_listed_assets_new =
  (count, lastVisible, callback) => async (dispatch) => {
    try {
      fetch(`https://api.opencnft.io/2/market/rank/collection?time_range=all`, {
      headers: {
        'X-Api-Key': CNFTapiKey,
      }
    })
      .then((res) => res.json())
      .then((res) => {
        let filtered_res = remove_junks(res.ranking, callback)
        let list = [];
        for (var i in filtered_res) {
          let project = filtered_res[i];
          project.rank = i;
          project.image = get_asset_image_source(
            Array.isArray(project.thumbnail)
              ? project.thumbnail[0].includes("data:image")
                ? project.thumbnail
                : project.thumbnail[0]
              : project.thumbnail
          );
          console.log(project)
          list.push(project);
        }
        callback({ success: true, data: list });
        dispatch(collections_top_loaded(list));})
     
      
      // if (listed_assets) {
      //   let listed_assets_by_policy = {};

      //   for (let i in listed_assets) {
      //     let asset = listed_assets[i];

      //     if (asset) {
      //       if (asset.details) {
      //         if (!(asset.details.policyId in listed_assets_by_policy)) {
      //           listed_assets_by_policy[asset.details.policyId] = {
      //             policy_id: asset.details.policyId,
      //             listing: {},
      //           };
      //         }
      //         listed_assets_by_policy[asset.details.policyId].listing[
      //           asset.details.asset
      //         ] = asset;
      //       }
      //     }
      //   }

      //   for (let policy_id in listed_assets_by_policy) {
      //     dispatch(collections_add_tokens(listed_assets_by_policy[policy_id]));
      //   }
      // }

      // callback({ success: true, data: listed_assets });
    } catch (error) {
      callback({ success: false, error });
      dispatch(
        set_error({
          message: resolveError(error.message, "Retrieving Listed Assets"),
          detail: error,
        })
      );
    }
  };

export const asset_add_offer =
  (wallet, asset, price, callback) => async (dispatch) => {
    try {
      if (!("offers" in asset)) {
        asset.offers = {};
      }

      let offer = {
        t: new Date().getTime(),
        p: price,
      };

      asset.offers[wallet.data.address] = offer;

      await saveAsset(asset);

      add_token(asset, dispatch);

      callback({ success: true, type: "offer-success" });
    } catch (error) {
      dispatch(
        set_error({
          message: resolveError(error.message, "Adding Offer"),
          detail: error,
        })
      );
    }
  };

export const opencnft_get_top_projects_old =
  (time, callback) => async (dispatch) => {
    fetch("https://api.opencnft.io/2/market/rank/nft?time_range=" + time, {
    })
      .then((res) => res.json())
      .then((res) => {
        let filtered_res = remove_junks(res.ranking, callback)
        let list = [];
        for (var i in filtered_res) {
          let project = filtered_res[i];
          project.rank = i;
          project.image = get_asset_image_source(
            Array.isArray(project.thumbnail)
              ? project.thumbnail[0].includes("data:image")
                ? project.thumbnail
                : project.thumbnail[0]
              : project.thumbnail
          );
          list.push(project);
        }
        callback({ success: true, data: list });
        dispatch(collections_top_loaded(list));
      })
      .catch((err) => {
        console.error(err);
      });
  };


export const opencnft_get_top_projects =
  (time, callback) => async (dispatch) => {
    fetch(`https://api.opencnft.io/2/market/rank/collection?time_range=${time}`, {
      headers: {
        'X-Api-Key': CNFTapiKey,
      }
    })
      .then((res) => res.json())
      .then((res) => {
        let filtered_res = remove_junks(res.ranking, callback)
        let list = [];
        for (var i in filtered_res) {
          let project = filtered_res[i];
          project.rank = i;
          project.image = get_asset_image_source(
            Array.isArray(project.thumbnail)
              ? project.thumbnail[0].includes("data:image")
                ? project.thumbnail
                : project.thumbnail[0]
              : project.thumbnail
          );
          console.log(project)
          list.push(project);
        }
        callback({ success: true, data: list });
        dispatch(collections_top_loaded(list));
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const opencnft_get_policy_old =
  (policy_id, callback) => async (dispatch) => {
    fetch(`https://api.opencnft.io/1/policy/${policy_id}`, {})
      .then((res) => res.json())
      .then((res) => {
        callback({ success: true, data: res });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  export const opencnft_get_policy =
  (policy_id, callback) => async (dispatch) => {
    fetch(`https://api.opencnft.io/2/collection/${policy_id}/nfts`, {headers: {
      'X-Api-Key': CNFTapiKey,
    }})
      .then((res) => res.json())
      .then((res) => {
        callback({ success: true, data: res });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const opencnft_get_asset_tx =
  (asset_id, callback) => async (dispatch) => {
    fetch(`https://api.opencnft.io/1/asset/${asset_id}/tx`, {})
      .then((res) => res.json())
      .then((res) => {
        callback({ success: true, data: res });
      })
      .catch((err) => {
        console.error(err);
      });
  };

function add_token(asset, dispatch) {
  let output = {
    policy_id: asset.details.policyId,
    listing: {
      [asset.details.asset]: asset,
    },
  };
  dispatch(collections_add_tokens(output));
}

function remove_junks(cols, callback) {
  let junks = [
    'befa6559b71f217bfc8ab2fd6666956f3cae8e54b4b51d7a3c87cf02',
    'd79181749db228d10c98501a7e1728585780bcf133b7b3df953a9017',
    '4e5f3e165b93c99d922111d9818c03f33e8da8a9f6ae1465c7869a21',
    '40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728',
    '141b1e54e3cef46e357102e811c9d0d72ff3b0a0b6e93f3bbc382306',
    'bff82d31352d9bdfdb49e243ab74af715488631f330b2cf064178f90',
    '7ea3e7719938f8acd700327d6c9dffd7523a02dce1d9891b5f3a452d'
  ]
  let fresh = [];
  cols.forEach(element => {
    if (junks.indexOf(element.policies[0]) === -1) {
      fresh.push(element)
    }
  });
  return fresh;
  // callback({ success: true, data: fresh });
}


export const single_nft_details = (fingerprint, callback) => async (dispatch)=>{
  fetch(`https://api.opencnft.io/2/nft/${fingerprint}/tx`, {
    headers: {
      'X-Api-Key': CNFTapiKey,
    }
  })
    .then((res) => res.json())
    .then((res) => {
      
      console.log(res)
      callback({ success: true, data: res });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const collection_transaction_details = (policy_id, callback) => async (dispatch) => {
  fetch(`https://api.opencnft.io/2/collection/${policy_id}/transactions `, {headers: {
    'X-Api-Key': CNFTapiKey,
  }})
    .then((res) => res.json())
    .then((res) => {
      callback({ success: true, data: res.transactions });
    })
    .catch((err) => {
      console.error(err);
    });
};