// import { TwitterAuthProvider, FacebookAuthProvider } from "firebase/auth";
// export const twitter_login = (callback) => async (dispatch) => {
//   const provider = new TwitterAuthProvider();
// }
import axios from "axios"
import { sunstepApi } from '../../config';
import {
  user_signin,
  user_signup,
  user_social_feed,
  user_recommended_users,
  user_get_followercount,
  user_allfeedcount,
  user_get_followingcount,
  user_followers,
  user_followings
} from "./accountActions";
import { set_error } from "../error/errorActions";
import { resolveError } from "utils/resolver";

export const sunstep_user_signin =
  (walletAddress, callback) => async (dispatch) => {
    axios.post(`${sunstepApi}/api/register`, { "walletaddress": walletAddress })
      .then(res => {
        const value = res.data

        dispatch(user_signin(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        alert(err)
      })
  };

export const sunstep_user_update =
  (formData, callback) => async (dispatch) => {

    console.log(sunstepApi)
    axios.post(`${sunstepApi}/api/update/userdetails`, formData)
      .then(res => {

        const value = res.data
        dispatch(user_signin(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        alert(err)
      })
  };

export const sunstep_user_fetch =
  (formData, callback) => async (dispatch) => {
    axios.post(`${sunstepApi}/api/single/userdetails`, formData)
      .then(res => {
        const value = res.data
        dispatch(user_signin(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        alert(err)
      })
  };

export const sunstep_collection_share =
  (formData, callback) => async (dispatch) => {
    axios.post(`${sunstepApi}/api/shareapost`, formData)
      .then(res => {
        const value = res.data
        //dispatch(user_signin(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        alert(err)
      })
  };

export const sunstep_collection_reshare =
  (formData, callback) => async (dispatch) => {
    axios.post(`${sunstepApi}/api/reshareapost`, formData)
      .then(res => {
        const value = res.data
        //dispatch(user_signin(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        alert(err)
      })
  };

export const authenticate_pinata = async () => {
  const payload = {}
  axios.post(`${sunstepApi}/cardano/pinatasdk`, payload)
}

export const file_upload = async (formData, callback) => {
  // await authenticate_pinata()
  // const payload = {}
  await axios.post(`${sunstepApi}/cardano/pinatasdk/fileupload`, formData)
    .then(res => {
      const value = res.data

      callback({ success: true, data: value });

      // return value
    })
    .catch(err => {
      alert(err)
    })
}

export const sunstep_feed_page_post =
  (formData, callback) => async (dispatch) => {
    axios.post(`${sunstepApi}/api/getshare/socialfeedpage`, formData)
      .then(res => {
        const value = res.data
        // dispatch(user_social_feed(value))
        callback({ success: true, data: value });
      })
      .catch(err => {
        alert(err)
      })
  };

export const sunstep_recommended_users =
  (formData, callback) => async (dispatch) => {
    axios.post(`${sunstepApi}/api/users`, formData)
      .then(res => {
        const value = res.data
        dispatch(user_recommended_users(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        alert(err)
      })
  };

export const getfollowdetailscount =
  (payload, callback) => async (dispatch) => {

    await axios.post(`${sunstepApi}/api/getfollowercount`, payload)
      .then(res => {
        const value = res.data
        dispatch(user_get_followercount(value))
      })
      .catch(err => {
        alert(err)
      })
    await axios.post(`${sunstepApi}/api/getfollowingcount`, payload)
      .then(res => {
        const value = res.data
        dispatch(user_get_followingcount(value))
        // callback({ success: true, data: value });

        // return value
      })
      .catch(err => {
        alert(err)
      })
  };
export const sunstep_user_allfeedcount =
  (formData, callback) => async (dispatch) => {
    axios.post(`${sunstepApi}/api/getshare/allfeedcount`, formData)
      .then(res => {
        const value = res.data
        dispatch(user_allfeedcount(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        alert(err)
      })
  };
export const file_upload_to_aws = async (formData, callback) => {
  // await authenticate_pinata()
  // const payload = {}
  await axios.post(`${sunstepApi}/api/cardano/fileupload`, formData)
    .then(res => {
      const value = res.data
      callback({ success: true, data: value });

      // return value
    })
    .catch(err => {
      alert(err)
    })
}

export const getfollowers = (email, callback) => async (dispatch) => {
  // await authenticate_pinata()
  const payload = {
    useremail: email,
    pageno: "0",
    size: "1000"
  }
  await axios.post(`${sunstepApi}/api/getuserfollowerdetails`, payload)
    .then(res => {
      const value = res.data
      dispatch(user_followers(value))
      callback({ success: true, data: value });

      // return value
    })
    .catch(err => {
      console.log(err)
    })
}

export const getfollowings = (email, callback) => async (dispatch) => {
  // await authenticate_pinata()
  const payload = {
    useremail: email,
    pageno: "0",
    size: "1000"
  }
  await axios.post(`${sunstepApi}/api/getuserfollowingdetails`, payload)
    .then(res => {
      const value = res.data
      dispatch(user_followings(value))
      callback({ success: true, data: value });

      // return value
    })
    .catch(err => {
      console.log(err)
    })
}


export const followaUser = (useremail, friendemail, callback) => async () => {
  const payload = {
    "senderemail": useremail,
    "receiveremail": friendemail,
  }
  await axios.post(`${sunstepApi}/api/followauser`, payload)
    .then(res => {
      callback({ success: true, data: res.data });
      // return value
    })
    .catch(err => {
      console.log(err)
    })
};

export const unfollowaUser = (useremail, friendemail, callback) => async () => {
  const payload = {
    "useremail": useremail,
    "followinguseremail": friendemail,
  }
  await axios.post(`${sunstepApi}/api/removefollowrequest`, payload)
    .then(res => {
      callback({ success: true, data: res.data });
      // return value
    })
    .catch(err => {
      console.log(err)
    })
};






