/* development */
export const apiKey = process.env.REACT_APP_BLOCKFROST_API_KEY;
export const cardanoUrl = process.env.REACT_APP_BLOCKFROST_API_URL;
export const sunstepApi = process.env.REACT_APP_EDGEWRAPPER_SUNSTEP_API_URL;
export const urls = {
  root: "http://martify.io/",
  cardanoscan: process.env.REACT_APP_CARDANOSCAN_URL,
  ipfs: "https://sunstep.infura-ipfs.io/",
}

export const CNFTapiKey = 'ocnft_649fca0a89c6b447deeca30b';