import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import DisplayListing from "./DisplayListing";
import NoAssetFound from "./NoAssetFound";
import { get_asset_image_source, numFormatter } from "utils/converter";

import {
  collection_selected as collectionSelected
} from "../../store/collection/collectionActions";
import CanvasJSReact from '../../assets/js/canvas/canvasjs.react';
import { Link } from "react-router-dom";


import { FadeImg, FadeVideo } from "components/Fades";
import { usePolicyMetadatas } from "hooks/usePolicyMetadatas";
import { get_listings } from "store/collection/api";
import $ from 'jquery';
const ListingSection = ({ thisCollection, policyIds, thisTransactions }) => {
  const show_num_projects_initial = 120;

  const ITEMS_PER_PAGE = 48;
  const dispatch = useDispatch();
  const [listings, setListings] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [paginationObject, setPaginationObject] = useState(undefined);
  const [policyMetadatas, loadingData] = usePolicyMetadatas(policyIds);
  const [transactions, settransactions] = useState([])
  function decimal(num) {
    if (num) return num.toFixed(2);
    return 0;
  }
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    // theme: "light2", //"light1", "dark1", "dark2"
    backgroundColor: "#984D38",
    title: {
      text: "Price Volume Change Over Time"
    },
    data: [{
      type: "line", //change type to bar, line, area, pie, etc
      indexLabelFontColor: "#5A5757",
      indexLabelPlacement: "outside",
      dataPoints: [
        { x: 10, y: 71 },
        { x: 20, y: 55 },
        { x: 30, y: 50 },
        { x: 40, y: 65 },
        { x: 50, y: 71 },
        { x: 60, y: 68 },
        { x: 70, y: 38 },
        { x: 80, y: 92, indexLabel: "Highest" },
        { x: 90, y: 54 },
        { x: 100, y: 60 },
        { x: 110, y: 21 },
        { x: 120, y: 49 },
        { x: 130, y: 36 }
      ]
    }]
  }
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const resetComponentState = useCallback(() => {
    if (policyMetadatas.length > 0) {
      let tmpPaginationObject = {};
      for (let metadata of policyMetadatas) {
        if (metadata) {
          tmpPaginationObject[metadata.policy] = {
            page: 1,
            hasMore: true,
            itemsMinted: metadata.asset_minted,
            policyId: metadata.policy,
          };
        }
      }
      // setListings([]);
      setLastVisible(null);
      setPaginationObject(tmpPaginationObject);
    }
  }, [policyMetadatas]);

  const updateComponentState = (collectionMetadata, loadedAssets) => {

    // setListings([...listings, ...loadedAssets]);
    setLastVisible(loadedAssets[loadedAssets.length - 1]);
    setPaginationObject({
      ...paginationObject,
      [collectionMetadata.policyId]: {
        ...collectionMetadata,
        page: collectionMetadata.page + 1,
        hasMore:
          ITEMS_PER_PAGE * collectionMetadata.page <
          collectionMetadata.itemsMinted,
      },
    });
  };

  const hasMore = () => {
    if (paginationObject !== undefined) {
      for (let key of Object.keys(paginationObject)) {
        if (paginationObject[key].hasMore) {
          return paginationObject[key];
        }
      }
    }
    return false;
  };

  const loadNext = () => {


    const collectionMetadata = hasMore();
    if (!isFetching && collectionMetadata) {
      setIsFetching(true);
      dispatch(
        get_listings(
          collectionMetadata.policyId,
          collectionMetadata.page,
          ITEMS_PER_PAGE,
          lastVisible,
          (res) => {
            if (res.success) {
              updateComponentState(collectionMetadata, res.data);
              setIsFetching(false);
            }
          }
        )
      );
    }
  };

  const setSelected = (asset) => {
    dispatch(collectionSelected(asset))
  }



  useEffect(() => {
    if (!isFetching && listings?.length === 0) loadNext();
  });
  useEffect(() => {
    console.log(thisCollection, policyIds, thisTransactions)
    setListings(thisCollection.opencnft[0]?.data)
    settransactions(thisTransactions)
    
  });

  console.log(transactions)
  useEffect(() => {

    if (policyMetadatas?.length > 0) {
      resetComponentState();
    } else {
      // setListings([]);
      setPaginationObject(undefined);
    }
  }, [resetComponentState, policyMetadatas]);

  useEffect(() => {
    $(".filter_left_area").hide();
    $(".colmdadd").addClass('col-md-12');
    $(".hideshowbtn").on("click", function () {
      $(".filter_left_area").animate({
        width: "toggle"
      });
      $(".colmdadd").removeClass('col-md-12');
      $("#bk2").toggleClass('col-md-9');
      $(".colmdadd").removeClass('col-md-3');
      $(".colmd3").toggleClass('col-md-4');
    });
  }, []);

  const getSrc = (image) => {
    if (Array.isArray(image)) {
      image = image.join("");
      return image;
    } else if (image.includes("ipfs://ipfs/")) {
      let i = "https://sunstep.infura-ipfs.io/ipfs/" + image.split("ipfs://ipfs/")[1];
      return i;
    } else if (image.includes("ipfs://")) {
      let i = "https://sunstep.infura-ipfs.io/ipfs/" + image.split("ipfs://")[1];
      return i;
    } else if (image.length === 46) {
      return "https://sunstep.infura-ipfs.io/ipfs/" + image;
    } else {
      return image;
    }
  };
  console.log(listings)

  return (
    <>
      <div className="container">
        <div className="item_tab_area collection_tab">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="home" data-bs-toggle="tab" data-bs-target="#item" type="button" role="tab"
              aria-controls="home" aria-selected="true">Items</button>
            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
              type="button" role="tab" aria-controls="profile" aria-selected="false">Activity</button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3 filter_left_area">
            <div className="a">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="heading_type accordion-button" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                      aria-controls="collapseOne">
                      Product Type
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show"
                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="filter_wrap _2">
                        <div className="control-pagination">
                          <div className="mixitup-page-stats count_value"></div>
                          <div className="form-group">
                            <input type="checkbox" id="buynow" checked="checked"
                              data-value="all" onChange={e => { }} />
                            <label htmlFor="buynow">Buy Now</label>
                          </div>
                          <div className="form-group">
                            <input type="checkbox" id="onauction" data-value=".software" />
                            <label htmlFor="onauction">On Auction</label>
                          </div>
                          <div className="form-group">
                            <input type="checkbox" id="new_p" data-value=".cat" />
                            <label htmlFor="new_p">New</label>
                          </div>
                          <div className="form-group">
                            <input type="checkbox" id="onoffer" />
                            <label htmlFor="onoffer">On Offer</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingPrice">
                    <button className="accordion-button collapsed" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapsePrice"
                      aria-expanded="false" aria-controls="collapsePrice">
                      Price
                    </button>
                  </h2>
                  <div id="collapsePrice" className="accordion-collapse collapse show"
                    aria-labelledby="headingPrice" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <select className="nice-select" name="price">
                        <option>Filter Price...</option>
                        <option value="asc">United States</option>
                        <option value="desc">United Kindom</option>
                        <option value="pop">UAE</option>
                      </select>
                      <div className="max_min_price">
                        <div className="row">
                          <div className="col-md-5">
                            <input type="number" className="form-control" placeholder="Min" />
                          </div>
                          <div className="col-md-2">To</div>
                          <div className="col-md-5">
                            <input type="number" className="form-control" placeholder="Max" />
                          </div>
                        </div>
                        <div className="apply_area">
                          <button type="submit" className="applybtn">Apply</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                      aria-expanded="false" aria-controls="collapseTwo">
                      Category
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="form-group">
                        <input type="checkbox" id="art" checked="checked" onChange={e => { }} />
                        <label htmlFor="art">Art </label>
                      </div>
                      <div className="form-group">
                        <input type="checkbox" id="sports" />
                        <label htmlFor="sports">Sports</label>
                      </div>
                      <div className="form-group">
                        <input type="checkbox" id="photography" />
                        <label htmlFor="photography">Photography</label>
                      </div>
                      <div className="form-group">
                        <input type="checkbox" id="music" />
                        <label htmlFor="music">Music</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseThree"
                      aria-expanded="false" aria-controls="collapseThree">
                      Collection
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse show"
                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="form-group">
                        <input type="checkbox" id="spacebudz" checked="checked" onChange={e => { }} />
                        <label htmlFor="spacebudz">Spacebudz</label>
                      </div>
                      <div className="form-group">
                        <input type="checkbox" id="claynation" />
                        <label htmlFor="claynation">Claynation</label>
                      </div>
                      <div className="form-group">
                        <input type="checkbox" id="pabia" />
                        <label htmlFor="pabia">Pabia</label>
                      </div>
                      <div className="form-group">
                        <input type="checkbox" id="blockofart" />
                        <label htmlFor="blockofart">Block of Art</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseFour"
                      aria-expanded="false" aria-controls="collapseFour">
                      Items
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse show"
                    aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="form-group">
                        <input type="checkbox" id="varifiedonly" checked="checked" onChange={e => { }} />
                        <label htmlFor="varifiedonly">Varified Only </label>
                      </div>
                      <div className="form-group">
                        <input type="checkbox" id="unvarified" />
                        <label htmlFor="unvarified">Unvarified</label>
                      </div>
                      <div className="form-group">
                        <input type="checkbox" id="allitems" />
                        <label htmlFor="allitems">All Items</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="colmdadd" id="bk2">
            <div className="explore_right_data_area">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="item" role="tabpanel" aria-labelledby="home-tab">
                  <div className="home-header explore_area">
                    <div className="filter_nav_top no_padding">
                      <div>
                        <div className="row">
                          <div className="col-md-6 col-3">
                            <div className="filter_button">
                              
                              {/* <p><a className="btn btn-primary outline hideshowbtn filter_btn">
                                <i
                                className="fa-solid fa-arrow-down-wide-short"></i>
                                Filter</a></p> */}
                            </div>
                          </div>
                          <div className="col-md-6 col-9">
                            <ul className="nav ml-auto float_right">
                              {/* <li>
                                <div className="search_area relative"> <i
                                  className="fa-solid fa-magnifying-glass icon_post_search"></i>
                                  <input type="text" placeholder="Search .." onChange={(e)=>handleSearch(e)}
                                    className="form-control" />
                                </div>
                              </li> */}
                              
                              {/* <div className="dropdown">
                                <button className="sortbybutton dropdown-toggle"
                                  type="button" id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  Sort By </button>
                                <ul className="dropdown-menu dropmenu"
                                  aria-labelledby="dropdownMenuButton1">
                                  <li><a className="dropdown-item" href="#">Recent Add</a>
                                  </li>
                                  <li><a className="dropdown-item" href="#">Recent
                                    Sold</a></li>
                                  <li><a className="dropdown-item" href="#">Price Low to
                                    High</a></li>
                                  <li><a className="dropdown-item" href="#">Price High to
                                    Low</a></li>
                                </ul>
                              </div> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div >
                    {!loadingData && listings?.length > 0 ? (
                      // <InfiniteScroll
                      //   dataLength={listings.length}
                      //   next={loadNext}
                      //   hasMore={hasMore()}
                      //   loader={
                      //     <progress
                      //       className="progress is-small is-primary"
                      //       max="100"
                      //     ></progress>
                      //   }
                      //   endMessage={
                      //     <div style={{ textAlign: "center" }}>
                      //       <span className="icon has-text-info">
                      //         <i className="fas fa-info-circle"></i>
                      //       </span>
                      //       <b>Yay! You have seen it all</b>
                      //     </div>
                      //   }
                      //   scrollableTarget="body"
                      // >
                      // <DisplayListing listings={listings} />
                      // </InfiniteScroll>
                      <div className="row artworks-row" >
                        {listings
                          .slice(0, show_num_projects_initial)
                          .map((project, i) => {
                            return (
                              <div className="col-md-3" key={i} style={{ "width": "285px" }}>
                                <div className="featured-box">
                                  <div className="fadeimg-box featured-imgbox like_share_all_content feed_l_content">
                                    {(project.last_metadata.mediaType ? project.last_metadata.mediaType : "").includes('image') ? (
                                      <FadeImg
                                        className="fadeimg"
                                        src={getSrc(project.last_metadata.image)}
                                        alt={project.last_metadata.name}
                                      />
                                    ) : (project.last_metadata.mediaType ? project.last_metadata.mediaType : "").includes('video') ? (
                                      <FadeVideo
                                        src={getSrc(project.last_metadata.image)}

                                      />
                                    ) :
                                      <FadeImg
                                        src={getSrc(project.last_metadata.image)}
                                        alt={project.last_metadata.name}
                                      />
                                    }
                                    <div className="like_share_all">
                                      <a href="#" ><i className="fa-solid fa-heart"></i></a>
                                      <a href="#" data-bs-toggle="modal" data-bs-target="#shareModal" onClick={() => setSelected(project, '100')}><i className="fa-solid fa-share"></i></a>
                                    </div>
                                  </div>
                                  <div className="featured-information">
                                    <div className="featured-user-info">
                                      <span data-tooltip={project.name} className="user-featured-title-bid">{(project.name).slice(0, 10)}</span>
                                    </div>
                                    <div className="featured-bid" style={{display:"flex", gap:"5px", alignItems: "center"}}>
                                      <span className="user-featured-title-bid">Rairety :{parseFloat(project.rarity_score).toFixed(2)}</span>

                                      {/* <span
                                        data-tooltip={`Change in volume in past ${window === "7d" ? "7 days" : "1 day"
                                          }`}
                                        className={
                                          parseFloat(
                                            decimal(
                                              project[
                                              window === "7d"
                                                ? "7dChange"
                                                : "1dChange"
                                              ]
                                            )
                                          ) >= 0
                                            ? "text-green"
                                            : "text-red"
                                        }
                                      >
                                        {parseFloat(
                                          decimal(
                                            project[
                                            window === "7d"
                                              ? "7dChange"
                                              : "1dChange"
                                            ]
                                          ) * 100
                                        ).toFixed(0)}
                                        %
                                        {
                                          parseFloat(
                                            decimal(
                                              project[
                                              window === "7d"
                                                ? "7dChange"
                                                : "1dChange"
                                              ]
                                            )
                                          ) >= 0
                                            ? <i className="fa-solid fa-arrow-up-long"></i> :
                                            <i className="fa-solid fa-arrow-down-long"></i>

                                        }
                                      </span> */}

                                    </div>
                                  </div>
                                  <div className="featured-action">
                                    <Link to={`#`} className="btn primary-btn">
                                      See More</Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="tab-pane fade" id="profile" role="tabpanel"
                  aria-labelledby="profile-tab">
                  <div className="activity_area">
                    <div className="price_graph">
                      <div className="step-heading item_detail_heading">
                        <h3>Price Analysis</h3>
                      </div>
                      <div className="featured-box">
                        {/* <div id="chartContainer2"></div> */}
                        <CanvasJSChart options={options} />
                      </div>
                    </div>
                    <div className="activity_product">
                      <div className="step-heading item_detail_heading">
                        <h3>Activity</h3>
                      </div>
                      <div className="featured-box">
                        <div className="table-responsive">
                          <table className="activity_product_price table">
                            <tbody>
                              <tr className="activity_table_heading">
                                <td>STATUS</td>
                                <td>EVENT</td>
                                <td>ITEM</td>
                                <td>PRICE</td>
                                <td>FROM</td>
                                <td>TO</td>
                                <td>RARITY RANK</td>
                                <td>MARKETPLACE</td>
                              </tr>
                              {transactions?.map((data, i) => (
                                <tr key={i}>
                                  <td><a href="#" className="confirm_btn">Confirmed</a></td>
                                  <td>Purchase</td>
                                  <td className="art_td_img">{data.name}
                                  </td>
                                  <td className="art_td_img">{data.price}
                                  </td>
                                  <td className="blue_color">{(data.seller).slice(0,8)}...{(data.seller).slice((data.seller.length-9),(data.seller.length))}</td>
                                  <td className="blue_color">{(data.buyer).slice(0,8)}...{(data.buyer).slice((data.buyer.length-9),(data.buyer.length))}</td>
                                  <td>{data.rarity_rank}</td>
                                  <td className="price_tbl_icon"><a href="#"><i
                                    className="fa-solid fa-share-from-square"></i>
                                  </a>{data.marketplace}</td>
                                </tr>
                              ))}
                              {/* <tr>
                                <td><a href="#" className="confirm_btn">Confirmed</a></td>
                                <td>Purchase</td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/create-6/user.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">Art Universe - 02145</li>
                                  </ul>
                                </td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/collection/round.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">700</li>
                                  </ul>
                                </td>
                                <td className="blue_color">addr1...x5vqr</td>
                                <td className="blue_color">addr1...9dw8t</td>
                                <td>3 hours ago</td>
                                <td className="price_tbl_icon"><a href="#"><i
                                  className="fa-solid fa-share-from-square"></i></a>
                                </td>
                              </tr>
                              <tr>
                                <td><a href="#" className="confirm_btn">Confirmed</a></td>
                                <td>Purchase</td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/create-6/user.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">Art Universe - 02145</li>
                                  </ul>
                                </td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/collection/round.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">700</li>
                                  </ul>
                                </td>
                                <td className="blue_color">addr1...x5vqr</td>
                                <td className="blue_color">addr1...9dw8t</td>
                                <td>3 hours ago</td>
                                <td className="price_tbl_icon"><a href="#"><i
                                  className="fa-solid fa-share-from-square"></i></a>
                                </td>
                              </tr>
                              <tr>
                                <td><a href="#" className="confirm_btn">Confirmed</a></td>
                                <td>Purchase</td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/create-6/user.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">Art Universe - 02145</li>
                                  </ul>
                                </td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/collection/round.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">700</li>
                                  </ul>
                                </td>
                                <td className="blue_color">addr1...x5vqr</td>
                                <td className="blue_color">addr1...9dw8t</td>
                                <td>3 hours ago</td>
                                <td className="price_tbl_icon"><a href="#"><i
                                  className="fa-solid fa-share-from-square"></i></a>
                                </td>
                              </tr>
                              <tr>
                                <td><a href="#" className="confirm_btn">Confirmed</a></td>
                                <td>Purchase</td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/create-6/user.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">Art Universe - 02145</li>
                                  </ul>
                                </td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/collection/round.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">700</li>
                                  </ul>
                                </td>
                                <td className="blue_color">addr1...x5vqr</td>
                                <td className="blue_color">addr1...9dw8t</td>
                                <td>3 hours ago</td>
                                <td className="price_tbl_icon"><a href="#"><i
                                  className="fa-solid fa-share-from-square"></i></a>
                                </td>
                              </tr>
                              <tr>
                                <td><a href="#" className="confirm_btn">Confirmed</a></td>
                                <td>Purchase</td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/create-6/user.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">Art Universe - 02145</li>
                                  </ul>
                                </td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/collection/round.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">700</li>
                                  </ul>
                                </td>
                                <td className="blue_color">addr1...x5vqr</td>
                                <td className="blue_color">addr1...9dw8t</td>
                                <td>3 hours ago</td>
                                <td className="price_tbl_icon"><a href="#"><i
                                  className="fa-solid fa-share-from-square"></i></a>
                                </td>
                              </tr>
                              <tr>
                                <td><a href="#" className="confirm_btn">Confirmed</a></td>
                                <td>Purchase</td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/create-6/user.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">Art Universe - 02145</li>
                                  </ul>
                                </td>
                                <td className="art_td_img">
                                  <ul className="text_float">
                                    <li className="img_middle"><img
                                      src="images/collection/round.png" alt="" />
                                    </li>
                                    <li className="art_img_desc">700</li>
                                  </ul>
                                </td>
                                <td className="blue_color">addr1...x5vqr</td>
                                <td className="blue_color">addr1...9dw8t</td>
                                <td>3 hours ago</td>
                                <td className="price_tbl_icon"><a href="#"><i
                                  className="fa-solid fa-share-from-square"></i></a>
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingSection;
