import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";


import {
  availableWallets,
  connectWallet,
  loadAssets,
} from "../../store/wallet/api";

import { sunstep_user_signin } from "../../store/account/api"

import { WALLET_STATE } from "../../store/wallet/walletTypes";
import { FadeImg } from "components/Fades";

import "./style.scss";
import { $ } from "jquery";

const wallets = {
  ccvault: {
    title: "ccvault.io",
    image: "/images/wallets/ccvault.svg",
  },
  gerowallet: {
    title: "GeroWallet",
    image: "/images/wallets/gero.svg",
  },
  nami: {
    title: "Nami",
    image: "/images/wallets/nami.svg",
  },
};

const ButtonConnect = ({
  state_wallet,
  availableWallets,
  connectWallet,
  loadAssets,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [showWallets, setShowWallets] = useState(false);
  const dispatch = useDispatch();
  let history = useHistory();


  function onclick_connect_wallet() {
 
    availableWallets((res) => {
      if (res.wallets.length === 0) {
        setShowNotification("no-wallet");
      } else if (res.wallets.length === 1) {
        connect_wallet(res.wallets[0]);
        
      } else if (res.wallets.length > 1) {
        setShowWallets(res.wallets);
      }
    });
  }

  function connect_wallet(wallet_name) {
    setShowWallets(false);
    connectWallet(wallet_name, (res) => {
      localStorage.setItem('wallet_name',wallet_name);
      if (!res.success) {
        setShowNotificationMessage(res.msg);
      }
      else if (res.data.data.address) {
        dispatch(sunstep_user_signin(res.data.data.address, (res) => {
          if (res.data.email == undefined) {
            // profileEdit();
          }
        }));
      }
    });
  }
  const profileEdit = () => {
    history.push("/profile");
  };
  const closeNotification = () => {
    setShowNotification(false);
  };
 

  useEffect(() => {
    window.onload=function() {
      if(window.localStorage.getItem('wallet_name')){
        onclick_connect_wallet()
      }}
   
    if (state_wallet.loading) {
      if (
        [
          "no-wallet",
          "no-accept",
          "connected",
          WALLET_STATE.CONNECTING,
          WALLET_STATE.GETTING_ASSETS,
        ].includes(state_wallet.loading)
      )
        setShowNotification(state_wallet.loading);
    } else {
      setShowNotification(false);
    }

    if (
      state_wallet.connected &&
      !state_wallet.loading &&
      !state_wallet.loaded_assets
    ) {
      loadAssets(state_wallet, (res) => { });
    }
  }, [loadAssets, state_wallet, localStorage]);

  function clear_notification() {
    setShowNotification(false);
    setShowNotificationMessage(false);
  }

  return (
    <>
      {!state_wallet.connected ? (
        <button
          className={
            "connect-btn" +
            (state_wallet.loading ? " is-loading" : "")
          }
          disabled={state_wallet.loading}
          onClick={() => onclick_connect_wallet()}
        >
          <span>Connect</span>
        </button>
      ) : (
        <Link type="button" className="connect-btn" to="/account">
          <span>Account</span>
        </Link>
      )}
      {showNotification || showNotificationMessage !== false ? (
        <button type="button" className="btn btn-primary nortification_footer">
          <div className="row">
            {showNotification === "no-wallet" ? (
              <div className="col-md-10">
                No wallet installed.{" "}
                <a href="https://namiwallet.io/" target="_blank" rel="noreferrer">
                  Get it now
                </a>
                .
              </div>
            ) : showNotification === "no-accept" ? (
              <div className="col-md-10">You need to allow wallet access.</div>
            ) : showNotification === "connected" ? (
              <div className="col-md-10">Wallet connected</div>
            ) : showNotification === WALLET_STATE.CONNECTING ? (
              <div className="col-md-10">Connecting wallet...</div>
            ) : showNotification === WALLET_STATE.GETTING_ASSETS ? (
              <div className="col-md-10">Getting assets in your wallet...</div>
            ) : showNotificationMessage !== false ? (
              <div className="col-md-10">{showNotificationMessage}</div>
            )

              : (
                <></>
              )}
            <div className="col-md-2">
              <span className="badge badge-light">
                <i className="fa-solid fa-xmark hide_x" onClick={closeNotification}></i></span>
            </div>
          </div>

        </button>
      ) : (
        <></>
      )}
      {showWallets ? (
        <div className={"modal " + (showWallets ? "is-active" : "")}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Connect wallet with one of our available wallet providers
                </h4>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  onClick={() => setShowWallets(false)}
                ></button>
              </div>
              <div className="modal-body">
                {showWallets &&
                  showWallets.length > 0 &&
                  showWallets.map((name) => (
                    <div className="connect-featured-action" key={name}>
                      <button type="button" className="connect-primary-btn"
                        onClick={() => connect_wallet(name)}
                        disabled={state_wallet.loading}
                      >
                        <div className="matamask-row">
                          <FadeImg
                            className="wallets-img"
                            alt={name}
                            src={wallets[name].image}
                          />
                          <div className="metamask"> {wallets[name].title}</div>
                        </div>
                      </button>
                    </div>
                  ))}
              </div>
              <div className="modal-footer">
                <div className="wallet-footer">
                  <h5>By continuing, you agree to our Privacy Policy and Terms of Service</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : <></>
      }
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    state_wallet: state.wallet,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    availableWallets: (callback) => dispatch(availableWallets(callback)),
    connectWallet: (is_silent, callback) =>
      dispatch(connectWallet(is_silent, callback)),
    loadAssets: (wallet, callback) => dispatch(loadAssets(wallet, callback)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ButtonConnect
);
