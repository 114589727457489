import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./style.css";
import AssetImageFigure from "../AssetImageFigure";
import { fromLovelace } from "../../utils/converter";

const AssetCard = ({ state_collection, asset, column_className, show_offer }) => {

  let collection = false;
  if (asset) {
    collection = state_collection.policies_collections[asset.details.policyId]
  }

  return (
    <>
      {
        asset ? (
          <>
            {
              asset.details.onchainMetadata ? (
                // <div className="colmd3 col-md-3">
                <div className="featured-box explore-imagebox">
                  <div className="featured-imgbox">
                    <AssetImageFigure asset={asset} />
                  </div>
                  <div className="featured-information">
                    <div className="featured-user-info">
                      <span className="user-name-bid">{asset.details.onchainMetadata.name}</span>
                      {/* <span className="user-featured-title-bid">Block of Art</span> */}
                    </div>
                    {/* <div className="featured-bid">
                        <span className="user-name-bid">Current Bid</span>
                        <span className="user-featured-title-bid">0.005 ADA</span>
                      </div> */}
                  </div>
                  <div className="featured-action">
                    <Link to={`/assets/${asset.details.policyId}/${asset.details.asset}`} className="btn primary-btn">
                      Place Order</Link>
                  </div>
                </div>
                // </div>
              ) : <></>
            }
          </>
        ) : <></>
      }
    </>
  )
};

function mapStateToProps(state, props) {
  return {
    state_collection: state.collection,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AssetCard);
