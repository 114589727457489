import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
// import { useState } from "react"
import sunstepApi from "../../config"
import Footer from "../../components/Footer/index"
import {
  sunstep_feed_page_post,
  sunstep_recommended_users,
  sunstep_user_allfeedcount, getfollowers, getfollowings, followaUser, unfollowaUser
} from "../../store/account/api";
import { FadeImg, FadeVideo } from "components/Fades";
import $ from "jquery";
import userdefault from "../../assets/images/userdefault.png";
import { sunstep_recommended_users as sunstepRecommendedUsers } from "../../store/account/api";
import { sunstep_collection_share, sunstep_collection_reshare } from "../../store/account/api";

import "./style.scss";

const Social = () => {
  const state_account = useSelector((state) => state.account);
  const ITEMS_PER_PAGE = 18;
  const [pagecount, setPagecount] = useState(0);
  const dispatch = useDispatch();
  const [allfeedcount, setallfeedcount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const state_wallet = useSelector((state) => state.wallet);
  const [hasMore, sethasMore] = useState(true);
  const [feed, setFeed] = useState([]);
  const [selectedCollection, setselectedCollection] = useState(undefined);
  const [col0, setcol0] = useState([]);
  const [col1, setcol1] = useState([]);
  const [col2, setcol2] = useState([]);
  const [col3, setcol3] = useState([]);
  const [col4, setcol4] = useState([]);
  const [col5, setcol5] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [inputs, setInput] = useState('');
  const [share, setShare] = useState('');
  const [specificFriend, setSpecificFriend] = useState([])
  const [socialfeeds, setsocialfeeds] = useState([])

  const pattern0 = [
    "feed_md_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_lg_img",
    "feed_xs_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_md_img",
    "feed_md_img",
  ];
  const pattern1 = [
    "feed_xs_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_lg_img",
    "feed_xs_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_md_img",
    "feed_md_img",
  ];
  const pattern2 = [
    "feed_lg_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_lg_img",
    "feed_xs_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_md_img",
    "feed_md_img",
  ];
  const pattern3 = [
    "feed_md_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_lg_img",
    "feed_xs_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_md_img",
    "feed_md_img",
  ];
  const pattern4 = [
    "feed_lg_img",
    "feed_xs_img",
    "feed_lg_img",
    "feed_lg_img",
    "feed_xs_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_md_img",
    "feed_md_img",
  ];
  const pattern5 = [
    "feed_md_img",
    "feed_md_img",
    "feed_md_img",
    "feed_lg_img",
    "feed_xs_img",
    "feed_xs_img",
    "feed_md_img",
    "feed_md_img",
    "feed_lg_img",
  ];

  function prepare_data(_list) {
    const mod = _list.length / 6;
    let count = 0;
    let tmpCol0 = [];
    let tmpCol1 = [];
    let tmpCol2 = [];
    let tmpCol3 = [];
    let tmpCol4 = [];
    let tmpCol5 = [];
    for (let i = 0; i <= mod; i++) {
      const bl0 = _list[i + count];
      if (bl0 != undefined) tmpCol0.push(bl0);

      count++;
      const bl1 = _list[i + count];
      if (bl1 != undefined) tmpCol1.push(bl1);

      count++;
      const bl2 = _list[i + count];
      if (bl2 != undefined) tmpCol2.push(bl2);

      count++;
      const bl3 = _list[i + count];
      if (bl3 != undefined) tmpCol3.push(bl3);

      count++;
      const bl4 = _list[i + count];
      if (bl4 != undefined) tmpCol4.push(bl4);

      count++;
      const bl5 = _list[i + count];
      if (bl5 != undefined) tmpCol5.push(bl5);
    }
    setcol0([...col0, ...tmpCol0]);
    setcol1([...col1, ...tmpCol1]);
    setcol2([...col2, ...tmpCol2]);
    setcol3([...col3, ...tmpCol3]);
    setcol4([...col4, ...tmpCol4]);
    setcol5([...col5, ...tmpCol5]);
  }
  useEffect(() => {
    if (state_wallet.connected) {
      let obj = {
        pageno: 0,
        size: 50,
      };
      //state_wallet.data.address
      dispatch(
        sunstepRecommendedUsers(obj, (res) => {
          setFollowers(res.data);
        })
      );
    }

    if (!isFetching && col0.length === 0) {
      dispatch(
        sunstep_user_allfeedcount(state_account.profile.email, (res) => {
          setallfeedcount([res.data]);
          loadNext();
        })
      );
    }
  }, [state_wallet]);

  useEffect(() => {
    let userobj = {
      "userid": '',
      "pageno": pagecount,
      "size": 1000
    }
    dispatch(sunstep_feed_page_post(userobj, (res) => {
      debugger;
      if (res.data.length > 0) {
        setsocialfeeds(res.data)
      }
    })
    );
    dispatch(getfollowings(state_account.profile.email))
    dispatch(getfollowers(state_account.profile.email))



  }, [state_wallet]);
  const share_social = () => {

    setShowNotificationMessage("Share Sucessfully..");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
    setInput("")
    setShare('')

    if (state_account.profile.email != undefined) {
      if (share === 'Public') {
        setSpecificFriend([])
      } else if (share === 'Friends') {
        setSpecificFriend([])
      }



      const shareObj = {
        userid: state_account.profile.email,
        sharedto: specificFriend,
        notes: inputs,
        shareid: selectedCollection.sharedbyId[0]
      };


      dispatch(
        sunstep_collection_reshare(shareObj, (res) => {
        })
      );
    }
  };
  const handleClick = (userEmail) => {
    if (specificFriend.includes(userEmail)) {

      setSpecificFriend(specificFriend.filter((email) => email !== userEmail));
    } else {

      setSpecificFriend([...specificFriend, userEmail]);
    }
  };
  useEffect(() => {
    $("body").addClass("loaded");
    $(".drop-down__button").click(function () {
      $(".drop-down").toggleClass("drop-down--active");
    });
  });

  const loadNext = () => {
    setShowNotificationMessage("Please wait...");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    if (hasMore && !isFetching) {
      setIsFetching(true);
      let userobj = {
        userid: state_account.profile.email,
        pageno: pagecount,
        size: `${ITEMS_PER_PAGE}`,
      };
      dispatch(
        sunstep_feed_page_post(userobj, (res) => {
          if (res.data) {
            prepare_data(res.data);
            setFeed([...feed, ...res.data]);
            setPagecount(pagecount + 1);
            sethasMore([res.data.length > 0]);
            setIsFetching(false);
          }
        })
      );
    }
  };
  const NotConnected = () => {
    return (
      <section className="hero is-large">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1>
              <span
                className="icon"
                style={{ fontSize: "100px", marginBottom: "50px" }}
              >
                <i className="fas fa-plug"></i>
              </span>
            </h1>
            <p className="title">Connect your wallet</p>
            <p className="subtitle">
              Do not have Nami Wallet?{" "}
              <a href="https://namiwallet.io/" target="_blank" rel="noreferrer">
                Download
              </a>{" "}
              now!
            </p>
          </div>
        </div>
      </section>
    );
  };
  const closeNotification = () => {
    setShowNotification(false);

  };

  const handleChange = (e) => {
    if (e.target.name === 'note') {
      setInput(e.target.value)

    }
  };

  // console.log(share, inputs, specificFriend)

  const [connectfriend, setconnectfriend] = useState(false)
  function Connectfriend() {
    setconnectfriend(!connectfriend)
  }

  const followuser = (friendemail) => {

    dispatch(followaUser(state_account.profile.email, friendemail, (res) => {
      if (res.data) {
        dispatch(getfollowings(state_account.profile.email))
        dispatch(getfollowers(state_account.profile.email))
      }
    })
    );
  }

  const unfollowuser = (friendemail) => {

    dispatch(unfollowaUser(state_account.profile.email, friendemail, (res) => {
      if (res.data) {
        dispatch(getfollowings(state_account.profile.email))
        dispatch(getfollowers(state_account.profile.email))
      }
    })
    );
  }



  console.log(socialfeeds)

  return (
    <>
      {!state_wallet.connected ? <NotConnected /> : <></>}
      {state_wallet.connected ? (
        <>
          {showNotification ? (
            <button
              type="button"
              className="btn btn-primary nortification_footer"
            >
              <div className="row">
                <div className="col-md-10">{showNotificationMessage}</div>
                <div className="col-md-2">
                  <span className="badge badge-light">
                    <i
                      className="fa-solid fa-xmark hide_x"
                      onClick={closeNotification}
                    ></i>
                  </span>
                </div>
              </div>
            </button>
          ) : (
            <></>
          )}
          <div className="main_social_section">
            <div className="explore_header_section">
              <div className="container">
                <div className="explore_heading social_heading">
                  <h1>Connect with a Community of <br />Passionate NFT Enthusiasts</h1>
                </div>
              </div>
            </div>
            <div className="container ">
              {connectfriend == false && <div className="social_section_row">
                {/* <div className="col-md-2"> */}
                <h5>Recently shared</h5>
                {/* </div> */}
                {/* <div className="col-md-1"> */}
                <div className="connect_friend_section_wrapp" onClick={Connectfriend}>
                  <span>5</span>
                  <div className="connect_friend_section">
                    <img src="/images/account_circleimg.svg" />
                  </div>
                </div>
                {/* </div> */}
              </div>}
              <div className="colmdadd col-md-12">
                <div className="explore_right_data_area">
                  <div className="row">
                    <div className="infinite-scroll-component__outerdiv">
                      <div className="infinite-scroll-component " style={{ "height": "auto", "overflow": "auto" }}>
                        <div className="block">
                          {/* <div className="artworks-row-wrapp row artworks-row"> */}
                          <InfiniteScroll
                            dataLength={socialfeeds.length}
                            next={loadNext}
                            hasMore={hasMore}
                            className="artworks-row-wrapp row artworks-row"
                            endMessage={
                              <div style={{ textAlign: "center" }}>
                                <span className="icon has-text-info">
                                  <i className="fas fa-info-circle"></i>
                                </span>
                                <b>Yay! You have seen it all</b>
                              </div>
                            }
                            scrollableTarget="body"
                          >
                            {socialfeeds.map((data, i) => (
                              <div className="featured-box explore-imagebox" key={`share${i}`}>
                                <div className="featured-imgbox">
                                  <img src={data.postimage} />
                                </div>
                                {/* <div className="featured-information"> */}
                                <div className="featured-user-info featured_user_details mt-2">
                                  <h4>{data.postname}</h4>
                                  <div className="row align-items-center">
                                    <div className="col-md-3">
                                      <div className="connect-user m-auto">
                                        {data.imageofsharedby[0] == undefined || data.imageofsharedby[0] == '' ?
                                          (<img src={userdefault} />) :
                                          <img id="profilePic" className="pic" src={`${data.imageofsharedby[0]}`} alt="" />
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-9">
                                      <h5>{data.sharedby[0]}</h5>
                                      <p>wants to connect with you</p>
                                    </div>
                                  </div>
                                </div>
                                {/* </div> */}
                                <div className="featured-action">
                                  {state_account.followings.find(item => item.userdetails[0]?.email === data.sharedbyEmail[0]) ? (
                                    <a className="btn primary-btn" style={{ background: "#842ea54d" }} onClick={() => unfollowuser(data.sharedbyEmail[0])}>Unfollow</a>
                                  ) : (
                                    <a className="btn primary-btn" onClick={() => followuser(data.sharedbyEmail[0])}>Follow</a>
                                  )}
                                  {/* {state_account.followings.length === 0 && <a href="#" className="btn primary-btn" onClick={()=>followuser(data.sharedbyEmail[0])}>Unfollow</a>}
                                {(state_account.followings.find(item => item.userdetails[0]?._id === data.sharedbyId[0])) && <a href="#" className="btn primary-btn">Follow</a>}
{(state_account.followings.find(item => item.userdetails[0]?._id !== data.sharedbyId[0])) && <a href="#" className="btn primary-btn">Follow</a>} */}
                                </div>
                              </div>
                            ))}
                          </InfiniteScroll>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <Footer />

          {/* <div className="feed_l_area">
            <div className="container">
              <div
                className="modal fade"
                id="shareModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <form>
                  <div className="feed_details modal-dialog offer_dialog modal-lg">
                    <div className="modal-content feed_modal_content">
                      <div className="modal-header">
                        <h5 className="modal-title text-white">Share</h5>
                        <a
                          className="modal-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setInput('')
                            setShare('')
                            setSpecificFriend([])

                          }}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </a>
                      </div>
                      <div className="modal-body">
                        {!state_wallet.connected ? <NotConnected /> : <></>}
                        {state_wallet.connected ? (
                          <div className="row">
                            <div className="col-md-4">
                              {selectedCollection != undefined ? (
                                (selectedCollection.thumbnail_type
                                  ? selectedCollection.thumbnail_type
                                  : ""
                                ).includes("image") ? (
                                  <FadeImg
                                    src={selectedCollection.postimage}
                                    alt={selectedCollection.postname}
                                  />
                                ) : (selectedCollection.thumbnail_type
                                  ? selectedCollection.thumbnail_type
                                  : ""
                                ).includes("video") ? (
                                  <FadeVideo src={selectedCollection.postimage} />
                                ) : (
                                  <FadeImg
                                    src={selectedCollection.postimage}
                                    alt={selectedCollection.postname}
                                  />
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-md-8">
                              <div className="first_row margin-bottom-20">
                                <div className="row">
                                  <div className="col-md-12">
                                    <ul className="display_flex margin-bottom-20">
                                      <li className="user_det_img">
                                        <img
                                          src={state_account.profile.profileimage}
                                          alt="sunstep"
                                        />
                                      </li>
                                      <li className="user_img_desc">
                                        Be the first to share and get rewards.
                                        <span>@Sunstep Team</span>
                                      </li>
                                    </ul>
                                    <textarea
                                      rows="4"
                                      cols="10"
                                      type="text"
                                      name='note'
                                      value={inputs}
                                      onChange={handleChange}
                                      className="comment_text"
                                      placeholder="Write something here about the point of interests of sharing the NFT. It might help the buyer to purchase. If that conversation happens you will be rewarded."
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="second_row margin-bottom-20">
                                <p className="text-white">Share With </p>
                                <div className="share_area" >
                                  <ul className="share_list">
                                    <li onClick={() => setShare('Public')}>
                                      <input
                                        type="radio"
                                        id="f-option"
                                        name="share"
                                        value={share}
                                        checked={share == "Public"}
                                      />
                                      <label htmlFor="f-option">Public</label>
                                      <div className="check"></div>
                                    </li>
                                    <li onClick={() => setShare('Friends')}>
                                      <input
                                        type="radio"
                                        id="s-option"
                                        name="share"
                                        value={share}
                                        checked={share == "Friends"}
                                      />
                                      <label htmlFor="s-option">Friends</label>
                                      <div className="check">
                                        <div className="inside"></div>
                                      </div>
                                    </li>
                                    <li
                                      className="dropdown-toggle"
                                      type="button"
                                      id="defaultDropdown"
                                      data-bs-toggle="dropdown"
                                      data-bs-auto-close="true"
                                      aria-expanded="false"

                                    >
                                      <label htmlFor="s-option1">
                                        Specific Friends{" "}
                                        <i className="fa-solid fa-circle-chevron-down text-right spc_icon"></i>
                                      </label>
                                    </li>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="defaultDropdown"
                                    >
                                      {followers
                                        .slice(0, followers.length - 1)
                                        .map((user, i) => {
                                          return (
                                            <div
                                              className="display_flex specific_area"
                                              key={i}
                                            >
                                              <li>
                                                <p className="user_det_img">
                                                  <img
                                                    src={`https://gateway.pinata.cloud/ipfs/${user.profileimage}`}
                                                    alt=""
                                                  />
                                                </p>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                >
                                                  {user.firstname} {user.lastname}
                                                </a>
                                              </li>
                                              <li>
                                                {/* <a
                                                  type="button"
                                                  className="btn email-send-btn"
                                                  onClick={() => setSpecificFriend([...specificFriend, user.email])}
                                                >
                                                Select
                                                </a> */}
          {/* </li>
                                              <li>
                                              <a
                                              type="button"
                                              className="btn email-send-btn"
                                              onClick={() => handleClick(user.email)}
                                              >
                                              {specificFriend.includes(user.email) ? "Unselect" : "Select"}
                                              </a>
                                              </li>
                                            </div>
                                          );
                                        })}
                                    </ul>
                                  </ul>
                                </div>
                              </div>
                              <div className="share_now">
                                <div className="display_flex">
                                {(share !== 'Public' && share !== 'Friend' && share !== 'Specificfriend') || inputs === '' || (share === 'Specificfriend' && specificFriend.length === 0) ? 
                                    <a type="button" className="btn email-send-btn" onClick={() => {
                                      setShowNotificationMessage("All fields are required to share..");
                                      setShowNotification(true);
                                    }}> */}
          {/* onClick={() => {
                                      setShowNotificationMessage("All fields are required to share..");
                                      setShowNotification(true);
                                    }} */}

          {/* Share
                                  </a>
                                    :
                                    <a
                                      type="button"
                                      className="btn email-send-btn"
                                      onClick={() => share_social()}
                                      data-bs-dismiss= 'modal'
                                    >
                                      Share
                                    </a>}
                                 <a href="#" data-bs-dismiss= 'modal'
                                    onClick={() => {
                                      setInput('')
                                      setShare('')
                                      setSpecificFriend([])
                                    }}
                                  >
                                    Cancel
                                  </a>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="feed_details modal-dialog offer_dialog modal-lg">
                  <div className="modal-content feed_modal_content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-4">
                          <img
                            src="images/feed/1.png"
                            alt=""
                            className="img-responsive"
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="first_row margin-bottom-20">
                            <div className="row">
                              <div className="col-md-5 col-sm-5">
                                <ul className="display_flex">
                                  <li className="user_det_img">
                                    <img src="images/create-6/user.png" alt="" />
                                  </li>
                                  <li className="user_img_desc">
                                    Diana Wade <span>@diana wade</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-2 col-sm-3">
                                <button
                                  type="submit"
                                  className="email-send-btn mobile_btn"
                                >
                                  <i className="fa-solid fa-plus"></i> Follow
                                </button>
                                {() => {
                                  setselectedCollection(selectedCollection.postimage);
                                }}
                              </div>
                              <div className="col-md-5 col-sm-4">
                                <div className="like_share_detail">
                                  <a href="#">
                                    <i className="fa-solid fa-heart"></i>
                                  </a>
                                  <a href="#">
                                    <i className="fa-solid fa-share"></i>
                                  </a>
                                  <a
                                    href="#"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="second_row margin-bottom-20">
                            <div className="user_det_heading">
                              <div className="row">
                                <div className="col-md-6">
                                  <h5>Block Of Art</h5>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    <small className="text-grey">Price</small>
                                    <span className="text-white">0.005 ADA</span>
                                    <span className="text-grey">
                                      (0.0042 USD)
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p>
                              Aenean sed nibh a magna posuere tempor. Nunc
                              faucibus pellentesque nunc in aliquet. Donec congue,
                              nunc vel tempor congue, enim sapien lobortis ipsum,
                              in volutpat sem ex in ligula.{" "}
                            </p>
                          </div>

                          <div className="third_row">
                            <h5 className="margin-bottom-20">Comments</h5>
                            <form>
                              <div className="row">
                                <div className="col-md-1">
                                  <p className="user_det_img">
                                    <img src="images/create-6/user.png" alt="" />
                                  </p>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    name=""
                                    className="comment_input"
                                    placeholder="Write your comment here"
                                  />
                                </div>
                              </div>
                            </form>
                          </div>

                          <div className="forth_row">
                            <div className="display_flex">
                              <div className="user_det_img">
                                <img src="images/create-6/user.png" alt="" />
                              </div>
                              <p>Top Miller</p>
                              <p className="text-grey">Great Work</p>
                            </div>
                            <div className="display_flex">
                              <div className="user_det_img">
                                <img src="images/create-6/user.png" alt="" />
                              </div>
                              <p>Top Miller</p>
                              <p className="text-grey">Great Work</p>
                            </div>

                            <div className="display_flex">
                              <div className="user_det_img">
                                <img src="images/create-6/user.png" alt="" />
                              </div>
                              <p>Top Miller</p>
                              <p className="text-grey">Great Work</p>
                            </div>

                            <div className="display_flex">
                              <div className="user_det_img">
                                <img src="images/create-6/user.png" alt="" />
                              </div>
                              <p>Top Miller</p>
                              <p className="text-grey">Great Work</p>
                            </div>

                            <div className="display_flex">
                              <div className="user_det_img">
                                <img src="images/create-6/user.png" alt="" />
                              </div>
                              <p>Top Miller</p>
                              <p className="text-grey">Great Work</p>
                            </div>

                            <div className="display_flex">
                              <div className="user_det_img">
                                <img src="images/create-6/user.png" alt="" />
                              </div>
                              <p>Top Miller</p>
                              <p className="text-grey">Great Work</p>
                            </div>

                            <div className="display_flex">
                              <div className="user_det_img">
                                <img src="images/create-6/user.png" alt="" />
                              </div>
                              <p>Top Miller</p>
                              <p className="text-grey">Great Work</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <InfiniteScroll
                dataLength={feed.length}
                next={loadNext}
                hasMore={hasMore}
                endMessage={
                  <div style={{ textAlign: "center" }}>
                    <span className="icon has-text-info">
                      <i className="fas fa-info-circle"></i>
                    </span>
                    <b>Yay! You have seen it all</b>
                  </div>
                }
                scrollableTarget="body"
              >
                <div className="row">
                  <div className="col-md-2">
                    {col0.slice(0).map((project, i) => {
                      return (
                        <div className="feed_l_content_area" key={i}>
                          <div className="feed_l_content">
                            <FadeImg
                              src={project.postimage}
                              alt={project.postname}
                              className={pattern0[i]}
                            />
                            <div className="latest_like_share">
                              <a
                                href="#"
                                className="favourite"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <i className="fa-solid fa-heart"></i>
                              </a>
                              <ul className="latest_like_one_row">
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-up"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#dislike"
                                  >
                                    <i
                                      className="fa-solid fa-thumbs-down"

                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#shareModal"
                                  >
                                    <i
                                      className="fa-solid fa-share"
                                      onClick={() => {
                                        
                                        setselectedCollection(project);
                                      }}
                                    ></i>
                                  </a>
                                </li>
                                <li className="drop-down">
                                  <a href="#" className="drop-down__button">
                                    <i className="fa-solid fa-ellipsis drop-down__name"></i>
                                  </a>
                                  <div className="drop-down__menu-box">
                                    <ul className="drop-down__menu">
                                      <li
                                        data-name="profile"
                                        className="drop-down__item"
                                      >
                                        <i className="fa-solid fa-ban"> onClick={() => {
                                        setselectedCollection(project);
                                      }}</i>  */}
          {/* Not
                                        Intrested */}
          {/* </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="feed_one_row">
                            {project.imageofsharedby[i] == undefined ? (
                              <FadeImg
                                src={userdefault}
                                alt={project.sharedby}
                                className="feed_one_row_img"
                              />
                            ) : (
                              <FadeImg
                                src={`https://gateway.pinata.cloud/ipfs/${project.imageofsharedby[i]}`}
                                alt={project.sharedby}
                                className="feed_one_row_img"
                              />
                            )}
                            <div className="feed_one_left">
                              <p>@{project.sharedby}</p>
                              <p className="text-grey">@sunstep</p>
                            </div>
                            <div className="feed_one_right">
                              <p>2.54 ADA</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-md-2">
                    {col1.slice(0).map((project, i) => {
                      return (
                        <div className="feed_l_content_area" key={i}>
                          <div className="feed_l_content">
                            <FadeImg
                              src={project.postimage}
                              alt={project.postname}
                              className={pattern1[i]}
                            />
                            <div className="latest_like_share">
                              <a
                                href="#"
                                className="favourite"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <i className="fa-solid fa-heart"></i>
                              </a>
                              <ul className="latest_like_one_row">
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-up"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-down"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#shareModal"
                                  >
                                    <i
                                      className="fa-solid fa-share"
                                      onClick={() => {
                                        setselectedCollection(project);
                                      }}
                                    ></i>
                                  </a>
                                </li>
                                <li className="drop-down">
                                  <a href="#" className="drop-down__button">
                                    <i className="fa-solid fa-ellipsis drop-down__name"></i>
                                  </a>
                                  <div className="drop-down__menu-box">
                                    <ul className="drop-down__menu">
                                      <li
                                        data-name="profile"
                                        className="drop-down__item"
                                      >
                                        <i className="fa-solid fa-ban"></i> Not
                                        Intrested
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="feed_one_row">
                            <FadeImg
                              src={userdefault}
                              alt={project.sharedby}
                              className="feed_one_row_img"
                            />
                            <div className="feed_one_left">
                              <p>@{project.sharedby}</p>
                              <p className="text-grey">@sunstep</p>
                            </div>
                            <div className="feed_one_right">
                              <p>2.54 ADA</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-md-2">
                    {col2.slice(0).map((project, i) => {
                      return (
                        <div className="feed_l_content_area" key={i}>
                          <div className="feed_l_content">
                            <FadeImg
                              src={project.postimage}
                              alt={project.postname}
                              className={pattern2[i]}
                            />
                            <div className="latest_like_share">
                              <a
                                href="#"
                                className="favourite"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <i className="fa-solid fa-heart"></i>
                              </a>
                              <ul className="latest_like_one_row">
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-up"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-down"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#shareModal"
                                  >
                                    <i
                                      className="fa-solid fa-share"
                                      onClick={() => {
                                        setselectedCollection(project);
                                      }}
                                    ></i>
                                  </a>
                                </li>
                                <li className="drop-down">
                                  <a href="#" className="drop-down__button">
                                    <i className="fa-solid fa-ellipsis drop-down__name"></i>
                                  </a>
                                  <div className="drop-down__menu-box">
                                    <ul className="drop-down__menu">
                                      <li
                                        data-name="profile"
                                        className="drop-down__item"
                                      >
                                        <i className="fa-solid fa-ban"></i> Not
                                        Intrested
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="feed_one_row">
                            <FadeImg
                              src={userdefault}
                              alt={project.sharedby}
                              className="feed_one_row_img"
                            />
                            <div className="feed_one_left">
                              <p>@{project.sharedby}</p>
                              <p className="text-grey">@sunstep</p>
                            </div>
                            <div className="feed_one_right">
                              <p>2.54 ADA</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-md-2">
                    {col3.slice(0).map((project, i) => {
                      return (
                        <div className="feed_l_content_area" key={i}>
                          <div className="feed_l_content">
                            <FadeImg
                              src={project.postimage}
                              alt={project.postname}
                              className={pattern3[i]}
                            />
                            <div className="latest_like_share">
                              <a
                                href="#"
                                className="favourite"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <i className="fa-solid fa-heart"></i>
                              </a>
                              <ul className="latest_like_one_row">
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-up"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-down"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#shareModal"
                                  >
                                    <i
                                      className="fa-solid fa-share"
                                      onClick={() => {
                                        setselectedCollection(project);
                                      }}
                                    ></i>
                                  </a>
                                </li>
                                <li className="drop-down">
                                  <a href="#" className="drop-down__button">
                                    <i className="fa-solid fa-ellipsis drop-down__name"></i>
                                  </a>
                                  <div className="drop-down__menu-box">
                                    <ul className="drop-down__menu">
                                      <li
                                        data-name="profile"
                                        className="drop-down__item"
                                      >
                                        <i className="fa-solid fa-ban"></i> Not
                                        Intrested
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="feed_one_row">
                            <FadeImg
                              src={userdefault}
                              alt={project.sharedby}
                              className="feed_one_row_img"
                            />
                            <div className="feed_one_left">
                              <p>@{project.sharedby}</p>
                              <p className="text-grey">@sunstep</p>
                            </div>
                            <div className="feed_one_right">
                              <p>2.54 ADA</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-md-2">
                    {col4.slice(0).map((project, i) => {
                      return (
                        <div className="feed_l_content_area" key={i}>
                          <div className="feed_l_content">
                            <FadeImg
                              src={project.postimage}
                              alt={project.postname}
                              className={pattern4[i]}
                            />
                            <div className="latest_like_share">
                              <a
                                href="#"
                                className="favourite"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <i className="fa-solid fa-heart"></i>
                              </a>
                              <ul className="latest_like_one_row">
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-up"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-down"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#shareModal"
                                  >
                                    <i
                                      className="fa-solid fa-share"
                                      onClick={() => {
                                        setselectedCollection(project);
                                      }}
                                    ></i>
                                  </a>
                                </li>
                                <li className="drop-down">
                                  <a href="#" className="drop-down__button">
                                    <i className="fa-solid fa-ellipsis drop-down__name"></i>
                                  </a>
                                  <div className="drop-down__menu-box">
                                    <ul className="drop-down__menu">
                                      <li
                                        data-name="profile"
                                        className="drop-down__item"
                                      >
                                        <i className="fa-solid fa-ban"></i> Not
                                        Intrested
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="feed_one_row">
                            <FadeImg
                              src={userdefault}
                              alt={project.sharedby}
                              className="feed_one_row_img"
                            />
                            <div className="feed_one_left">
                              <p>@{project.sharedby}</p>
                              <p className="text-grey">@sunstep</p>
                            </div>
                            <div className="feed_one_right">
                              <p>2.54 ADA</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-md-2">
                    {col5.slice(0).map((project, i) => {
                      return (
                        <div className="feed_l_content_area" key={i}>
                          <div className="feed_l_content">
                            <FadeImg
                              src={project.postimage}
                              alt={project.postname}
                              className={pattern5[i]}
                            />
                            <div className="latest_like_share">
                              <a
                                href="#"
                                className="favourite"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <i className="fa-solid fa-heart"></i>
                              </a>
                              <ul className="latest_like_one_row">
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-up"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fa-solid fa-thumbs-down"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#shareModal"
                                  >
                                    <i
                                      className="fa-solid fa-share"
                                      onClick={() => {
                                        setselectedCollection(project);
                                      }}
                                    ></i>
                                  </a>
                                </li>
                                <li className="drop-down">
                                  <a href="#" className="drop-down__button">
                                    <i className="fa-solid fa-ellipsis drop-down__name"></i>
                                  </a>
                                  <div className="drop-down__menu-box">
                                    <ul className="drop-down__menu">
                                      <li
                                        data-name="profile"
                                        className="drop-down__item"
                                      >
                                        <i className="fa-solid fa-ban"></i> Not
                                        Intrested
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="feed_one_row">
                            <FadeImg
                              src={`https://gateway.pinata.cloud/ipfs/${project.imageofsharedby}`}
                              alt={project.sharedby}
                              className="feed_one_row_img"
                            />
                            <div className="feed_one_left">
                              <p>@{project.sharedby}</p>
                              <p className="text-grey">@sunstep</p>
                            </div>
                            <div className="feed_one_right">
                              <p>2.54 ADA</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
          </div> */}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default Social;
