import React from "react";
// import logo from '../../assets/images/sunsteplogo.svg';
import "./style.scss";

const Footer = () => {
    const twitter = "https://twitter.com/EdgewrapperL";
    const facebook = "https://www.facebook.com/edgewrapper";
    const linkedin = "https://www.linkedin.com/company/edgewrapper";
    const instagram = "https://www.instagram.com/edgewrapper";
    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer_first">
                                <img src={"/images/sunsteplogo.svg"} alt="" className="img-responsive footer-logo" width={"50px"} />
                                <p className="footer_sub_heading">Put Your Steps Into The World of Digital Collectibles And Unlock the Power of NFTs with Sunsteps</p>
                                <ul className="list-unstyled display_flex">
                                    <li><a href={`${facebook}`} target="_blank"><i className="fa-brands fa-facebook-square"></i></a></li>
                                    <li><a href={`${twitter}`} target="_blank"><i className="fa-brands fa-twitter-square"></i></a></li>
                                    <li><a href={`${linkedin}`} target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                                    <li><a href={`${instagram}`} target="_blank"><i className="fa-brands fa-instagram-square"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                            <p className="footer_sub_heading padding-bottom-20">About Us</p>
                            <ul className="list-unstyled footer_link">
                                <li><a href={"/about"}>About NFTs</a></li>
                                <li><a href={"#"}>Collections </a></li>
                                <li><a href="#">NFT Blog</a></li>
                                <li><a href="#">Activity</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <p className="footer_sub_heading padding-bottom-20">Support</p>
                            <ul className="list-unstyled footer_link">
                                <li><a href={"#"} >Help & Support</a></li>
                                <li><a href={"#"}>Item Details</a></li>
                                <li><a href={"#"}>Author Profile</a></li>
                                <li><a href={"#"}>Newsletter</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer_copyright">

                        <p>All rights reserved by <a href="https://join.skype.com/o1jxhMDdMKCI" target="_blank" className="footer-underline-text">BrandMe</a> and <a href="https://www.edgewrapper.com" target="_blank" className="footer-underline-text">eDgeWrapper</a> @2023</p>

                    </div>

                </div>
            </div>
        </>
    );
}
export default Footer;