export const WALLET_CONNECTED = 'WALLET_CONNECTED';
export const SET_WALLET_NETWORK = 'SET_WALLET_NETWORK';
export const SET_WALLET_USEADDR = 'SET_WALLET_USEADDR';
export const SET_WALLET_REWARDADDR = 'SET_WALLET_REWARDADDR';
export const SET_WALLET_BALANCE = 'SET_WALLET_BALANCE';
export const SET_WALLET_UTXOS = 'SET_WALLET_UTXOS';
export const SET_WALLET_LOADING = 'SET_WALLET_LOADING';
export const SET_WALLET_ASSETS = 'SET_WALLET_ASSETS';
export const SET_WALLET_DATA = 'SET_WALLET_DATA';
export const ADD_WALLET_ASSET = 'ADD_WALLET_ASSET';

export const WALLET_STATE = {
  'CONNECTING': 'CONNECTING',
  'GETTING_ASSETS': 'GETTING_ASSETS',
  'AWAITING_SIGNATURE': 'AWAITING_SIGNATURE',
}

export const MARKET_TYPE = {
  'NEW_LISTING': 'NEW_LISTING',
  'PRICE_UPDATE': 'PRICE_UPDATE',
  'DELIST': 'DELIST',
  'PURCHASE': 'PURCHASE',
  'SOLD': 'SOLD',
}
