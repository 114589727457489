const errorTypes = {
  COULD_NOT_FETCH_ASSET_DETAILS: "COULD_NOT_FETCH_ASSET_DETAILS",
  COULD_NOT_FETCH_ASSET_TRANSACTIONS: "COULD_NOT_FETCH_ASSET_TRANSACTIONS",
  COULD_NOT_FETCH_ADDRESS_UTXOS: "COULD_NOT_FETCH_ADDRESS_UTXOS",
  COULD_NOT_FETCH_ASSET_UTXOS: "COULD_NOT_FETCH_ASSET_UTXOS",
  COULD_NOT_FETCH_MINTED_ASSETS: "COULD_NOT_FETCH_MINTED_ASSETS",
  COULD_NOT_FETCH_TRANSACTION_METADATA: "COULD_NOT_FETCH_TRANSACTION_METADATA",
  COULD_NOT_FETCH_TRANSACTION_UTXOS: "COULD_NOT_FETCH_TRANSACTION_UTXOS",
  COULD_NOT_FETCH_PROTOCOL_PARAMETERS: "COULD_NOT_FETCH_PROTOCOL_PARAMETERS",
};

export default errorTypes;
