import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Navbar } from "components";

import "./style.scss";

import { opencnft_get_top_projects as opencnftGetTopProjects } from "store/collection/api";
import { FadeImg, FadeVideo } from "components/Fades";
import { get_asset_image_source, numFormatter } from "utils/converter";

const MintSplash = () => {
  const show_num_projects_initial = 12;
  const [pending, setPending] = useState(false);
  const [window, setWindow] = useState("7d");
  const [showLimit, setShowLimit] = useState(show_num_projects_initial);
  const [topProjectData, setTopProjectData] = useState([]);
  const [CNFTLoaded, setCNFTLoaded] = useState(false);

  const dispatch = useDispatch();
  const [ticking, setTicking] = useState(true),
    [count, setCount] = useState(0)
  const [listProjects, setListProjects] = useState([]);

  const opencnft_get_top_projects = (time, callback) => {
    dispatch(opencnftGetTopProjects(time, callback));
  };
  function prepare_data(project_list, limit) {
    let list = [];
    // 
    for (var i in project_list) {
      let project = project_list[i];
      project.rank = i;
      project.image = get_asset_image_source(
        Array.isArray(project.thumbnail)
          ? project.thumbnail[0].includes("data:image")
            ? project.thumbnail
            : project.thumbnail[0]
          : project.thumbnail
      );
      list.push(project);
      // if(i>=limit) break;
    }
    setListProjects(list);
    setPending(false);
    setCNFTLoaded(true);
  }
  const onchange_window = (win) => {
    setWindow(win);
    setPending(true);
    setListProjects([]);
    setShowLimit(show_num_projects_initial);
    // opencnft_get_top_projects(win, (res) => {
    //   setTopProjectData(res.data);
    //   prepare_data(res.data, show_num_projects_initial);
    // });
  };
  useEffect(() => {
    const timer = setTimeout(() => ticking && setCount(count + 1), 10000)
    return () => clearTimeout(timer)
  });
  useEffect(() => {
    // if (listProjects.length === 0) onchange_window("7d");
  }, []);

  return (
    <>
      <Navbar />
      {/* <div className="home-header explore_area">
        <div className="explore_header_section">
          <div className="container">
            <div className="breadcrumb_area">
              <ul className="breadcrumb">
                <li><a href="#" className="text-white"><i className="fa-solid fa-arrow-left"></i></a></li>
                <li><a href="#">Home</a></li>
                <li className="active">Mint</li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default MintSplash;
