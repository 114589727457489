import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// import defaultImage from '../../assets/images/create-profile/default-banner.png';
import userdefault from "../../assets/images/userdefault.png";
import facebookImage from "../../assets/images/create-profile/facebook.png";
import instagramImage from "../../assets/images/create-profile/instagram.png";
import twitterImage from "../../assets/images/create-profile/twitter.png";
import shareImage from "../../assets/images/create-profile/share.png";

import {
  authenticate_pinata,
  sunstep_user_update,
  sunstep_user_fetch,
  file_upload,
  file_upload_to_aws,
  getfollowdetailscount,
} from "../../store/account/api";
import { user_signin } from "../../store/account/accountActions";
import { FadeImg, FadeVideo } from "components/Fades";
import Footer from "../../components/Footer/index"



import $ from "jquery";
import './style.scss'

const Profile = () => {
  const state_account = useSelector((state) => state.account);
  const state_wallet = useSelector((state) => state.wallet);
  let history = useHistory();
  let profiledata = state_account.profile;
  let [formData, updateFormData] = React.useState(profiledata);
  const [emailchecker, setemailchecker] = React.useState(false);
  // const [profileimageloader, setprofileimageloader] = React.useState(false)
  const [profilebannerloader, setprofilebannerloader] = React.useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);

  // this.state_account.profile = state_account.profile
  const dispatch = useDispatch();
  useEffect(() => {
    $("body").addClass("loaded");
    if (state_account.loaded) {
      authenticate_pinata();
      if (state_account.profile.email != undefined) {
        const payload = {
          email: state_account.profile.email,
        };
        const payloadforfollowdetails = {
          useremail: state_account.profile.email,
        };
        dispatch(sunstep_user_fetch(payload, (res) => { }));
        dispatch(getfollowdetailscount(payloadforfollowdetails, (res) => { }));
      }
      console.log(state_account.profile);
    }
  }, []);

  const onProfilePicUpload = async (e) => {
    // setprofileimageloader(true)
    if (e) {
      const file = e.target.files[0];
      console.log(file);

      const formdata = new FormData();

      // Update the formData object
      formdata.append("file", e.target.files[0]);
      setShowNotificationMessage(
        "Please wait your profile photo is uploading to server"
      );
      setShowNotification(true);
      await file_upload_to_aws(formdata, (res) => {
        // this.state_account.profile.profileimage = `${res.data.IpfsHash}`
        updateFormData({
          ...formData,
          // Trimming any whitespace
          [e.target.name]: `${res.data}`,
        });
        formData = state_account.profile;
        setShowNotificationMessage(
          "Your profile photo has been uploaded to server!"
        );
        setShowNotification(true);

        // setprofileimageloader(false)
      });
      // dispatch(user_signin(this.state_account.profile))
      // dispatch(user_profile_image_upload(state_account.profile.profileimage))
    } else {
      // setprofileimageloader(false)
    }
  };
  const onCoverPicUpload = async (e) => {
    setprofilebannerloader(true);
    if (e) {
      const file = e.target.files[0];
      console.log(file);

      const formdata = new FormData();
      setShowNotificationMessage(
        "Please wait your cover photo is uploading to server"
      );
      setShowNotification(true);
      // Update the formData object
      formdata.append("file", e.target.files[0]);

      await file_upload_to_aws(formdata, (res) => {
        updateFormData({
          ...formData,
          [e.target.name]: `${res.data}`,
        });
        formData = state_account.profile;
        setprofilebannerloader(false);
        setShowNotificationMessage(
          "Your profile photo has been uploaded to server!"
        );
        setShowNotification(true);
        // this.state_account.profile.profilebannerimage = `${res.data.IpfsHash}`
      });
      // dispatch(user_signin(this.state_account.profile))
    } else {
      setprofilebannerloader(false);
    }
  };



  const handleChange = (e) => {
    if (e.target.name != "bio") {
      updateFormData({
        ...formData,
        // Trimming any whitespace
        [e.target.name]: e.target.value,
      });
      formData = state_account.profile;
    } else if (e.target.name != "email") {
      setemailchecker(false);

      updateFormData({
        ...formData,
        // Trimming any whitespace
        [e.target.name]: e.target.value,
      });
      formData = state_account.profile;
    } else {
      updateFormData({
        ...formData,
        // Trimming any whitespace
        [e.target.name]: e.target.value,
      });
      formData = state_account.profile;
    }
  };

  const submit = async () => {
    if (formData.email == "") {
      if (formData.email == undefined) {
        setemailchecker(true);
      } else {
        setemailchecker(true);
      }
    } else {
      setShowNotificationMessage("Save Changes Sucessfully..");
      setShowNotification(true);
      dispatch(
        sunstep_user_update(formData, (res) => {
          dispatch(user_signin(formData));
          // localStorage.setItem('formData',JSON.stringify(formData));
          history.push("/account");
        })
      );
    }

    // this.state_account.profile.walletaddress = state_account.profile.walletaddress
  };
  const Home = () => {
    history.push("/profile");
  };
  const closeNotification = () => {
    setShowNotification(false);
  };
  // const data = localStorage.getItem('formData')

  return (
    <>
      {!state_wallet.connected ? <NotConnected /> : <></>}
      {state_wallet.connected ? (
        <>
          {showNotification ? (
            <button
              type="button"
              className="btn btn-primary nortification_footer"
            >
              <div className="row">
                <div className="col-md-10">{showNotificationMessage}</div>
                <div className="col-md-2">
                  <span className="badge badge-light">
                    <i
                      className="fa-solid fa-xmark hide_x"
                      onClick={closeNotification}
                    ></i>
                  </span>
                </div>
              </div>
            </button>
          ) : (
            <></>
          )}
          <div className="edit_profille_area">
            {/* <div>
              <div className="row">
                <div className="col-md-6 col-6">
                  <ul className="list-unstyled following_area">
                    <li>
                      <a href="#">Followings {state_account.followingcount} </a>
                    </li>
                    <li>
                      <a href="#">Followers {state_account.followercount}</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-6">
                  <ul className="profile_social_media">
                    {formData.facebook != undefined && (
                      <li>
                        <a href={formData.facebook} target="_blank">
                          <img src={facebookImage} alt="" />
                        </a>
                      </li>
                    )}
                    {formData.linkedIn != undefined && (
                      <li>
                        <a href={formData.linkedIn} target="_blank">
                          <img src={instagramImage} alt="" />
                        </a>
                      </li>
                    )}
                    {formData.twitter != undefined && (
                      <li>
                        <a href={formData.twitter} target="_blank">
                          <img src={twitterImage} alt="" />
                        </a>
                      </li>
                    )}
                    {formData.website != undefined && (
                      <li>
                        <a href={formData.website} target="_blank">
                          <img src={shareImage} alt="" />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div> */}
            {/* <div className="create_main_profile">
              <div className="container">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <div className="profile-image">
                      {formData.profileimage == undefined && (
                        <img src={userdefault} alt="" className="imgThumb" />
                      )}
                      {formData.profileimage != undefined &&
                        formData.profileimage != "" && (
                          <img
                            className="imgThumb"
                            src={`${formData.profileimage}`}
                            alt=""
                          />
                        )}
                    </div>
                    <div className="creat_main_profile_heading padding_bottom_50">
                      <ul className="list-inline float_none">
                        <li className="text-bold">
                          {/* {formData.firstname} {formData.lastname} */}
            {/* </li> */}
            {/* <li><a href="#" className="subheading_size">@walterfisher33</a></li> */}
            {/* </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="edit_profille_area_form">
                    {/* <label>Change Cover Pic</label> */}
                    <div className="pic-holder">
                      {formData.profilebannerimage != undefined &&
                        formData.profilebannerimage != "" && (
                          <img
                            id="profilePic"
                            className="pic"
                            style={{ "height": "fit-content", "zIndex": "3" }}
                            src={`${formData.profilebannerimage}`}
                            alt=""
                          />
                        )}
                      <input
                        className="uploadCoverInput"
                        type="file"
                        name="profilebannerimage"
                        id="newProfileBannerPhoto"
                        accept="image/*"
                        onChange={onCoverPicUpload}
                      />
                      <label
                        htmlFor="newProfileBannerPhoto"
                        className="upload-file-block"
                      >
                        <div className="text-center">
                          <div className="mb-2">
                            <i className="fa fa-camera fa-2x"></i>
                          </div>
                          <div className="text-uppercase">
                            Update <br /> Cover Photo
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form className="msform">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="edit_profille_area_form margin-bottom-30 profile_img_upload_area">
                      {/* <label>Change Profile Pic</label> */}
                      <div className="pic-holder profile-img-holder">
                        {formData.profileimage != undefined &&
                          formData.profileimage != "" && (
                            <img
                              id="profilePic"
                              className="pic"
                              src={`${formData.profileimage}`}
                              alt=""
                            />
                          )}
                        {/* <input
                          className="uploadProfileInput"
                          type="file"
                          name="profileimage"
                          id="newProfilePhoto"
                          accept="image/*"
                          onChange={onProfilePicUpload}
                        />
                        <label
                          htmlFor="newProfilePhoto"
                          className="upload-file-block"
                        >
                          <div className="text-center">
                            <div className="mb-2">
                              <i className="fa fa-camera fa-2x"></i>
                            </div>
                            <div className="text-uppercase">
                              Update <br /> Profile Photo
                            </div>
                          </div>
                        </label> */}
                      </div>
                      <div className="btn_exlore uploadinputbtn-wrapp">
                        Upload Profile Picture
                        <input
                          className="uploadCoverInput uploadinputbtn"
                          type="file"
                          name="profileimage"
                          id="newProfileBannerPhoto"
                          accept="image/*"
                          onChange={onProfilePicUpload}
                        />
                      </div>
                    </div>
                    {/* <div className="edit_profille_area_form">
                      <label>Change Cover Pic</label>
                      <div className="pic-holder">
                        {formData.profilebannerimage != undefined &&
                          formData.profilebannerimage != "" && (
                            <img
                              id="profilePic"
                              className="pic"
                              src={`${formData.profilebannerimage}`}
                              alt=""
                            />
                          )}
                        <input
                          className="uploadCoverInput"
                          type="file"
                          name="profilebannerimage"
                          id="newProfileBannerPhoto"
                          accept="image/*"
                          onChange={onCoverPicUpload}
                        />
                        <label
                          htmlFor="newProfileBannerPhoto"
                          className="upload-file-block"
                        >
                          <div className="text-center">
                            <div className="mb-2">
                              <i className="fa fa-camera fa-2x"></i>
                            </div>
                            <div className="text-uppercase">
                              Update <br /> Cover Photo
                            </div>
                          </div>
                        </label>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-md-8">
                    <div>
                      <div className="edit_profille_area_form">
                        <h5>Account Information</h5>
                      </div>
                      <div className="edit_profille_area_form_body">
                        <div className="row">
                          <div className="col-md-6 all_info_box">
                            <label>First name</label>
                            <input
                              type="text"
                              className="form-control form-control-box"
                              name="firstname"
                              placeholder="write your first name here"
                              onChange={handleChange}
                              value={formData.firstname}
                            />
                          </div>
                          <div className="col-md-6 all_info_box">
                            <label>Last Name</label>
                            <input
                              type="text"
                              name="lastname"
                              className="form-control form-control-box"
                              placeholder="write your last name here"
                              onChange={handleChange}
                              value={formData.lastname}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 all_info_box">
                            <label>Bio</label>
                            <textarea
                              rows="6"
                              cols="20"
                              name="bio"
                              className="form-control form-control-box"
                              placeholder="write your bio here"
                              onChange={handleChange}
                              value={formData.bio}
                            ></textarea>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 all_info_box">
                            <input
                              type="text"
                              name="email"
                              placeholder=" Enter your email id"
                              className="form-control form-control-box"
                              value={formData.email}
                              onChange={handleChange}
                            />
                            {emailchecker == true && (
                              <span>Please sumbit your email</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="social_info_area">
                      <div className="edit_profille_area_form">
                        <h5>Add links to your profile</h5>
                      </div>
                      <div className="edit_profille_area_form_body">
                        <div className="row">
                          <div className="col-md-6 add_link_icon_area">
                            <input
                              type="text"
                              placeholder="Instagram"
                              className="form-control form-control-box"
                              onChange={handleChange}
                              name="linkedIn"
                              value={formData.linkedIn}
                            />{" "}
                            <i className="fa-brands fa-instagram-square"></i>
                          </div>
                          <div className="col-md-6 add_link_icon_area">
                            <input
                              type="text"
                              name="twitter"
                              placeholder="Twitter"
                              value={formData.twitter}
                              className="form-control form-control-box"
                              onChange={handleChange}
                            />{" "}
                            <i className="fa-brands fa-twitter-square"></i>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 add_link_icon_area">
                            <input
                              type="text"
                              name="facebook"
                              placeholder="Facebook"
                              value={formData.facebook}
                              className="form-control form-control-box"
                              onChange={handleChange}
                            />{" "}
                            <i className="fa-brands fa-facebook-square"></i>
                          </div>
                          <div className="col-md-6 add_link_icon_area">
                            <input
                              type="text"
                              name="telegram"
                              placeholder="Telegram"
                              value={formData.telegram}
                              className="form-control form-control-box"
                              onChange={handleChange}
                            />{" "}
                            <i className="fa-brands fa-telegram"></i>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 add_link_icon_area">
                            <input
                              type="text"
                              name="discord"
                              placeholder="Discord"
                              value={formData.discord}
                              className="form-control form-control-box"
                              onChange={handleChange}
                            />{" "}
                            <i className="fa-brands fa-discord"></i>
                          </div>
                          <div className="col-md-6 add_link_icon_area">
                            <input
                              type="text"
                              name="website"
                              placeholder="Website"
                              value={formData.website}
                              className="form-control form-control-box"
                              onChange={handleChange}
                            />{" "}
                            <i className="fa-solid fa-globe"></i>
                          </div>
                        </div>
                      </div>
                      <div className="profile_changes_btn">
                        <div name="next" className="btn_exlore btn_cancel" value="Cancel">Cancel</div>
                        <div type="button" name="next" className="btn_exlore"
                          value="Log out"
                          // onClick={() => {
                          //   localStorage.removeItem("wallet_name");
                          //   window.location.reload();
                          //   // localStorage.clear()
                          // }}
                          onClick={submit}
                        >Save</div>

                      </div>

                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-4">
                    <p className="float-end save_btn">
                      <input
                        type="button"
                        name="next"
                        className="action-button"
                        value="Save Changes"
                        onClick={submit}
                      />
                    </p>

                  </div>
                  <div className="col-md-4">
                    <p className="float-end save_btn"><input type="button" name="next" className="action-button"
                      value="Log out" onClick={() => {
                        localStorage.removeItem("wallet_name");
                        window.location.reload();
                        // localStorage.clear()
                      }} /></p>
                  </div>


                </div> */}
              </div>
            </form>
            <Footer />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
const NotConnected = () => {
  return (
    <section className="hero is-large">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1>
            <span
              className="icon"
              style={{ fontSize: "100px", marginBottom: "50px" }}
            >
              <i className="fas fa-plug"></i>
            </span>
          </h1>
          <p className="title">Connect your wallet</p>
          <p className="subtitle">
            Do not have Nami Wallet?{" "}
            <a href="https://namiwallet.io/" target="_blank" rel="noreferrer">
              Download
            </a>{" "}
            now!
          </p>
        </div>
      </div>
    </section>
  );
};
export default Profile;
