import React, { useState } from "react";
import "./style.css";

const FadeVideo = (props) => {
  const [show, setShow] = useState(false);

  const onLoad = () => {
    setShow(true);
  };

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <video 
    crossOrigin="anonymous" 
    autoPlay controls loop playsInline className = "feed_lg_img">
    <source {...props} type="video/mp4" />
    </video>

  );
};

export default FadeVideo;
