export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_SIGNIN = 'USER_SIGNIN';
export const USER_FOLLOWERS = 'USER_FOLLOWERS';
export const USER_FOLLOWINGS = 'USER_FOLLOWINGS';
export const USER_FOLLOWER_REQUEST = 'USER_FOLLOWER_REQUEST';
export const USER_REQUESTED_FOLLOWERS = 'USER_REQUESTED_FOLLOWERS';
export const USER_FOLLOWER_ACCEPT = 'USER_FOLLOWER_ACCEPT';
export const USER_SOCIAL_FEED = 'USER_SOCIAL_FEED';
export const USER_ALL_USERS = 'USER_ALL_USERS';
export const USER_FOLLOWER_COUNT = 'USER_FOLLOWER_COUNT';
export const USER_FOLLOWING_COUNT = 'USER_FOLLOWING_COUNT';
export const USER_PROFILE_IMAGE_UPLOAD = 'USER_PROFILE_IMAGE_UPLOAD';
export const USER_ALL_FEEDCOUNT = 'USER_ALL_FEEDCOUNT';
export const NOTIFICATION_STATUS = 'NOTIFICATION_STATUS';
export const NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE';


